import emailIcon from 'assets/images/icons/icon-email.svg';
import smsIcon from 'assets/images/icons/icon-sms.svg';
import automationIcon from 'assets/images/icons/icon-loyalty.svg';
import { GUEST_COMMUNICATION } from './constants';

export const getMarketingPopupDetails = ({
  marketingType,
  marketingEmail,
  marketingSms,
  marketingAutomation,
  firstName,
  confirmPopup,
}: any) => {
  switch (marketingType) {
    case GUEST_COMMUNICATION.email:
      return {
        confirmActionHandler: () =>
          confirmPopup({
            guest: { marketingEmail: !marketingEmail },
          }),
        popupTitle: marketingEmail
          ? `Are you sure you want to unsubscribe ${firstName} from e-mail marketing?`
          : `Are you sure you want to subscribe ${firstName} to e-mail marketing?`,
        popupDescription: marketingEmail
          ? 'They will be marked as not wanting e-mail marketing in your guest book and CRM exports.'
          : 'They will be marked as wanting e-mail marketing in your guest book and CRM exports.',

        confirmActionText: `Yes, ${marketingEmail ? 'unsubscribe' : 'subscribe'}`,
        toggleStatus: marketingEmail,
        icon: marketingEmail ? null : emailIcon,
      };

    case GUEST_COMMUNICATION.sms:
      return {
        // set marketingAutomation to false when turning marketingSms to false value.
        confirmActionHandler: () =>
          confirmPopup({
            guest: { marketingSms: !marketingSms },
            data: { ...(marketingSms && { marketingAutomation: false }) },
          }),
        popupTitle: marketingSms
          ? `Are you sure you want to unsubscribe ${firstName} from SMS marketing?`
          : `Are you sure you want to subscribe ${firstName} to SMS marketing?`,
        popupDescription: marketingSms
          ? 'They will no longer receive any Autopilot campaigns, loyalty offers, or other promotions, and will be marked as not wanting SMS marketing in your guest book and CRM exports.'
          : 'They will receive loyalty offers, and other promotions. They will be marked as wanting SMS marketing in your guest book and CRM exports.',

        confirmActionText: `Yes, ${marketingSms ? 'unsubscribe' : 'subscribe'}`,
        toggleStatus: marketingSms,
        icon: marketingSms ? null : smsIcon,
      };

    case GUEST_COMMUNICATION.automation:
      return {
        confirmActionHandler: () =>
          confirmPopup({ data: { marketingAutomation: !marketingAutomation } }),
        popupTitle: marketingAutomation
          ? `Are you sure you want to unsubscribe ${firstName} from  marketing automation?`
          : `Are you sure you want to subscribe ${firstName} to marketing automation?`,
        popupDescription: marketingAutomation
          ? 'They will no longer receive any Autopilot campaigns.'
          : 'They will receive Autopilot campaigns.',

        confirmActionText: `Yes, ${marketingAutomation ? 'unsubscribe' : 'subscribe'}`,
        toggleStatus: marketingAutomation,
        icon: marketingAutomation ? null : automationIcon,
      };

    default:
      return {
        popupTitle: '',
        popupDescription: '',
        confirmActionText: '',
        confirmActionHandler: () => {},
        toggleStatus: false,
      };
  }
};

export const getFullJobTitle = (jobTitle?: string, company?: string) =>
  company && jobTitle ? `${jobTitle} - ${company}` : jobTitle;

export const transactionsSummaryOrder = (order = {}) => ({
  totalSpend: 1,
  visits: 2,
  avgPerVisit: 3,
  avgPerCover: 4,
  noShows: 5,
  cancellations: 6,
  ...order,
});
