import React from 'react';
import type { SVGProps } from 'react';
import { COLORS } from 'constants/colors';

const CreateGuestIcon = ({
  width = 20,
  height = 20,
  color = COLORS.BLACK,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 20 20"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <path
      fill={color}
      d="M10.029 10.657h-.101A2.85 2.85 0 0 1 7.7 9.652c-1.213-1.425-1.02-3.844-.992-4.115.079-1.77.887-2.61 1.556-3.003a3.4 3.4 0 0 1 1.698-.45h.054a3.4 3.4 0 0 1 1.701.461c.656.39 1.451 1.226 1.534 2.988.026.272.215 2.697-.993 4.116a2.87 2.87 0 0 1-2.23 1.008m-.07-7.68c-.687 0-2.3.255-2.403 2.616v.025c0 .022-.218 2.271.785 3.444a2.03 2.03 0 0 0 1.625.705h.032a2.04 2.04 0 0 0 1.63-.705c.996-1.175.78-3.421.776-3.444a.1.1 0 0 1-.003-.026c-.098-2.23-1.548-2.596-2.403-2.616z"
    />
    <path
      fill={color}
      d="M16.575 14.69V14.6c-.023-.644-.066-2.143-1.459-2.623l-.032-.01a9.2 9.2 0 0 1-2.664-1.226.43.43 0 0 0-.603.107.44.44 0 0 0 .106.61 10 10 0 0 0 2.932 1.353c.748.269.832 1.077.854 1.816a5.962 5.962 0 0 1-.066 1.084 11.7 11.7 0 0 1-5.663 1.33 11.8 11.8 0 0 1-5.663-1.334 5.6 5.6 0 0 1-.066-1.003v-.08c.023-.74.107-1.548.858-1.817a10 10 0 0 0 2.93-1.353.44.44 0 0 0-.174-.79.43.43 0 0 0-.325.074 9.1 9.1 0 0 1-2.665 1.226l-.032.01c-1.392.483-1.434 1.982-1.454 2.624q0 .042-.004.083v.01c-.018.494.038.989.165 1.467.032.084.09.156.167.204a12.06 12.06 0 0 0 6.268 1.55c2.19.061 4.356-.475 6.265-1.55a.43.43 0 0 0 .167-.204c.121-.48.174-.973.158-1.466M17.75.75a.75.75 0 0 0-1.5 0v4.5a.75.75 0 0 0 1.5 0z"
    />
    <path fill={color} d="M19.25 3.75a.75.75 0 0 0 0-1.5h-4.5a.75.75 0 0 0 0 1.5z" />
  </svg>
);

export default CreateGuestIcon;
