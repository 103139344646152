import moment from 'moment-timezone';
import { findPhoneNumbersInText } from 'libphonenumber-js';
import { formatDateToServerDateTime, getCurrentDateAndTime } from 'utils/date-and-time';
import { TIME_SLOT_FORMAT, DATE_FORMAT, DATE_FORMAT_CALENDAR } from 'constants/time-and-date';
import { formatFirstAndLastName } from 'utils/string-helpers';
import { parsePhoneNumberOnSearch } from 'utils/phone-number';
import { getReservationGuest } from '../ReservationsList/services';
import { DEFAULT_TURN_TIME, SOURCE_OF_RESERVATION } from 'modules/ReservationsList/constants';
import { TURN_TIME_TYPE, RESERVATION_FUNNEL_STEPS } from './constants';

export const transformAvailabilityAndSlotsAPIResponse = (availabilityAndSlotsResponse: any) => {
  const slotsAndAvailability = availabilityAndSlotsResponse.map(
    ({ reservationAvailability, slots }: any) => {
      const availabilityAndSlotObj = {
        availability: reservationAvailability,
        slots: {},
      };

      if (slots?.length) {
        slots.forEach((slot: any) => {
          const { startTime } = slot;
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          if (!availabilityAndSlotObj.slots[startTime]) {
            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            availabilityAndSlotObj.slots[startTime] = [];
          }
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          availabilityAndSlotObj.slots[startTime].push(slot);
        });
      }
      return availabilityAndSlotObj;
    }
  );

  return slotsAndAvailability;
};

// Groups tables into an object where keys are roomId's
const groupTablesByRoom = (tables: any) =>
  tables.reduce((grouped: any, table: any) => {
    const roomId = table.room?.id;
    if (!roomId) {
      return grouped;
    }
    if (grouped[roomId]?.tables) {
      grouped[roomId].tables.push(table);
    } else {
      grouped[roomId] = {
        tables: [table],
        room: table.room,
      };
    }
    return grouped;
  }, {});

// Takes an object of rooms and returns an array of rooms sorted by the `order` field
const sortRooms = (grouped: any) =>
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  Object.values(grouped)?.sort((a, b) => (a.room?.order ?? 0) - (b.room?.order ?? 0));

export const isMultipleTables = (selectedItem: any) => selectedItem.length >= 2;

const assignTables = (tables: any, assignableTables: any) =>
  tables.map((table: any) => ({
    ...table,

    isAvailable: assignableTables?.available?.some(({ id }: any) => +(table?.id ?? 0) === +id),

    isNotAvailable: assignableTables?.notAvailable?.some(
      ({ id }: any) => +(table?.id ?? 0) === +id
    ),

    isUpcoming: assignableTables?.upcoming?.some(({ id }: any) => +(table?.id ?? 0) === +id),
  }));

export const getTablesByRoom = (tables: any, assignableTables: any) =>
  sortRooms(groupTablesByRoom(assignTables(tables, assignableTables)));

export const getSelectedTables = (tables = [], ids = []) =>
  tables.filter(({ id }) => ids?.indexOf(id) >= 0);

export const getSelectedTableNames = (tables = [], ids = []) =>
  getSelectedTables(tables, ids)
    .map(({ name }) => name)
    .join(', ');

// Returns the table combination if the provided table ids form a combination together
export const getSelectedTableCombination = (combinations = [], ids = []) => {
  const sortedIds = ids.sort().toString();
  // @ts-expect-error TS(2339): Property 'sort' does not exist on type 'never'.
  return combinations.find(({ tableIds }) => tableIds.sort().toString() === sortedIds);
};

export const getBlockedOrLockedTablesNames = (tables: any) => {
  const tableNames = tables?.map(({ name }: any) => name);
  // @ts-expect-error TS(2802): Type 'Set<unknown>' can only be iterated through w... Remove this comment to see the full error message
  return [...new Set(tableNames)].join(', ');
};

export const getSelectedNotAvailableTables = (selectedTables: any, assignableTables: any) =>
  assignTables(selectedTables, assignableTables).filter((table: any) => table?.isNotAvailable);

export const getSelectedUpcomingTables = (selectedTables: any, assignableTables: any) =>
  assignTables(selectedTables, assignableTables).filter((table: any) => table?.isUpcoming);

export const getTablesTurnTime = (
  tables: any,
  tableCombination: any,
  turnTimeType: any,
  defaultTurnTime = DEFAULT_TURN_TIME
) => {
  if (tableCombination) {
    return turnTimeType === TURN_TIME_TYPE.SHORT
      ? tableCombination.shortTurnTime
      : tableCombination.turnTime;
  }
  if (tables.length) {
    return Math.max(
      ...tables.map(({ turnTime, shortTurnTime }: any) =>
        turnTimeType === TURN_TIME_TYPE.SHORT ? shortTurnTime : turnTime
      )
    );
  }
  return defaultTurnTime;
};

// Returns the minimum and maximum size for the selected tables or the combination if provided
export function getTableSizes(tables: any, combination: any) {
  // If the selected tables form a combination, use the size of the combination
  if (combination) {
    return {
      minTableSize: combination.minSize,
      maxTableSize: combination.maxSize,
    };
  }
  // When the selected tables don't form a combination,
  // the sizes will be the total of all selected tables' sizes together
  return tables.reduce(
    // @ts-expect-error TS(7006): Parameter 'acc' implicitly has an 'any' type.
    (acc, { maxSize, minSize }: any) => ({
      minTableSize: acc.minTableSize + minSize,
      maxTableSize: acc.maxTableSize + maxSize,
    }),
    { minTableSize: 0, maxTableSize: 0 }
  );
}

const getAvailabilityById = (availabilityAndSlots: any, availabilityId: any) => {
  const selectedAvailabilityAndSlot = availabilityAndSlots.find(
    ({ availability }: any) => availability?.id === availabilityId
  );
  return selectedAvailabilityAndSlot?.availability;
};

const shouldDisplayEndTime = (availability: any, timeSlot: any) => {
  // transform timeSlot to HH:mm format
  const formattedTimeSlot = moment(timeSlot, TIME_SLOT_FORMAT).format('HH:mm');
  // The times for which the end time should be shown is stored on the rules inside the `by_hour` pacing
  const pacing = availability.pacings.find(({ type }: any) => type === 'by_hour');
  if (pacing) {
    const displayTimes = pacing.rules.reduce(
      (times: any, rule: any) => (rule.displayEndTime ? { ...times, [rule.time]: true } : times),
      {}
    );
    return !!displayTimes[formattedTimeSlot];
  }
  return false;
};

export const getReservationTurnTime = ({
  turnTime,
  tables,
  selectedTableIds,
  tablesCombinations,
  turnTimeType,
  defaultTurnTime,
}: any) => {
  if (!turnTime) {
    return getTablesTurnTime(
      getSelectedTables(tables, selectedTableIds),
      getSelectedTableCombination(tablesCombinations, selectedTableIds),
      turnTimeType,
      defaultTurnTime
    );
  }
  return turnTime;
};

export const getSelectedTablesBlocked = (selectedTables: any, timeSlot: any) =>
  selectedTables?.map(({ name, blockedTables }: any) =>
    blockedTables
      .map((table: any) => ({
        ...table,
        name,
      }))
      .filter(({ dateTimeEnd, dateTimeStart }: any) =>
        moment(timeSlot, TIME_SLOT_FORMAT).isBetween(dateTimeStart, dateTimeEnd, null, '[]')
      )
  );

export const parseNewGuestQuery = (query: any, callingCode: any) => {
  const guest = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  };

  if (!query) return guest;

  const words = query?.trim()?.split(' ');
  const isEmail = (str: any) => /\S+@\S+\.\S+/.test(str);
  const isPhone = (str: any) => findPhoneNumbersInText(str).length || /^\+?\d+$/.test(str);
  const parsedWords = words.reduce((acc: any, word: any) => {
    const str = word.trim();
    if (str) {
      if (isEmail(str)) return { ...acc, email: str };
      if (isPhone(str)) return { ...acc, phone: acc.phone?.concat(`${str}`) };
      return { ...acc, firstName: acc.firstName?.concat(` ${str} `) };
    }
    return acc;
  }, guest);

  return {
    ...parsedWords,
    ...formatFirstAndLastName(parsedWords?.firstName),
    phone: parsePhoneNumberOnSearch(parsedWords.phone, callingCode),
  };
};

export const createReservationPayload = ({
  reservationFunnel,
  callingCode,
  isSmsChecked,
  turnTimeType,
  defaultTurnTime,
  frontendErrorMessage,
}: {
  reservationFunnel: any;
  callingCode: any;
  isSmsChecked: boolean;
  turnTimeType: any;
  defaultTurnTime: number;
  frontendErrorMessage: string;
}) => {
  const {
    isManualPaymentReactivation,
    paymentExpireHours,
    timeSlot,
    partySize,
    guest,
    selectedTableIds = [],
    lockTables = [],
    tagIds = [],
    notes,
    errorMessage,
    notifylistId,
    isWalkin,
    isTemporaryGuest,
    guestQuery,
    isManualPayment,
    communication,
    availabilityId,
    turnTime,
    tables,
    offlineWaitlistId,
    template,
    tablesCombinations,
    thirdPartyBooker,
    availabilityAndSlots,
    suggestionId,
    sourceOfReservation,
    status,
  } = reservationFunnel;
  const tableTurnTime = getReservationTurnTime({
    turnTime,
    tables,
    selectedTableIds,
    tablesCombinations,
    turnTimeType,
    defaultTurnTime,
  });
  const tableCombination = getSelectedTableCombination(tablesCombinations, selectedTableIds);
  const availability = getAvailabilityById(availabilityAndSlots, availabilityId);
  let { firstName = '', lastName = '', phone = '', email = '' } = guest;

  if (isTemporaryGuest) {
    ({ firstName, lastName, email, phone } = parseNewGuestQuery(guestQuery, callingCode));
  }

  return {
    newLogic: true,
    timeOverride: true,
    reservationDateTime: timeSlot?.startTime || timeSlot,
    reservationAvailabilityId: availabilityId,
    manualPayment: isManualPayment,
    // We only need to send this attribute when it is true, Backend doesn't need it when it is not true.
    ...(isManualPaymentReactivation && { reactivatePaymentLink: isManualPaymentReactivation }),
    thirdPartyId: thirdPartyBooker?.id,
    // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
    combinationId: tableCombination?.id ?? null,
    reservation: {
      paymentExpireHours,
      guestCount: partySize,
      primaryGuestId: isTemporaryGuest ? null : guest.id,
      sourceOfReservation: isWalkin
        ? SOURCE_OF_RESERVATION.WALKIN
        : sourceOfReservation || SOURCE_OF_RESERVATION.INHOUSE,
      notes,
      firstName,
      lastName,
      phone,
      email,
      tableIds: selectedTableIds,
      lockTables,
      turnTime: tableTurnTime,
      errorOverride: !!errorMessage,
      ...(notifylistId && { notifylistId }),
      tagIds,
      walkin: isWalkin || false,
      smsBlock: isManualPayment ? !communication.smsPayment : !communication.sms,
      emailBlock: isManualPayment ? !communication.emailPayment : !communication.email,
      webValidate: !!frontendErrorMessage,
      ...(offlineWaitlistId && { offlineWaitlistId }),
      // template only works if its not a manual payment and sms confirmation is true
      ...(template?.id && !isManualPayment && isSmsChecked && { templateId: template.id }),
      reservationType: turnTimeType,
      ...(availability && { displayEndTime: shouldDisplayEndTime(availability, timeSlot) }),
      suggestionId,
      ...(status && { status }),
    },
  };
};

export const createWaitlistReservationPayload = (reservationFunnel: any, venueId: any) => {
  const {
    partySize,
    guest: { id, firstName = '', name = '', lastName = '', phone = '', email = '' },
    notifyTimes: { startTime, endTime, notifyDate },
    notes,
    tagIds = [],
  } = reservationFunnel;
  return {
    notifylist: {
      firstName: firstName || name,
      lastName,
      email,
      phone,
      startTime,
      endTime,
      venueId,
      guestCount: partySize,
      notifyDate,
      reservationAvailabilityId: null,
      masterGuestId: id,
      notes,
      tagIds,
      sourceOfCreate: SOURCE_OF_RESERVATION.INHOUSE,
    },
  };
};

export const createQueueReservationPayload = (
  reservationFunnel: any,
  venueId: any,
  callingCode = ''
) => {
  const {
    partySize,
    isTemporaryGuest,
    guest: { name = '', ...guest },
    guestQuery,
  } = reservationFunnel;
  let { firstName = '', lastName = '', phone = '', email = '' } = guest;
  // split name to extract first and last name from master guest
  const [fname, lname] = (name || '').split(' ');

  if (isTemporaryGuest) {
    ({ firstName, lastName, email, phone } = parseNewGuestQuery(guestQuery, callingCode));
  }
  return {
    offlineWaitlist: {
      firstName: firstName || fname || guestQuery,
      lastName: lastName || lname,
      email,
      phone,
      guestCount: partySize,
      masterGuestId: isTemporaryGuest ? null : guest.id,
      temporaryGuest: isTemporaryGuest,
      venueId,
    },
  };
};

export const isWaitlistCreation = (notifyTimes: any) =>
  !!(notifyTimes?.startTime && notifyTimes?.endTime && notifyTimes?.notifyDate);

export const getReservationEndTime = (timeSlot: any, turnTime: any) => {
  if (timeSlot)
    return formatDateToServerDateTime(
      moment(timeSlot, TIME_SLOT_FORMAT).add(turnTime, 'minutes'),
      TIME_SLOT_FORMAT
    );
  return formatDateToServerDateTime(moment().add(turnTime, 'minutes'), TIME_SLOT_FORMAT);
};

export const getReservationStartTime = (timeSlot: any) =>
  timeSlot || formatDateToServerDateTime(moment(), TIME_SLOT_FORMAT);

export const getTableReservation = (assignableTable: any, tableId: any) =>
  assignableTable
    ?.filter(({ id }: any) => id === tableId)
    .map(({ reservation }: any) => reservation);

export const getFunnelTableLockStatus = (lockedTables: any) =>
  lockedTables.length > 0 && lockedTables[0] !== 0;

export const isSmsOrEmailDisabled = ({ parsedGuest, guest, reservation }: any) => {
  const { email, phone } = parsedGuest;
  const reservationGuest = getReservationGuest(reservation);
  const reservationEmail = reservationGuest?.email || reservation?.email;
  const reservationPhone = reservationGuest?.phone || reservation?.phone;
  return {
    isSmsDisabled: !phone && !guest?.phone && !reservationPhone,
    isEmailDisabled: !email && !guest?.email && !reservationEmail,
  };
};

// Returns the first day (`YYYY-MM-DD` format) from the calendar that has slots available
export function findFirstAvailableDay(calendarData: any) {
  let firstAvailableDay: any = null;
  const currentDay = getCurrentDateAndTime().format(DATE_FORMAT_CALENDAR);
  // Calendar response format will be object with `YYYYMMDD` as keys
  // Since order is not guaranteed inside objects sort it by the dates
  const calendarDataSorted = Object.entries(calendarData).sort(
    ([dateA], [dateB]) => parseInt(dateA, 10) - parseInt(dateB, 10)
  );

  // @ts-expect-error TS(2339): Property 'isOpen' does not exist on type 'unknown'... Remove this comment to see the full error message
  calendarDataSorted.forEach(([day, { isOpen, fullyReserved, noAvailability }]) => {
    // Only check for current day and later, and only when there isn't found a firstAvailableDay yet
    if (parseInt(day, 10) >= parseInt(currentDay, 10) && !firstAvailableDay) {
      if (isOpen && !fullyReserved && !noAvailability) {
        firstAvailableDay = moment(day, DATE_FORMAT_CALENDAR).format(DATE_FORMAT);
      }
    }
  });

  return firstAvailableDay;
}

export const getAvailabilityStartDate = (date: any, monthsPerRequest: any, index = 0) =>
  moment(date, DATE_FORMAT)
    .startOf('month')
    .add(0 + index * monthsPerRequest, 'months')
    .format(DATE_FORMAT);

export const getAvailabilityEndDate = (date: any, monthsPerRequest: any, index = 0) =>
  moment(date, DATE_FORMAT)
    .startOf('month')
    .add(monthsPerRequest + index * monthsPerRequest, 'months')
    .format(DATE_FORMAT);

export const getFunnelFooterLabel = ({
  mobile,
  selectedTableIds,
  isReservationWithGuest,
  isEdit,
  isWalkin,
  buttonErrorMessage,
  errorMessage,
  backendErrorMessage,
  isWaitlist,
  currentStep,
}: any) => {
  let buttonLabel = '';
  if (currentStep === RESERVATION_FUNNEL_STEPS.CONFIRM_RESERVATION) {
    if (backendErrorMessage) return 'Confirm override';
    const buttonText = isWaitlist ? 'Add to waitlist' : 'Create reservation';
    buttonLabel = isEdit ? 'Confirm edit' : buttonText;
  } else {
    if (errorMessage) return buttonErrorMessage;
    const reservationType = isWalkin ? 'walk-in' : 'reservation';
    const continueLabel = selectedTableIds.length ? 'Continue' : 'Continue without table';
    const editLabel = selectedTableIds.length
      ? `Confirm & edit ${reservationType}`
      : `Confirm & edit ${reservationType} without table`;
    const createLabel = selectedTableIds.length
      ? `Confirm & Create ${reservationType}`
      : `Confirm & Create ${reservationType} without table`;
    const selectedLabel = mobile && !isReservationWithGuest ? continueLabel : createLabel;
    buttonLabel = isEdit ? editLabel : selectedLabel;
  }
  return buttonLabel;
};
