import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'constants/colors';

interface ConfirmedSmsIconProps {
  width?: number;
  color?: string;
}

const ConfirmedSmsIcon = ({ width = 30, color = COLORS.WHITE }: ConfirmedSmsIconProps) => (
  <svg
    data-name="icon/coloured/msg"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    viewBox="0 0 30 30"
  >
    <path
      data-name="Fill 1"
      d="M29.292 9.807a.7.7 0 0 0-.708.687v8.217a3.193 3.193 0 0 1-3.233 3.138H15.037a.691.691 0 0 0-.486.195L8.455 27.7v-5.164a.7.7 0 0 0-.708-.687H4.65a3.193 3.193 0 0 1-3.233-3.138V4.513A3.193 3.193 0 0 1 4.65 1.375h14.693a.7.7 0 0 0 .708-.687.7.7 0 0 0-.708-.688H4.65A4.587 4.587 0 0 0 0 4.513V18.7a4.583 4.583 0 0 0 4.65 4.513h2.382v6.1a.692.692 0 0 0 .429.632.8.8 0 0 0 .279.055.734.734 0 0 0 .494-.194l7.089-6.589H25.35A4.583 4.583 0 0 0 30 18.7v-8.21a.7.7 0 0 0-.708-.687"
      fill={color}
    />
    <path
      data-name="Fill 4"
      d="M13.276 16.266a.514.514 0 0 0 .708-.061l8.265-9.3a.517.517 0 1 0-.774-.686l-7.937 8.935-4.781-3.826a.517.517 0 1 0-.646.807Z"
      fill={color}
    />
  </svg>
);

ConfirmedSmsIcon.propTypes = {
  width: PropTypes.number,
  color: PropTypes.string,
};

export default ConfirmedSmsIcon;
