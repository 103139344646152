import React from 'react';
import classNames from 'classnames';
import { SkeletonLoader, useViewPortContext } from '@umai/common';
import styles from './Loader.module.scss';

function Loader() {
  const { mobile } = useViewPortContext();

  return (
    <section className={styles.incomingCallLoader}>
      <header className={styles.header}>
        <h4 className={styles.title}>UPhone</h4>
        <p className={styles.subtitle}>
          UPhone shows you guest information when receiving calls and automatically fills out their
          information when you create a reservation.
        </p>
      </header>
      <header className={styles.header}>
        <h4 className={styles.title}>Unavailable mode</h4>
        {!mobile && (
          <p className={styles.subtitle}>
            Turn this on when you don’t want to receive incoming phone calls. We’ll send a message
            to the guest with your selected...
          </p>
        )}
      </header>
      <header className={styles.header}>
        <h4 className={styles.title}>In-app call notification</h4>
        {!mobile && (
          <p className={styles.subtitle}>
            Receive an information popup with guest & reservation details that allows you to easily
            add or edit a reservation with all the guest details pre-filled. Only affects this
            device.
          </p>
        )}
      </header>
      <div className={styles.filters}>
        {[1, 2, 3].map((buttonStyle) => (
          <SkeletonLoader.Skeleton
            key={buttonStyle}
            className={classNames(styles.filterButton, buttonStyle)}
            baseColor="#fff"
            highlightColor="#f4f4f4"
            width="100px"
            style={{ overflow: 'hidden' }}
          />
        ))}
      </div>
      <div className={styles.callLogList}>
        <SkeletonLoader.List lines={4} />
      </div>
    </section>
  );
}

export default Loader;
