import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'constants/colors';

interface NotesIconProps {
  width?: number | string;
  height?: number | string;
  color?: string;
  title?: string;
}

function NotesIcon({
  width = 16,
  height = 16,
  color = COLORS.BLACK,
  title = 'Notes',
}: NotesIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <path
        d="M3.766 20A3.766 3.766 0 010 16.234V3.772A3.766 3.766 0 013.766 0h12.462A3.766 3.766 0 0120 3.766v12.986a.407.407 0 01-.814 0V3.766A2.952 2.952 0 0016.234.814H3.772A2.95 2.95 0 00.814 3.766v12.462a2.952 2.952 0 002.952 2.952h12.296a.407.407 0 110 .814L3.765 20zm-.34-5a.726.726 0 111.452 0 .726.726 0 01-1.452 0zm2.746.486a.486.486 0 010-.97h4.343a.486.486 0 010 .97H6.172zM3.426 9.99a.726.726 0 111.453 0 .726.726 0 01-1.453 0zm2.746.485a.484.484 0 110-.97h8.888a.485.485 0 010 .97H6.172zM3.426 4.98a.726.726 0 111.453 0 .726.726 0 01-1.453 0zm2.746.485a.485.485 0 010-.97h9.899a.484.484 0 010 .97H6.172z"
        fill={color}
      />
    </svg>
  );
}

NotesIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string,
  title: PropTypes.string,
};

export default NotesIcon;
