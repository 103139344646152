import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'constants/colors';

interface CancelledIconProps {
  width?: number;
  color?: string;
}

function CancelledIcon({ width = 20, color = COLORS.WHITE }: CancelledIconProps) {
  return (
    <svg width={width} fill="none" viewBox="0 0 20 20">
      <g clipPath="url(#prefix__clip0)" fill={color}>
        <path d="M19.829 14.536a6.28 6.28 0 01-1.125 2.78 6.593 6.593 0 01-9.042 1.528 6.261 6.261 0 01-1.565-8.843 6.593 6.593 0 019.042-1.529.49.49 0 01.122.693.51.51 0 01-.7.119 5.573 5.573 0 00-7.642 1.291 5.279 5.279 0 001.321 7.457 5.573 5.573 0 007.641-1.291 5.22 5.22 0 00.949-2.342 5.283 5.283 0 00-.228-2.428.496.496 0 01.32-.628.506.506 0 01.636.314 6.26 6.26 0 01.271 2.879z" />
        <path d="M16.1 11.874a.648.648 0 10-.915-.916l-4.427 4.427a.648.648 0 10.916.915l4.427-4.426z" />
        <path d="M15.185 16.3a.648.648 0 10.915-.915l-4.426-4.426a.648.648 0 10-.916.915l4.427 4.427z" />
        <path d="M5.672 14.196a.419.419 0 00-.452-.413.427.427 0 00-.4.427v3.294H3.471a1.255 1.255 0 01-1.271-1.24v-.455h.917a.43.43 0 00.437-.386.418.418 0 00-.422-.441H2.2v-3.309h.917a.43.43 0 00.437-.386.418.418 0 00-.422-.441H2.2V7.537h.917a.429.429 0 00.438-.385.418.418 0 00-.422-.441H2.2V3.402h.917a.429.429 0 00.437-.386.418.418 0 00-.422-.441H2.2V2.12A1.256 1.256 0 013.471.879h1.354v11.892a.427.427 0 00.815.18.419.419 0 00.036-.166V.879h8.042a1.256 1.256 0 011.27 1.241v4.548a.4.4 0 00.8 0V1.706A2.108 2.108 0 0013.714.052H3.471A2.093 2.093 0 001.355 2.12v.455H.438A.429.429 0 000 2.961a.418.418 0 00.422.441h.931V6.71H.438A.429.429 0 000 7.096a.418.418 0 00.422.441h.931v3.308H.438a.429.429 0 00-.438.387.418.418 0 00.422.441h.931v3.308H.438a.429.429 0 00-.438.386.418.418 0 00.422.441h.931v.455a2.093 2.093 0 002.116 2.068h3.594a.414.414 0 100-.827H5.672v-3.308z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill={color} d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

CancelledIcon.propTypes = {
  width: PropTypes.number,
  color: PropTypes.string,
};

export default CancelledIcon;
