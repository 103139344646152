import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { COLORS } from 'constants/colors';
import NoShowIcon from 'components/icons/statuses/NoShowIcon';
import styles from './NoShowCount.module.scss';

interface NoShowCountProps {
  count?: string | number;
  color?: string;
}

const NoShowCount = ({ count, color = COLORS.DANGER }: NoShowCountProps) => {
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const isMoreThenZeroNoShow = +count > 0;

  return (
    <span className={classnames({ [styles.noShow]: isMoreThenZeroNoShow })}>
      {isMoreThenZeroNoShow && <NoShowIcon color={color} width={17} />}
      {count}
    </span>
  );
};

NoShowCount.propTypes = {
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
};

export default NoShowCount;
