export type ChargeType = (typeof CHARGE_TYPES)[keyof typeof CHARGE_TYPES];

export const CHARGE_STEPS = {
  CONFIRMATION: 'confirmation',
  CHARGE_AMOUNT: 'chargeAmount',
  CUSTOM_CHARGE_AMOUNT: 'customChargeAmount',
  CHARGE_STATUS_ERROR: 'chargeStatusError',
};

export const CHARGE_TYPES = {
  deposit: 'deposit',
  cancellation: 'cancellation',
  supplement: 'supplement',
} as const;

export const REFUND_CHARGE_TYPES: Omit<ChargeType, 'cancellation'>[] = [
  CHARGE_TYPES.deposit,
  CHARGE_TYPES.supplement,
];

export const CHARGE_TYPES_NAME = {
  [CHARGE_TYPES.deposit]: 'deposit',
  [CHARGE_TYPES.supplement]: 'supplements',
  [CHARGE_TYPES.cancellation]: 'cancellation',
};

export const PAYMENT_STATUS = {
  success: 'success',
  refund: 'refund',
  failed: 'failed',
  pending: 'pending',
  cancelled: 'cancelled',
  accepted: 'accepted',
  // reservation payment statuses:
  free: 'free',
};

export const FEE_TYPES = {
  fixedFee: 'fixed_fee',
  perCover: 'per_cover',
};
