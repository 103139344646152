import React from 'react';
import { useAppSelector } from 'hooks/redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useViewPortContext } from '@umai/common';
import {
  selectGuestReservationSummaryLoading,
  selectGuestReservationSummary,
} from 'modules/Guest/selectors';
import { transactionsSummaryOrder } from 'modules/Guest/services';
import CurrencyFormatter from 'components/shared/CurrencyFormatter';
import NoShowCount from 'components/shared/NoShowCount';
import GuestTransactionsSummaryPopover from './GuestTransactionsSummaryPopover';
import GuestTransactionsSummaryLoader from './Loader';
import type { GuestReservationSummary } from 'modules/Guest/types';
import './GuestTransactionsSummary.styles.scss';

interface TransactionsSummaryProps {
  type?: 'venue' | 'partner';
  isTooltipEnabled?: boolean;
  order?: {
    totalSpend?: number;
    visits?: number;
    avgPerVisit?: number;
    avgPerCover?: number;
    noShows?: number;
    cancellations?: number;
  };
  showZeroSpend?: boolean;
  header?: React.ReactNode;
  isPartnerVisible?: boolean;
  customReservationSummary?: GuestReservationSummary;
  className?: string;
}

const TransactionsSummary = ({
  type = 'venue',
  isTooltipEnabled = true,
  order = {},
  showZeroSpend = true,
  header,
  isPartnerVisible = true,
  customReservationSummary,
  className,
}: TransactionsSummaryProps) => {
  const isLoadingReservationSummary = useAppSelector(selectGuestReservationSummaryLoading);
  const reservationSummary = useAppSelector(selectGuestReservationSummary);
  const { mobile } = useViewPortContext();
  const trigger = mobile ? 'click' : 'hover';
  const transactionOrder = transactionsSummaryOrder(order);
  const {
    netSpend = 0,
    totalVisit = 0,
    avgVisitReservations = 0,
    avgCoverReservations = 0,
    totalCancelledReservations = 0,
    totalNoShowedReservations = 0,
  } = customReservationSummary?.[type] || reservationSummary?.[type] || {};
  const isPartner = type === 'partner';

  if (isLoadingReservationSummary && !customReservationSummary) {
    return (
      <GuestTransactionsSummaryLoader
        header={header}
        isPartner={isPartner}
        isPartnerVisible={isPartnerVisible}
        showZeroSpend={showZeroSpend}
      />
    );
  }

  if (isPartner && !isPartnerVisible) {
    return null;
  }

  return (
    <section className={classNames('guest-transactions-summary-wrapper', className)}>
      {header && isPartnerVisible ? (
        <header className="guest-transactions-summary-header">{header}</header>
      ) : null}
      <div
        aria-label="guest transaction history"
        className={classNames('guest-transactions-summary', {
          'in-two-rows': mobile || isPartnerVisible,
          'show-border': !mobile && isPartner && isPartnerVisible,
        })}
      >
        {showZeroSpend || +netSpend ? (
          <>
            <div key={transactionOrder.totalSpend} style={{ order: transactionOrder.totalSpend }}>
              <GuestTransactionsSummaryPopover
                isTooltipEnabled={isTooltipEnabled}
                placement="bottomRight"
                transactionName="Total spend"
                trigger={trigger}
              >
                {isPartner
                  ? 'The total amount that the guest has spent in all venues in your group.'
                  : 'The total amount that the guest has spent at your venue.'}
              </GuestTransactionsSummaryPopover>
              <div aria-label={`Total spend ${netSpend}`}>
                <CurrencyFormatter amount={netSpend ?? '0.0'} />
              </div>
            </div>
            <div key={transactionOrder.avgPerVisit} style={{ order: transactionOrder.avgPerVisit }}>
              <GuestTransactionsSummaryPopover
                isTooltipEnabled={isTooltipEnabled}
                placement="bottomRight"
                transactionName="Avg per visit"
                trigger={trigger}
              >
                {isPartner
                  ? 'The average spend this guest has had across all venues in your group.'
                  : 'The average spend this guest has had when visiting your venue.'}
              </GuestTransactionsSummaryPopover>
              <div
                title="Average per visit"
                aria-label={`Average per visit ${avgVisitReservations}`}
              >
                <CurrencyFormatter amount={avgVisitReservations ?? '0.0'} />
              </div>
            </div>
            <div key={transactionOrder.avgPerCover} style={{ order: transactionOrder.avgPerCover }}>
              <GuestTransactionsSummaryPopover
                isTooltipEnabled={isTooltipEnabled}
                placement="bottomLeft"
                transactionName="Avg per cover"
                trigger={trigger}
              >
                {isPartner
                  ? 'The average spend, per person, for this guest and their party across all venues in your group.'
                  : 'The average spend, per person, when this guest has visited your venue.'}
              </GuestTransactionsSummaryPopover>
              <div
                title="Average per cover"
                aria-label={`Average per cover ${avgCoverReservations}`}
              >
                <CurrencyFormatter amount={avgCoverReservations ?? '0.0'} />
              </div>
            </div>
          </>
        ) : null}
        <div key={transactionOrder.visits} style={{ order: transactionOrder.visits }}>
          <GuestTransactionsSummaryPopover
            isTooltipEnabled={isTooltipEnabled}
            placement={mobile ? 'bottom' : 'bottomLeft'}
            transactionName="Visits"
            trigger={trigger}
          >
            {isPartner
              ? 'The total amount of visits that the guest has at your venue as well as all other venues in your group.'
              : 'The total amount of visits that the guest has at your venue.'}
          </GuestTransactionsSummaryPopover>
          <div aria-label={`Visits ${totalVisit}`}>{totalVisit}</div>
        </div>
        <div key={transactionOrder.noShows} style={{ order: transactionOrder.noShows }}>
          <GuestTransactionsSummaryPopover
            isTooltipEnabled={isTooltipEnabled}
            placement={mobile ? 'bottom' : 'bottomRight'}
            transactionName="No-shows"
            trigger={trigger}
          >
            {isPartner
              ? 'The amount of no-shows this guest has had across venues in your group, including your venue. If the guest has any no-shows a warning icon will appear on their profile and on the reservation lists.'
              : 'The amount of no-shows this guest has had at your venue. If the guest has any no-shows a warning icon will appear on their profile and on the reservation lists.'}
          </GuestTransactionsSummaryPopover>

          <div aria-label={`No-shows ${totalNoShowedReservations}`}>
            <NoShowCount count={totalNoShowedReservations} />
          </div>
        </div>
        <div key={transactionOrder.cancellations} style={{ order: transactionOrder.cancellations }}>
          <GuestTransactionsSummaryPopover
            isTooltipEnabled={isTooltipEnabled}
            placement="bottomLeft"
            transactionName="Cancellations"
            trigger={trigger}
          >
            {isPartner
              ? 'Total cancellations in your venue and all other venues in your group.'
              : 'Total cancellations this guest has had at your venue.'}
          </GuestTransactionsSummaryPopover>
          <div aria-label={`Cancellations ${totalNoShowedReservations}`}>
            {totalCancelledReservations}
          </div>
        </div>
      </div>
    </section>
  );
};

TransactionsSummary.propTypes = {
  type: PropTypes.oneOf(['venue', 'partner']),
  isTooltipEnabled: PropTypes.bool,
  order: PropTypes.shape({
    totalSpend: PropTypes.number,
    visits: PropTypes.number,
    avgPerVisit: PropTypes.number,
    avgPerCover: PropTypes.number,
    noShows: PropTypes.number,
    cancellations: PropTypes.number,
  }),
  showZeroSpend: PropTypes.bool,
  header: PropTypes.node,
  isPartnerVisible: PropTypes.bool,
};

export default TransactionsSummary;
