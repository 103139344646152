import React from 'react';
import { COLORS } from 'constants/colors';
import { getHumanizedStatus, getReservationStatusTextColor } from 'services/status';
import ReservationStatusIcon from 'components/shared/ReservationStatusIcon';
import { STATUSES } from 'modules/ReservationsList/constants';
import styles from './CallPopup.module.scss';

type Props = {
  status: string;
};
export default function Status({ status }: Props) {
  const isNoShow = status.toUpperCase() === STATUSES['NO-SHOW'];
  return (
    <span
      className={styles.status}
      style={isNoShow ? { color: COLORS.DANGER } : getReservationStatusTextColor(status)}
    >
      ({' '}
      <ReservationStatusIcon
        status={status}
        width={18}
        color={isNoShow ? COLORS.DANGER : undefined}
      />
      <span>{getHumanizedStatus(status)} )</span>
    </span>
  );
}
