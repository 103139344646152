/* eslint-disable camelcase */
import { setDataToStorage, getDataFromStorage } from '@umai/common';
import { createEventListener } from 'utils/event-listeners';
import type { VenueId } from 'modules/Venue/types';

const ordersFlashKey = 'orderIdsThatFlashes';
export const ordersFlashEvent = 'orderIdsToFlashInserted';

const addOrderTOFlashing = (id: any, venueId: VenueId) => {
  const flashOrderId = +id;
  if (flashOrderId && venueId) {
    const ordersId = getDataFromStorage(ordersFlashKey) || {};
    if (ordersId?.[venueId]?.length) {
      if (!ordersId?.[venueId]?.includes?.(flashOrderId)) {
        ordersId[venueId] = [...ordersId[venueId], flashOrderId];
      }
    } else {
      ordersId[venueId] = [id];
    }
    setDataToStorage(ordersFlashKey, ordersId);
    createEventListener(ordersFlashEvent);
  }
};

export const getOrderCountToFlashing = (venueId: any) => {
  const orders = getDataFromStorage(ordersFlashKey) || {};
  return (venueId && orders?.[venueId]?.length) || 0;
};

export const orderUpdatesFromSocket = ({ message, venue: { id: venueId, name } }: any) => {
  if (message?.params) {
    const { params } = message;
    if (params?.new_status === 'paid') {
      addOrderTOFlashing(params?.order_id, venueId);
    }
  }
};

export const getFeeKindLabel = (kind: any) => {
  switch (kind) {
    case 'included':
      return ' (incl.)';
    case 'excluded':
      return ' (excl.)';
    default:
      return '';
  }
};
