import React, { useEffect, useState } from 'react';
import { COLORS } from 'constants/colors';
import CopyIcon from 'components/icons/CopyIcon';
import CopySuccessIcon from 'components/icons/CopySuccessIcon';
import type { TimeoutId } from 'types/common';
import styles from './CopyText.module.scss';

type Props = {
  children: React.ReactNode;
  text: string;
  color?: string;
  size?: number;
};

function CopyText({ children, text, color = COLORS.BLACK, size = 16 }: Props) {
  const [isCopied, setIsCopied] = useState(false);
  let copyTextTimeout: TimeoutId;

  useEffect(
    () => () => {
      clearTimeout(copyTextTimeout);
    },
    []
  );

  const copyText = async (event: React.MouseEvent<SVGSVGElement>) => {
    try {
      event.preventDefault();
      event.stopPropagation();

      if (navigator.clipboard?.writeText) {
        await navigator.clipboard.writeText(text);
        setIsCopied(true);
      }
      copyTextTimeout = setTimeout(() => {
        setIsCopied(false);
      }, 1500);
    } catch (error) {
      setIsCopied(false);
    }
  };

  return (
    <>
      {children}
      {!isCopied ? (
        <CopyIcon
          aria-label="copy text"
          onClick={copyText}
          className={styles.copyIcon}
          color={color}
          width={size - 2}
          height={size}
        />
      ) : (
        <CopySuccessIcon
          aria-label="copied"
          className={styles.copyIcon}
          width={size}
          height={size}
        />
      )}
    </>
  );
}

export default CopyText;
