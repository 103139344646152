import React from 'react';
import type { SVGProps } from 'react';
import { COLORS } from 'constants/colors';

function AddReservationIcon({
  width = 20,
  height = 20,
  color = COLORS.WHITE,
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <path
        fill={color}
        d="M12.161 20H3.078c-.497 0-.975-.191-1.326-.53a1.78 1.78 0 0 1-.55-1.28v-.4H.375a.38.38 0 0 1-.266-.105.356.356 0 0 1 0-.513.38.38 0 0 1 .266-.106H1.2V14.17H.376a.38.38 0 0 1-.265-.106.356.356 0 0 1 0-.512.38.38 0 0 1 .265-.106H1.2v-2.896H.376a.38.38 0 0 1-.265-.106.356.356 0 0 1 0-.511.38.38 0 0 1 .265-.106H1.2V6.932H.376a.4.4 0 0 1-.265-.106A.36.36 0 0 1 0 6.57a.35.35 0 0 1 .11-.256.38.38 0 0 1 .265-.106H1.2V5.81c0-.48.199-.94.55-1.28.352-.339.83-.53 1.327-.53h9.083c.432 0 .851.145 1.186.409.335.263.565.63.653 1.038v13.104a1.8 1.8 0 0 1-.653 1.04 1.93 1.93 0 0 1-1.186.409m-7.507-3.982c.1 0 .195.039.266.106.07.068.11.16.11.256v2.896h7.131c.299 0 .585-.114.796-.318.21-.203.33-.48.33-.767V5.81c0-.289-.119-.565-.33-.769a1.15 1.15 0 0 0-.796-.318H5.03v10.42c0 .097-.039.189-.11.256a.383.383 0 0 1-.53 0 .36.36 0 0 1-.11-.255V4.724h-1.2a1.15 1.15 0 0 0-.797.318c-.211.204-.33.48-.33.768v.4h.825c.1 0 .195.037.266.105a.355.355 0 0 1 0 .512.38.38 0 0 1-.266.106h-.825v2.895h.825c.1 0 .195.038.266.106a.355.355 0 0 1 0 .511.38.38 0 0 1-.266.106h-.825v2.896h.825a.4.4 0 0 1 .266.106.36.36 0 0 1 .11.256.35.35 0 0 1-.11.256.38.38 0 0 1-.266.106h-.825v2.896h.825c.1 0 .196.038.266.106a.356.356 0 0 1 0 .513.38.38 0 0 1-.266.106h-.825v.399c0 .288.119.564.33.767s.498.318.796.318H4.28V16.38c0-.096.04-.188.11-.256a.38.38 0 0 1 .265-.105M17.978.674a.674.674 0 0 0-1.349 0V4.72a.674.674 0 1 0 1.348 0z"
      />
      <path fill={color} d="M19.326 3.37a.674.674 0 1 0 0-1.348H15.28a.674.674 0 0 0 0 1.349z" />
    </svg>
  );
}

export default AddReservationIcon;
