import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'constants/colors';

interface DessertIconProps {
  width?: number;
  color?: string;
}

function DessertIcon({ width = 20, color = COLORS.WHITE }: DessertIconProps) {
  return (
    <svg width={width} fill="none" viewBox="0 0 20 20">
      <path d="M10 19.5a9.5 9.5 0 100-19 9.5 9.5 0 000 19z" stroke={color} />
      <path
        d="M9.048 10.082h.884c1.006 0 1.631.513 1.631 1.343 0 .737-.669 1.265-1.6 1.265a1.451 1.451 0 01-1.628-1.222H7.49a2.2 2.2 0 002.48 1.982 2.247 2.247 0 002.485-2.055 1.683 1.683 0 00-1.747-1.714v-.02a1.644 1.644 0 001.447-1.674 1.983 1.983 0 00-2.212-1.816 2.114 2.114 0 00-2.31 1.963h.845a1.312 1.312 0 011.426-1.2c.894 0 1.367.474 1.367 1.172a1.262 1.262 0 01-1.378 1.233h-.845v.743z"
        fill={color}
      />
    </svg>
  );
}

DessertIcon.propTypes = {
  width: PropTypes.number,
  color: PropTypes.string,
};

export default DessertIcon;
