import { useEffect, RefObject, useState } from 'react';

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideClick<T extends HTMLElement>(
  ref: RefObject<T>,
  callback?: (isOutside: boolean) => void
) {
  const [isOutSideClick, setIsOutSideClick] = useState(false);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOutSideClick(true);
        if (callback) {
          callback(true);
        }
      } else {
        setIsOutSideClick(false);
        if (callback) {
          callback(false);
        }
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside, false);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside, false);
    };
  }, [ref]);

  return { isOutSideClick, setIsOutSideClick };
}

export default useOutsideClick;
