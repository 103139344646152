import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InfoIcon from 'components/icons/InfoIcon';
import Popover from 'components/popovers/Popover';

interface GuestTransactionsSummaryPopoverProps {
  children?: React.ReactNode;
  placement?: string;
  transactionName?: React.ReactNode;
  trigger?: string;
  isTooltipEnabled?: boolean;
}

const GuestTransactionsSummaryPopover = ({
  isTooltipEnabled,
  children,
  placement = 'bottomLeft',
  transactionName = '',
  trigger = 'hover',
}: GuestTransactionsSummaryPopoverProps) => {
  const [visible, setVisible] = useState(false);
  const triggerElement = (
    <div aria-describedby="transaction-summary-info">
      {transactionName}
      {isTooltipEnabled && <InfoIcon />}
    </div>
  );

  if (!isTooltipEnabled) return triggerElement;

  return (
    <Popover
      overlayClassName="guest-transactions-popover-overlay"
      // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'TooltipPl... Remove this comment to see the full error message
      placement={placement}
      triggerElement={triggerElement}
      // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'TooltipTr... Remove this comment to see the full error message
      trigger={trigger}
      open={visible}
      onOpenChange={setVisible}
      isBackdropEnabled={false}
    >
      <div
        aria-label={`${transactionName} tooltip`}
        id="transaction-summary-info"
        className="guest-transactions-popover-wrapper"
      >
        {children}
      </div>
    </Popover>
  );
};

GuestTransactionsSummaryPopover.propTypes = {
  children: PropTypes.node,
  placement: PropTypes.string,
  transactionName: PropTypes.node,
  trigger: PropTypes.string,
  isTooltipEnabled: PropTypes.bool,
};

export default GuestTransactionsSummaryPopover;
