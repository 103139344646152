import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'constants/colors';

interface StarterIconProps {
  width?: number;
  color?: string;
}

function StarterIcon({ width = 20, color = COLORS.WHITE }: StarterIconProps) {
  return (
    <svg width={width} fill="none" viewBox="0 0 20 20">
      <path d="M10 19.5a9.5 9.5 0 100-19 9.5 9.5 0 000 19z" stroke={color} />
      <path d="M10.352 13.513h.85V6.467h-.86L8.467 7.815v.928L10.332 7.4h.02v6.113z" fill={color} />
    </svg>
  );
}

StarterIcon.propTypes = {
  width: PropTypes.number,
  color: PropTypes.string,
};

export default StarterIcon;
