import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'constants/colors';

interface FailedPaymentIconProps {
  width?: number;
  color?: string;
}

function FailedPaymentIcon({ width = 20, color = COLORS.WHITE }: FailedPaymentIconProps) {
  return (
    <svg width={width} height={20} fill="none">
      <path
        d="M22.091 14.615A6.252 6.252 0 1119.5 8.639a.49.49 0 01-.196.881.484.484 0 01-.361-.081 5.277 5.277 0 101.273 7.349 5.21 5.21 0 00.914-2.308 5.322 5.322 0 00-.219-2.393.485.485 0 11.921-.309 6.3 6.3 0 01.259 2.837z"
        fill={color}
      />
      <path
        d="M18.452 12.09a.624.624 0 10-.882-.882l-4.264 4.264a.624.624 0 00.882.883l4.264-4.264z"
        fill={color}
      />
      <path
        d="M17.57 16.354a.624.624 0 00.883-.882l-4.264-4.264a.624.624 0 10-.883.882l4.264 4.264z"
        fill={color}
      />
      <path
        d="M7.728 14.891H2.566A2.366 2.366 0 01.15 12.588V6.195a2.366 2.366 0 012.417-2.3h10.29a2.423 2.423 0 012.314 1.64 9.273 9.273 0 00-.763.1 1.559 1.559 0 00-.378-.558 1.717 1.717 0 00-1.174-.464H2.566c-.437 0-.857.165-1.176.463a1.551 1.551 0 00-.486 1.12v.054h11.557a8.325 8.325 0 00-2.869 2.182H.908v4.156a1.553 1.553 0 00.484 1.118 1.7 1.7 0 001.174.464H7.66c.012.237.034.479.068.72v.001z"
        fill={color}
        stroke={color}
        strokeWidth={0.3}
      />
    </svg>
  );
}

FailedPaymentIcon.propTypes = {
  width: PropTypes.number,
  color: PropTypes.string,
};

export default FailedPaymentIcon;
