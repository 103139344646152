import React, { ErrorInfo } from 'react';
import classnames from 'classnames';
import * as Sentry from '@sentry/react';
import { HELP_CHAT_INTERCOM_ID } from 'constants/app';
import { showErrorMessage } from 'utils/alerts';
import { captureException, sentryFeedbackDialog } from 'utils/sentry';
import { isWebview, reloadNativeApp } from 'utils/devices';
import { openHelpCenterLink } from 'utils/url-helpers';
import { ReactComponent as ConfettiCone } from 'assets/images/icons/confetti-cone.svg';
import ParticleEffect from 'components/ui/ParticleEffect';
import './ErrorBoundary.style.scss';

interface ErrorBoundaryProps {
  children: React.ReactNode;
  className?: string;
  isReload?: boolean;
  appName?: React.ReactNode;
  confirmToAction?(...args: unknown[]): unknown;
  confirmToActionLabel?: React.ReactNode;
}

type ErrorBoundaryState = {
  hasError: boolean;
  isChunkLoadError: boolean;
};

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, isChunkLoadError: false } as ErrorBoundaryState;
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.

    let isChunkLoadError = false;
    const derivedError = error?.toString();

    if (typeof derivedError?.indexOf === 'function') {
      isChunkLoadError =
        derivedError.indexOf('ChunkLoadError') !== -1 ||
        derivedError.indexOf('Loading CSS chunk') !== -1 ||
        derivedError.indexOf("Unexpected token '<'") !== -1;
    }

    return {
      hasError: true,
      isChunkLoadError,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Customized error handling goes here!
    const { isChunkLoadError = false } = this.state;
    if (!isChunkLoadError) showErrorMessage(error.toString());
    Sentry.withScope((scope) => {
      scope.setExtras({ errorInfo });
      captureException(error);
    });
    console.error(error, errorInfo);
  }

  renderErrorBox = () => {
    const { isChunkLoadError } = this.state;
    const {
      className,
      isReload = true,
      appName = 'UMAI 360',
      confirmToAction,
      confirmToActionLabel = 'Reload',
    } = this.props;

    const isNewVersion = window?.navigator?.onLine && isChunkLoadError;

    const actionOnError = () => {
      if (isReload) {
        if (isWebview()) {
          reloadNativeApp();
          return;
        }
        window?.location?.reload?.();
      } else if (confirmToAction) {
        confirmToAction();
      }
    };

    return (
      <div className={classnames('error-boundary-wrapper', { className })}>
        <div className={classnames('error-boundary-box', { 'no-overflow': isNewVersion })}>
          {isNewVersion ? (
            <>
              <div className="title">
                A new version of {appName} is now available <ConfettiCone />
              </div>
              <div className="description">
                Celebrating every new version with exciting features, resolves bugs, and boosts
                performance. All it takes to experience the magic is to simply refresh this page by
                clicking the button below.
              </div>
              <div className="reload-button">
                <ParticleEffect>
                  <button type="button" className="uB-btn-success" onClick={actionOnError}>
                    {confirmToActionLabel}
                  </button>
                </ParticleEffect>
              </div>
            </>
          ) : (
            <>
              <div className="title">
                <ParticleEffect effect="bubble">
                  For some reason, {appName} couldn&apos;t load{' '}
                  <span className="emoji" role="img" aria-label="sad">
                    😥
                  </span>
                </ParticleEffect>
              </div>
              <div className="description">
                We apologize for any inconvenience, and we&apos; re working to resolve this issue as
                quickly as possible. Please follow the steps below to troubleshoot the problem
              </div>
              <div className="troubleshoot">
                <div className="troubleshoot-title">Troubleshooting</div>
                <div className="troubleshoot-description">Here are few things to try: </div>
                <ul className="troubleshoot-list">
                  <li>
                    <button type="button" className="link-button" onClick={actionOnError}>
                      {confirmToActionLabel}
                    </button>{' '}
                    <span className="bold">{appName} or restart your application: </span> Sometimes,
                    a simple page refresh can resolve the issue.
                  </li>
                  <li>
                    <span className="bold">Check Your Internet Connection: </span>Try opening a web
                    page or refreshing your browser to see if your connection is working.
                  </li>
                  <li>
                    Make sure your{' '}
                    <span className="bold">browser extension or security software</span> isn&apos;t
                    blocking {appName}
                  </li>
                </ul>
              </div>
              <div className="error-help-links">
                <button
                  type="button"
                  className="link-button"
                  onClick={() => {
                    openHelpCenterLink();
                  }}
                >
                  Check our Help Center
                </button>{' '}
                for more details, or{' '}
                <button type="button" className="link-button" id={HELP_CHAT_INTERCOM_ID}>
                  {' '}
                  chat with us
                </button>
                {sentryFeedbackDialog && (
                  <>
                    <span>, or report </span>
                    <button
                      type="button"
                      className="link-button"
                      onClick={() => {
                        sentryFeedbackDialog.open();
                      }}
                    >
                      issue
                    </button>
                  </>
                )}
              </div>
              <ParticleEffect className="fit-content" effect="heart">
                Thank you for your patience!
              </ParticleEffect>
            </>
          )}
        </div>
      </div>
    );
  };

  render() {
    const { children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return this.renderErrorBox();
    }
    return children;
  }
}

export default ErrorBoundary;
