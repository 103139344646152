import moment from 'moment-timezone';
import { DEFAULT_LANGUAGE } from 'constants/app';
import { getParamByISO, getISOByParam } from 'iso-country-currency';
import { CountryCode, getCountryCallingCode } from 'libphonenumber-js';
import { Country } from 'modules/Venue/types';

export const getCountryCurrencySymbolByISOCode = (isoCode = '') => {
  try {
    return getParamByISO(isoCode, 'symbol');
  } catch (err) {
    throw new Error(`ISO code does not match any country: ${isoCode}`);
  }
};

export const getCountryNameFromISO = (iso: any) => {
  try {
    return getParamByISO(iso, 'countryName');
  } catch (err) {
    console.error(`country name code does not match any country: ${iso.toUpperCase()}`);
    return '';
  }
};

const getCurrencyCodeByCountryISO = (isoCode = '') => {
  try {
    return getParamByISO(isoCode, 'currency');
  } catch (err) {
    console.error(`ISO code does not match any country: ${isoCode}`);
    return 'MYR';
  }
};

const getISOCodeByCurrencyCode = (currency = '') => {
  try {
    return getISOByParam('currency', currency.toUpperCase()); // non-uppercase currency would not work
  } catch (err) {
    throw new Error(`Currency code does not match any country: ${currency.toUpperCase()}`);
  }
};

const getCurrencyCodeFromVenue = (country: Country) => {
  try {
    return country?.currencyCode || getCurrencyCodeByCountryISO(country?.countryIsoCode);
  } catch (error) {
    console.error('currency code not found of venue', error);
    return 'MYR';
  }
};

export const getCountryISOCodeFromVenue = (country: Country) =>
  country?.countryIsoCode || getISOCodeByCurrencyCode(country?.currencyCode);

export const getVenueCurrencyAndCountryCode = (country: Country) =>
  `${getCountryISOCodeFromVenue(country)},${getCurrencyCodeFromVenue(country)}`;

export const getVenueCountryCallingCode = (country: Country) => {
  try {
    return `+${getCountryCallingCode(getCountryISOCodeFromVenue(country) as CountryCode)}`;
  } catch (err) {
    return '+60';
  }
};

export const getFirstBrowserLanguage = () => {
  const nav = window?.navigator || {};
  const browserLanguagePropertyKeys = [
    'language',
    'browserLanguage',
    'systemLanguage',
    'userLanguage',
  ];

  // support for HTML 5.1 "navigator.languages"
  if (Array.isArray(nav?.languages)) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < nav.languages.length; i++) {
      const language = nav?.languages[i];
      if (language?.length) {
        return language;
      }
    }
  }

  // support for other well known properties in browsers
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < browserLanguagePropertyKeys.length; i++) {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const language = nav?.[browserLanguagePropertyKeys[i]];
    if (language?.length) {
      return language;
    }
  }

  return null;
};

export const setMomentLocale = (locale: any) => {
  import(`moment/locale/${locale}`)
    .then(() => {
      moment.locale(locale);
    })
    .catch(() => {
      moment.locale(DEFAULT_LANGUAGE);
    });
};
