import React from 'react';
import PropTypes from 'prop-types';
import { useAppSelector } from 'hooks/redux';
import { selectCurrencyFormatter, selectVenueLanguage } from 'modules/Partner/selectors';
import { formatAmountWithCurrencySymbol } from '@umai/common';

interface CurrencyFormatterProps {
  amount?: string | number;
  minDigits?: number;
}

const CurrencyFormatter = ({ amount = 0, minDigits = 2 }: CurrencyFormatterProps) => {
  const symbol = useAppSelector(selectCurrencyFormatter);
  const defaultLanguage = useAppSelector(selectVenueLanguage);
  return (
    <>{formatAmountWithCurrencySymbol({ amount: +amount, symbol, defaultLanguage, minDigits })}</>
  );
};

CurrencyFormatter.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minDigits: PropTypes.number,
};
export default CurrencyFormatter;
