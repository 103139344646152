import type { AppDispatch, AppGetState } from 'redux-store';
import debounce from 'lodash-es/debounce';
import queueApis from 'modules/QueueList/apis';
import pick from 'lodash-es/pick';
import { GUEST_SMS_SUCCESS_TEXT } from 'constants/app';
import { getErrorMessage } from 'utils/errors';
import { showErrorMessage, showSuccessMessage } from 'utils/alerts';
import * as ActionTypes from 'modules/QueueList/action-types';

const getQueueItemsDebounce = debounce(
  async ({ venueId, date, newCacheKey, showLoader, dispatch, signal }) => {
    try {
      const queueResponse = await queueApis.getQueueItems({ venueId, date }, { signal });

      dispatch({
        type: ActionTypes.GET_QUEUE_ITEMS,
        payload: { queueResponse, cacheKey: newCacheKey },
      });
    } catch (error) {
      if (queueApis.isCancel(error)) return;

      dispatch({ type: ActionTypes.SET_QUEUE_ERROR, payload: { error } });
      showErrorMessage(getErrorMessage(error));
    } finally {
      if (showLoader) {
        dispatch({
          type: ActionTypes.SET_QUEUE_LIST_LOADING,
          payload: { isQueueLoading: false },
        });
      }
    }
  },
  400
);

/**
 * Action to dispatch amd fetch queue items from api
 * uses a `newCacheKey` that comprises of `venueId + footerDate`, and compares it with the stored `cacheKey` in store
 *
 * If matched then does not perform this action and would use queue data prefetched in the store,
 * otherwise would hit api and store the latest queue based on the keys / params.
 *
 * @namespace Action
 * @param params Parameter
 * @param params.newCacheKey - New cache key generated from venue-id and footer-date
 * @param params.showLoader - Boolean to check if want to show loader where used. Like on detail or timeline
 * @param params.venueId - current venue id
 * @param params.date - current footer date
 *
 * @returns fetch the queue items from api and set in the store
 *
 */
export const getQueueItems = (params: any) => (dispatch: AppDispatch, getState: AppGetState) => {
  const { newCacheKey, showLoader } = params;
  const {
    queue: { cacheKey },
  } = getState();

  if (newCacheKey === cacheKey) return null;

  const controller = new AbortController();

  if (showLoader) {
    dispatch({
      type: ActionTypes.SET_QUEUE_LIST_LOADING,
      payload: { isQueueLoading: true },
    });
  }

  getQueueItemsDebounce({ ...params, dispatch, signal: controller.signal });

  return controller;
};

export const setViewQueueItem = (queueId: any) => ({
  type: ActionTypes.VIEW_QUEUE_RESERVATION,
  payload: { queueId },
});

const updateQueue = async ({ queueId, data, venueId, dispatch, getState, fields = [] }: any) => {
  try {
    const requestPayload = {
      offlineWaitlist: {
        ...data,
      },
    };
    const response = await queueApis.updateQueue(requestPayload, venueId, queueId);
    const updatedQueueItem = pick(response, [
      'id',
      ...(fields?.length ? fields : Object.keys(data)),
    ]);
    dispatch({
      type: ActionTypes.UPDATE_QUEUE_ITEM,
      payload: { updatedQueueItem, footerDate: getState().footer.date },
    });
  } catch (err) {
    showErrorMessage(getErrorMessage(err));
  }
};

export const updateQueueStatus =
  (queueId: any, data: any, venueId: any) => async (dispatch: AppDispatch, getState: AppGetState) =>
    updateQueue({ queueId, data, venueId, dispatch, getState });

export const updateQueueItem =
  (queueId: any, data: any, venueId: any) => async (dispatch: AppDispatch, getState: AppGetState) =>
    updateQueue({ queueId, data, venueId, dispatch, getState });

export const updateQueueTags =
  (queueId: any, data: any, venueId: any) => async (dispatch: AppDispatch, getState: AppGetState) =>
    updateQueue({ queueId, data, venueId, dispatch, getState, fields: ['tags'] });

export const updateQueueSearchText = (queueSearchText: any) => async (dispatch: AppDispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_QUEUE_SEARCH_TEXT,
    payload: { queueSearchText },
  });
};

export const updateQueueFromSocket = (socketQueue: any) => async (dispatch: AppDispatch) => {
  const { reservationId, queueId } = socketQueue;
  // if there is a reservation ID, Item is converted to seated reservation, remove the item from the reducer
  if (reservationId) {
    dispatch({
      type: ActionTypes.REMOVE_FROM_QUEUE,
      payload: { queueId },
    });
  } else {
    dispatch({ type: ActionTypes.UPDATE_QUEUE_FROM_SOCKET, payload: socketQueue });
  }
};

export const updateQueueGuest =
  (queueItem: any) => async (dispatch: AppDispatch, getState: AppGetState) => {
    const { phone, lastName, email, firstName } = queueItem.primaryGuest;
    const updatedQueueItem = { phone, lastName, email, firstName, ...queueItem };
    dispatch({
      type: ActionTypes.UPDATE_QUEUE_ITEM,
      payload: { updatedQueueItem, footerDate: getState().footer.date },
    });
  };

export const sendQueueCommunication = async ({ queueId, smsType, venueId }: any) => {
  try {
    await queueApis.sendQueueCommunication(queueId, smsType, venueId);
    showSuccessMessage(GUEST_SMS_SUCCESS_TEXT);
  } catch (error) {
    showErrorMessage(getErrorMessage(error));
  }
};

const getQueueActivitiesDebounced = debounce(async ({ queueId, venueId }, dispatch, { signal }) => {
  try {
    const activities = await queueApis.getQueueActivities(queueId, venueId, {
      signal,
    });
    dispatch({ type: ActionTypes.GET_QUEUE_ACTIVITIES, payload: activities });
  } catch (error) {
    if (queueApis.isCancel(error)) return;
    showErrorMessage(getErrorMessage(error));
  } finally {
    dispatch({
      type: ActionTypes.SET_QUEUE_ACTIVITIES_LOADING,
      payload: false,
    });
  }
}, 500);

export const getQueueActivities = (params: any) => (dispatch: AppDispatch) => {
  const controller = new AbortController();
  getQueueActivitiesDebounced(params, dispatch, controller);
  return controller;
};

export const resetQueueCache = () => ({
  type: ActionTypes.RESET_QUEUE_CACHE,
});
