import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'constants/colors';

interface CancelledNotifyIconProps {
  width?: number;
  color?: string;
}

function CancelledNotifyIcon({ width = 20, color = COLORS.WHITE }: CancelledNotifyIconProps) {
  return (
    <svg width={width} fill="none" viewBox="0 0 20 20">
      <g clipPath="url(#prefix__clip0)" fill={color}>
        <path d="M19.941 14.654a6.05 6.05 0 01-1.084 2.677 6.35 6.35 0 01-8.709 1.469 6.028 6.028 0 01-1.507-8.513 6.35 6.35 0 018.71-1.473.473.473 0 01.116.667.491.491 0 01-.674.115 5.368 5.368 0 00-7.36 1.244 5.085 5.085 0 001.273 7.182 5.367 5.367 0 007.36-1.244c.485-.666.799-1.44.914-2.256a5.09 5.09 0 00-.219-2.339.472.472 0 01.31-.6.487.487 0 01.611.3c.301.89.39 1.84.26 2.771z" />
        <path d="M16.303 12.09a.624.624 0 10-.883-.882l-4.264 4.264a.624.624 0 10.883.882l4.264-4.263z" />
        <path d="M15.42 16.354a.624.624 0 00.882-.882l-4.264-4.264a.624.624 0 10-.882.882l4.264 4.264zM12.277 7.045a.411.411 0 01-.349.386.394.394 0 01-.469-.323l-.028-.119-.027-.174-.065-.35a3.557 3.557 0 00-2.488-2.913 1.625 1.625 0 00-.295-.083.417.417 0 01-.266-.193.34.34 0 01-.017-.311.915.915 0 00.063-.322.873.873 0 00-.423-.726 1.013 1.013 0 00-.892-.064.841.841 0 00-.468 1.102.35.35 0 01-.028.3.375.375 0 01-.257.193l-.293.083c-1.489.44-2.362 1.645-2.61 3.6l-.919 4.7a1.671 1.671 0 01-.746 1.076l-.919.606.13 1.617h5.753a.378.378 0 01.28.65.377.377 0 01-.28.103h-.681a1.537 1.537 0 001.2.882l.313-.046a.434.434 0 01.278.076.32.32 0 01.141.2.415.415 0 01-.028.282.423.423 0 01-.125.142.5.5 0 01-.187.07 1.427 1.427 0 01-.2.035h-.124a2.293 2.293 0 01-2.086-1.645H.9a.733.733 0 01-.753-.652L0 13.549a.7.7 0 01.321-.633l.949-.616a.979.979 0 00.4-.606l.919-4.677c.331-2.62 1.664-3.758 3.116-4.2A1.569 1.569 0 016.5 1.255a1.833 1.833 0 011.865 0 1.558 1.558 0 01.791 1.562 4.086 4.086 0 011.911 1.214 4.938 4.938 0 011.047 2.1l.037.156.1.551.017.128c.008.027.01.056.009.084" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill={color} d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

CancelledNotifyIcon.propTypes = {
  width: PropTypes.number,
  color: PropTypes.string,
};

export default CancelledNotifyIcon;
