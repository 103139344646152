import React from 'react';
import type { SVGProps } from 'react';
import { COLORS } from 'constants/colors';

const EditIcon = ({
  width = 15,
  height = 15,
  color = COLORS.BLACK,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 15 15"
    fill="none"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <path
      fill={color}
      d="M8.203 9.395a.6.6 0 0 1-.136.063l-2.724.86a.29.29 0 0 1-.296-.072l-.294-.294a.3.3 0 0 1-.072-.296l.86-2.722a.6.6 0 0 1 .144-.238L11.622.76a1.175 1.175 0 0 1 1.66 0l.958.956a1.174 1.174 0 0 1 0 1.662L8.305 9.314a.6.6 0 0 1-.102.081m.058-.867 3.923-3.924-1.787-1.788L6.473 6.74a2.5 2.5 0 0 1 1.788 1.788m-2.594-.04c.346.21.635.5.845.845l1.23-.389a1.91 1.91 0 0 0-1.687-1.686zm-.37 1.174.039.04.592-.187a1.9 1.9 0 0 0-.447-.447zm8.527-6.7a.586.586 0 0 0 0-.83l-.956-.956a.587.587 0 0 0-.83 0l-.345.344 1.787 1.788zm-2.548-1.027-.466.465 1.791 1.79.466-.465zm-1.382 2.02a.294.294 0 1 1 .415.415L8.253 6.426a.294.294 0 0 1-.415-.415zm.734.734a.294.294 0 0 1 .415.415L8.987 7.16a.294.294 0 1 1-.415-.415z"
    />
    <path
      fill={color}
      d="M13.574 6.342V12.3q.001.385 0 .77 0 .072-.01.145l.019-.135a1 1 0 0 1-.066.24l.05-.12a1 1 0 0 1-.112.203c-.027.047.076-.09.039-.049q-.034.037-.068.074-.031.03-.065.06c-.052.046.05-.041.05-.04a1.104 1.104 0 0 1-.213.12l.12-.05q-.113.044-.24.065l.135-.018c-.136.016-.277.01-.413.01H1.941q-.076 0-.15-.01l.135.018a1 1 0 0 1-.24-.066l.12.05a1 1 0 0 1-.166-.087l-.037-.025c-.047-.027.089.076.049.039q-.037-.034-.074-.067-.03-.031-.06-.066c-.046-.052.041.05.04.05a1.105 1.105 0 0 1-.12-.214l.05.122a1.2 1.2 0 0 1-.065-.24l.019.134c-.017-.136-.01-.277-.01-.414V3.261q-.001-.664 0-1.326 0-.075.01-.15l-.019.135a1 1 0 0 1 .066-.24l-.05.12q.036-.088.087-.166l.025-.037c.027-.047-.076.09-.039.049q.034-.037.067-.074.032-.03.066-.059c.052-.047-.05.04-.05.039a1.107 1.107 0 0 1 .213-.12l-.121.05q.115-.044.24-.065l-.134.019c.247-.032.51-.01.758-.01h5.266c.277 0 .554.005.83 0h.012c.264 0 .516-.232.504-.505A.513.513 0 0 0 8.66.417H1.945C1.255.418.59.909.46 1.606c-.065.348-.035.721-.035 1.073v9.539c0 .286-.003.57 0 .857.003.408.171.819.474 1.096.284.26.647.409 1.034.412H12.91c.186 0 .366-.002.55-.05.665-.173 1.12-.8 1.122-1.479q.001-.761 0-1.522V6.34c0-.264-.232-.516-.504-.504a.515.515 0 0 0-.505.506"
    />
  </svg>
);
export default EditIcon;
