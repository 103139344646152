import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'constants/colors';

interface CreditCardProps {
  width?: number | string;
  height?: number | string;
  color?: string;
}

function CreditCard({ width = 16, height = 12, color = COLORS.WHITE }: CreditCardProps) {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Cancellation icon</title>
      <path
        d="M12.857.15H2.566A2.363 2.363 0 00.15 2.453v6.393a2.363 2.363 0 002.416 2.3h10.29a2.363 2.363 0 002.416-2.3V2.453A2.363 2.363 0 0012.857.15zM2.567.871h10.29a1.7 1.7 0 011.174.464 1.543 1.543 0 01.484 1.118v.054H.904v-.054a1.541 1.541 0 01.486-1.119c.318-.3.74-.466 1.177-.463zm10.29 9.558H2.566a1.7 1.7 0 01-1.174-.469 1.541 1.541 0 01-.484-1.118V4.691h13.611v4.156a1.54 1.54 0 01-.486 1.119 1.7 1.7 0 01-1.176.463z"
        fill={color}
        stroke={color}
        strokeWidth={0.3}
      />
    </svg>
  );
}

CreditCard.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string,
};

export default CreditCard;
