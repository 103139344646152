import React from 'react';
import type { IncomingCall } from 'modules/IncomingCall/types';
import GuestName from 'components/shared/GuestName';
import styles from './CallPopup.module.scss';

const Name = ({
  guest,
  upcomingReservation,
}: {
  guest: IncomingCall['guest'];
  upcomingReservation: IncomingCall['upcomingReservation'];
}) => {
  const guestName = guest?.name ? guest.name.trim() : '';

  if (upcomingReservation) {
    const reservationName =
      `${upcomingReservation.firstName ?? ''} ${upcomingReservation.lastName ?? ''}`.trim();

    // Render guest name in the following order:
    // 1. If there is a upcoming reservation with a name, show it on top
    // 2. If there's a guest attached to it, and guest name is not same as reservation name, then show it
    // 3. Otherwise, if no guest, then its a temporary guest reservation, then show 'Temporary guest'
    return (
      <span>
        {reservationName.length ? (
          <GuestName className={styles.heading} name={reservationName} />
        ) : null}
        {guest && guestName !== reservationName ? (
          <GuestName
            name={guestName}
            isBlacklisted={guest.blacklisted}
            hasLoyalty={guest.hasLoyalty}
          />
        ) : null}
        {!guest ? <div>Temporary guest</div> : null}
      </span>
    );
  }

  if (guest && guestName.length) {
    return (
      <GuestName
        className={styles.heading}
        name={guestName}
        isBlacklisted={guest.blacklisted}
        hasLoyalty={guest.hasLoyalty}
      />
    );
  }

  return null;
};

export default Name;
