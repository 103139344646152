import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'constants/colors';

interface BlacklistIconProps {
  className?: string;
  size?: number;
  color?: string;
}

function BlacklistIcon({ className, size = 22, color = COLORS.BLACK }: BlacklistIconProps) {
  return (
    <svg
      width={size}
      height={size}
      fill={color}
      viewBox="0 0 22 22"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Blacklisted</title>
      <g clipPath="url(#prefix__clip0_3332_39348)" fill={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.036 10.83H8.91a3.6 3.6 0 01-2.812-1.27c-1.532-1.8-1.288-4.856-1.254-5.198.1-2.235 1.121-3.296 1.965-3.792A4.299 4.299 0 018.953 0h.068a4.306 4.306 0 012.15.583c.828.493 1.833 1.549 1.938 3.775.032.343.27 3.406-1.255 5.198a3.617 3.617 0 01-2.818 1.274zM8.95 1.128c-.869 0-2.905.322-3.036 3.305v.047c-.018.226-.227 2.91.992 4.336a2.558 2.558 0 002.052.89h.041a2.576 2.576 0 002.058-.89c1.259-1.485.985-4.322.981-4.35a.073.073 0 01-.004-.034c-.123-2.816-1.955-3.279-3.036-3.304H8.95z"
        />
        <path d="M9.74 18.887c-.255.008-.51.01-.765.005a14.897 14.897 0 01-7.153-1.685 7.112 7.112 0 01-.083-1.266v-.103c.028-.933.134-1.954 1.083-2.294a12.685 12.685 0 003.701-1.709.556.556 0 00-.22-.997.543.543 0 00-.41.093 11.528 11.528 0 01-3.366 1.549l-.041.012C.732 13.101.674 14.985.649 15.8v.007c0 .035-.001.07-.005.105v.012a6.331 6.331 0 00.208 1.853c.04.107.115.197.211.258a15.23 15.23 0 007.918 1.959c.505.014 1.01.003 1.512-.033a6.017 6.017 0 01-.754-1.074zM12.603 17.676a.48.48 0 10.68.68l1.696-1.698 1.697 1.697a.48.48 0 10.68-.679l-1.698-1.697 1.697-1.697a.48.48 0 10-.679-.679L14.98 15.3l-1.697-1.697a.48.48 0 10-.679.68l1.697 1.696-1.697 1.697z" />
        <path d="M20.326 13.237a.344.344 0 00-.484-.13.38.38 0 00-.135.5 5.28 5.28 0 11-2.059-2.175.38.38 0 00.506-.107.344.344 0 00-.103-.491 6 6 0 102.275 2.403z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_3332_39348">
          <path fill={color} d="M0 0h22v22H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

BlacklistIcon.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
  color: PropTypes.string,
};

export default BlacklistIcon;
