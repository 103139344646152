import { API_ENDPOINT_PARTNER_V2 } from 'constants/app';
import HttpRequest from 'utils/http-request';
import type {
  GuestSearchGroupList,
  GuestReservationSummary,
  GuestLoyaltyAccountDetails,
  MasterGuest,
} from './types';
import type { VenueId } from 'modules/Venue/types';

class Guest extends HttpRequest {
  constructor() {
    super(`${API_ENDPOINT_PARTNER_V2}/master_guests`);
  }

  // This endpoint will use a fuzzy search algorithm by default.
  // The `exactPhone` parameter can be used to search for a guest with an exact phone number match.
  searchGuest({ searchText, shortResponse, venueId, exactPhone = false }: any) {
    return this.fetch<GuestSearchGroupList>('/search', {
      venueId,
      searchText,
      shortResponse,
      ...(exactPhone && { exactPhone }),
    });
  }

  createGuest(data: any) {
    return this.create<{ masterGuest: MasterGuest }>(
      `${API_ENDPOINT_PARTNER_V2}/permanent_guests`,
      data
    );
  }

  updateMasterGuest(masterGuestId: number, data: any) {
    return this.update<{ masterGuest: MasterGuest }>(`/${masterGuestId}/update_guest`, data);
  }

  updateMasterGuestNote(masterGuestId: number, data: any) {
    return this.update<{ masterGuest: MasterGuest }>(`/${masterGuestId}/update_guest_note`, data);
  }

  updateMasterGuestTags(masterGuestId: number, data: any) {
    return this.update<{ masterGuest: MasterGuest }>(`/${masterGuestId}`, data);
  }

  updateMasterGuestProfile(masterGuestId: number, data: any) {
    return this.update<{ masterGuest: MasterGuest }>(
      `/${masterGuestId}/update_guest_profile`,
      data
    );
  }

  fetchMasterGuest(masterGuestId: number, venueId: VenueId, config = {}) {
    return this.fetch<{ masterGuest: MasterGuest }>(`/${masterGuestId}`, { venueId }, config);
  }

  fetchMasterGuestReservationSummary({ masterGuestId, venueId, scope }: any, config = {}) {
    return this.fetch<GuestReservationSummary>(
      `/${masterGuestId}/reservation_summary_2`,
      { venueId, ...(scope && { scope }) },
      config
    );
  }

  fetchMasterGuestReservations(masterGuestId: number, venueId: VenueId, type: any, config = {}) {
    return this.fetch(`/${masterGuestId}/items`, { type, venueId }, config);
  }

  fetchMasterGuestLoyaltyTransactions(masterGuestId: number, venueId: VenueId, config = {}) {
    return this.fetch<{ loyaltyAccountDetails: GuestLoyaltyAccountDetails }>(
      `/${masterGuestId}/loyalty_transactions`,
      { venueId },
      config
    );
  }

  setMasterGuestLoyaltySubscription(masterGuestId: number, venueId: VenueId) {
    return this.create<{ masterGuest: MasterGuest }>(`/${masterGuestId}/subscribe_to_loyalty`, {
      venueId,
    });
  }

  setMasterGuestLoyaltyTier(masterGuestId: number, loyaltyTier: any, venueId: VenueId) {
    return this.create(`/${masterGuestId}/loyalty_tier`, { venueId, loyaltyTier });
  }

  fetchMasterGuestLoyalty(masterGuestId: number, venueId: VenueId, loyaltyId: number) {
    return this.fetch(`/${masterGuestId}/show_loyalty`, { loyaltyId, venueId });
  }
}

export default new Guest();
