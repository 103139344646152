import { useEffect } from 'react';
import { batch } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useViewPortContext } from '@umai/common';
import { MESSAGE_EVENTS } from 'constants/socket';
import { guestSelectionAction } from 'modules/ReservationFunnel/actions';
import { selectIsQueueSubscribed } from 'modules/Partner/selectors';
import { selectGuests, selectMasterGuestId } from 'modules/Guest/selectors';
import { selectGuestId } from 'modules/ReservationFunnel/selectors';
import { updateReservationGuest } from 'modules/ReservationsList/actions';
import { updateQueueGuest } from 'modules/QueueList/actions';
import guestApis from 'modules/Guest/apis';

const useSocketReservationMasterGuest = ({ venueId }: any) => {
  const dispatch = useAppDispatch();
  const { mobile } = useViewPortContext();
  const masterGuestId = useAppSelector(selectMasterGuestId);
  const funnelGuestId = useAppSelector(selectGuestId);
  const isQueueSubscribed = useAppSelector(selectIsQueueSubscribed);
  const guests = useAppSelector(selectGuests);

  const masterGuestUpdate = async ({ detail = {} }) => {
    try {
      // @ts-expect-error TS(2339): Property 'params' does not exist on type '{}'.
      const masterGuestData = detail?.params || {};
      let masterGuest: any = null;

      const getMasterGuest = async () => {
        const guestId = masterGuestData?.new_master_guest_id
          ? masterGuestData.new_master_guest_id
          : masterGuestData?.master_guest_id;
        if (!masterGuest) {
          const response = await guestApis.fetchMasterGuest(guestId, venueId);
          masterGuest = response.masterGuest;
        }
        return masterGuest;
      };
      // eslint-disable-next-line
      console.debug('FUNNEL GUEST: socket update: ', masterGuestData, funnelGuestId);
      // if the guest from the socket is selected on funnel then update guest.
      if (funnelGuestId && masterGuestData?.master_guest_id === funnelGuestId) {
        dispatch(guestSelectionAction(await getMasterGuest(), mobile));
      }
      // eslint-disable-next-line
      console.debug('RESERVATION PRIMARY GUEST: socket update: ', masterGuestData);
      if (masterGuestData?.reservation_ids?.length && masterGuestData?.master_guest_id) {
        const guest = await getMasterGuest();
        batch(() => {
          masterGuestData.reservation_ids.forEach((reservationId: any) => {
            if (reservationId)
              dispatch(updateReservationGuest({ id: +reservationId, primaryGuest: guest }));
          });
        });
      }
      if (isQueueSubscribed) {
        // Updating the queueItem when master guest data is updated
        // eslint-disable-next-line
        console.debug('QUEUE PRIMARY GUEST: socket update: ', masterGuestData);
        if (masterGuestData?.offline_waitlist_ids?.length && masterGuestData?.master_guest_id) {
          const guest = await getMasterGuest();
          batch(() => {
            masterGuestData.offline_waitlist_ids.forEach((queueId: any) => {
              if (queueId) dispatch(updateQueueGuest({ id: +queueId, primaryGuest: guest }));
            });
          });
        }
      }
    } catch (error) {
      console.error('Error in update master guest from socket', error);
    }
  };

  useEffect(() => {
    // @ts-expect-error TS(2769): No overload matches this call.
    document.addEventListener(MESSAGE_EVENTS.masterGuest, masterGuestUpdate, false);
    return () => {
      // @ts-expect-error TS(2769): No overload matches this call.
      document.removeEventListener(MESSAGE_EVENTS.masterGuest, masterGuestUpdate, false);
    };
  }, [venueId, masterGuestId, funnelGuestId, guests, mobile]);
};

export default useSocketReservationMasterGuest;
