import React, { type SVGProps } from 'react';
import { COLORS } from 'constants/colors';

interface Props extends SVGProps<SVGSVGElement> {
  width?: number | string;
  height?: number | string;
  color?: string;
  title?: string;
  className?: string;
}

function ForwardArrowIcon({
  width = 10,
  height = 17,
  color = COLORS.GREEN_PRIMARY,
  title = 'Forward',
  className,
  ...props
}: Props) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 10 17"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      {title && <title>{title}</title>}
      <path
        d="M.1 15.586 1.515 17 10 8.515 1.515.029.1 1.444l7.072 7.07L.1 15.587v-.001Z"
        fill={color}
      />
    </svg>
  );
}

export default ForwardArrowIcon;
