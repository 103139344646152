import React from 'react';
import type { SVGProps } from 'react';
import { COLORS } from 'constants/colors';

const ReservedIcon = ({
  width = 18,
  height = 20,
  color = COLORS.WHITE,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 18 20"
    fill="none"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <path
      fill={color}
      d="M15.452 20H4.098a2.4 2.4 0 0 1-1.659-.663c-.44-.425-.687-1-.687-1.6v-.498H.72a.48.48 0 0 1-.332-.133.445.445 0 0 1 0-.641.48.48 0 0 1 .332-.133h1.032v-3.618H.72a.48.48 0 0 1-.332-.133.445.445 0 0 1 0-.64.48.48 0 0 1 .332-.132h1.032V8.19H.72a.48.48 0 0 1-.332-.132.444.444 0 0 1 0-.64.48.48 0 0 1 .332-.132h1.032v-3.62H.72a.48.48 0 0 1-.332-.133.45.45 0 0 1-.137-.32.44.44 0 0 1 .137-.32.47.47 0 0 1 .332-.132h1.032v-.497c0-.6.247-1.175.687-1.6A2.4 2.4 0 0 1 4.098 0h11.353c.54.001 1.064.182 1.483.511.418.33.706.788.816 1.298v16.38c-.11.51-.398.97-.816 1.299-.419.33-.942.51-1.483.512m-9.384-4.977c.124 0 .244.048.332.133.087.084.137.2.137.32v3.62h8.914c.373-.001.731-.144.995-.398.264-.255.412-.6.412-.96V2.263c0-.36-.148-.705-.412-.96a1.44 1.44 0 0 0-.995-.398H6.538V13.93c0 .12-.05.235-.137.32a.48.48 0 0 1-.664 0 .44.44 0 0 1-.137-.32V.905H4.098c-.373 0-.731.143-.995.398s-.413.6-.413.96v.499h1.032c.124 0 .243.047.331.132a.444.444 0 0 1 0 .64.48.48 0 0 1-.331.132H2.69v3.619h1.032c.124 0 .243.047.331.132a.444.444 0 0 1 0 .64.48.48 0 0 1-.331.132H2.69v3.62h1.032a.48.48 0 0 1 .331.133.45.45 0 0 1 .138.32.44.44 0 0 1-.138.32.47.47 0 0 1-.331.132H2.69v3.62h1.032c.124 0 .244.048.332.133a.445.445 0 0 1 0 .64.48.48 0 0 1-.332.133H2.69v.498c0 .36.149.706.413.96.264.255.622.397.995.397h1.501v-3.62c0-.12.05-.235.137-.32a.48.48 0 0 1 .332-.132"
    />
  </svg>
);

export default ReservedIcon;
