import React from 'react';
import classnames from 'classnames';
import { PRE_SEATED_STATUSES, STATUSES } from 'modules/ReservationsList/constants';
import { combineArrays, combineTagArrays } from 'utils/array-helpers';
import { getGuestNotes } from 'modules/ReservationsList/services';
import { getGuestName } from 'modules/IncomingCall/services';
import type { IncomingCall } from 'modules/IncomingCall/types';
import CopyText from 'components/shared/CopyText';
import WhatsAppButton from 'components/ui/WhatsAppButton';
import TransactionsSummary from 'components/shared/GuestTransactionsSummary/TransactionsSummary';
import ReservationPayment from 'components/shared/ReservationPayment';
import SupplementsPanel from 'components/panels/SupplementsPanel';
import Tags from 'components/shared/Tags';
import Notes from './Notes';
import ReservationDetails from './ReservationDetails';
import Name from './Name';
import Status from './Status';
import styles from './CallPopup.module.scss';

type Props = {
  data: IncomingCall;
};

function Content({ data }: Props) {
  const guestName = getGuestName({
    name: data.guest?.name,
    firstName: data.upcomingReservation?.firstName,
    lastName: data.upcomingReservation?.lastName,
  });
  // combine guest tags so that it only shows the unique tags
  const combinedGuestTags = combineTagArrays({
    autoTags: data.guest?.automatedTags,
    tags: data.guest?.tags,
  });
  // combine reservation tags so that it only shows the unique tags
  const combinedRsvTags = combineTagArrays({
    autoTags: data.upcomingReservation?.automatedTags,
    tags: combineArrays(data.upcomingReservation?.tags, data.upcomingReservation?.reservationTags),
  });

  return (
    <section className={styles.content} aria-label="incoming call details">
      <div className={styles.name}>
        <Name guest={data.guest} upcomingReservation={data.upcomingReservation} />
        <span>
          <a
            href={`tel:${data.callPhoneNumber}`}
            // NOTE: if name not exists on guest or upcoming reservation then it becomes a heading
            className={classnames(styles.phone, { [styles.heading]: !guestName.length })}
          >
            <CopyText text={data.callPhoneNumber}>
              {!guestName.length ? data.callPhoneNumber : <>( {data.callPhoneNumber} )</>}
            </CopyText>
          </a>
          <WhatsAppButton phone={data.callPhoneNumber} size={16} />
        </span>
      </div>
      {data.guest ? (
        <div className={styles.guest} aria-label="guest details">
          {combinedGuestTags.length ? <Tags tags={combinedGuestTags} className="guest" /> : null}
          <Notes note={getGuestNotes(data.guest.guestNote)} />
          <TransactionsSummary
            className={styles.summary}
            customReservationSummary={{
              venue: {
                totalCancelledReservations: data.guest.totalCancelledReservations || 0,
                totalNoShowedReservations: data.guest.totalNoShowedReservations || 0,
                totalVisit: data.guest.totalVisit || 0,
                avgVisitReservations: `${data.guest.avgVisitReservations || 0}`,
                avgCoverReservations: `${data.guest.avgCoverReservations || 0}`,
                netSpend: `${data.guest.netSpend || 0}`,
              },
            }}
            isTooltipEnabled={false}
            type="venue"
            header={null}
            order={{
              visits: 1,
              cancellations: 2,
              noShows: 3,
              totalSpend: 4,
              avgPerVisit: 5,
              avgPerCover: 6,
            }}
          />
        </div>
      ) : null}
      {data.upcomingReservation ? (
        <div className={styles.upcomingReservation} aria-label="upcoming reservation details">
          <h6 className={styles.heading}>
            Upcoming reservation
            {PRE_SEATED_STATUSES.includes(data.upcomingReservation.status.toUpperCase()) ? (
              <Status status={data.upcomingReservation.status} />
            ) : null}
          </h6>
          {combinedRsvTags.length ? <Tags tags={combinedRsvTags} className="reservation" /> : null}
          <ReservationDetails
            slotStartTime={data.upcomingReservation.slotStartTime}
            turnTime={data.upcomingReservation.turnTime}
            guestCount={data.upcomingReservation.guestCount}
            tables={data.upcomingReservation.tables}
          />
          <ReservationPayment
            className={styles.payment}
            hasDepositPayableFee={data.upcomingReservation.hasDepositPayableFee}
            payableFee={data.upcomingReservation.depositPayableFee}
            status={data.upcomingReservation.status}
            hasSupplements={!!data.upcomingReservation.supplements?.length}
          />
          {data.upcomingReservation.supplements?.length ? (
            <section className={styles.supplements}>
              <SupplementsPanel
                supplements={data.upcomingReservation.supplements}
                paymentInfo={data.upcomingReservation.paymentInfo}
                depositPayableFee={data.upcomingReservation.depositPayableFee}
                showFeeDetails={false}
              />
            </section>
          ) : null}
          <Notes note={data.upcomingReservation.notes} />
        </div>
      ) : null}
      {data.lastReservation ? (
        <div className={styles.lastReservation} aria-label="last reservation details">
          <h6 className={styles.heading}>
            Last visit
            {data.lastReservation.status.toUpperCase() === STATUSES['NO-SHOW'] ? (
              <Status status={data.lastReservation.status} />
            ) : null}
          </h6>
          <ReservationDetails
            slotStartTime={data.lastReservation.slotStartTime}
            turnTime={data.lastReservation.turnTime}
            guestCount={data.lastReservation.guestCount}
            tables={data.lastReservation.tables}
          />
          <ReservationPayment
            className={styles.payment}
            hasDepositPayableFee={data.lastReservation.hasDepositPayableFee}
            payableFee={data.lastReservation.depositPayableFee}
            status={data.lastReservation.status}
            hasSupplements={!!data.lastReservation.supplements?.length}
          />
          {data.lastReservation.supplements?.length ? (
            <section className={styles.supplements}>
              <SupplementsPanel
                supplements={data.lastReservation.supplements}
                paymentInfo={data.lastReservation.paymentInfo}
                depositPayableFee={data.lastReservation.depositPayableFee}
                showFeeDetails={false}
              />
            </section>
          ) : null}
        </div>
      ) : null}
    </section>
  );
}

export default Content;
