import React, { memo } from 'react';
import { useViewPortContext } from '@umai/common';
import { CHARGE_TYPES, type ChargeType, PAYMENT_STATUS } from 'constants/charge';
import { COLORS, STATUSES_COLOR } from 'constants/colors';
import { isReservationChargedOrRefunded } from 'modules/ReservationsList/services';
import type { DepositPayableFee } from 'modules/ReservationsList/types';
import CreditCardIcon from 'components/icons/CreditCardIcon';
import DepositIcon from 'components/icons/DepositIcon';
import SupplementIcon from 'components/icons/SupplementIcon';
import FailedPaymentIcon from 'components/icons/FailedPaymentIcon';

interface Props {
  type: ChargeType;
  isAttentionReservation?: boolean;
  payableFee?: DepositPayableFee | null;
  text?: string | boolean;
  isPaymentFailed?: boolean;
}

const ReservationPaymentIcon = ({
  type,
  payableFee,
  text = false,
  isAttentionReservation = false,
  isPaymentFailed = false,
}: Props) => {
  const { mobile } = useViewPortContext();
  const { payableType = CHARGE_TYPES.supplement, paymentStatus = '' } = payableFee || {};
  const reservationChargedOrRefunded = isReservationChargedOrRefunded(payableType, paymentStatus);
  const reservationPaymentPending = paymentStatus === PAYMENT_STATUS.pending;

  if (paymentStatus === PAYMENT_STATUS.failed || isPaymentFailed) {
    if (mobile && text) return null;
    if (isAttentionReservation) return <FailedPaymentIcon color={COLORS.WHITE} />;
    return <FailedPaymentIcon color={COLORS.DANGER} />;
  }
  switch (type) {
    case CHARGE_TYPES.deposit: {
      if (isAttentionReservation) return <DepositIcon color={COLORS.WHITE} />;
      if (reservationChargedOrRefunded) return <DepositIcon color={COLORS.ATTENTION} />;
      if (reservationPaymentPending) return <DepositIcon color={STATUSES_COLOR.CLEARED} />;
      return <DepositIcon color={STATUSES_COLOR.PAID} />;
    }
    case CHARGE_TYPES.supplement: {
      if (isAttentionReservation) return <SupplementIcon color={COLORS.WHITE} />;
      if (reservationChargedOrRefunded) return <SupplementIcon color={COLORS.ATTENTION} />;
      if (reservationPaymentPending) return <SupplementIcon color={STATUSES_COLOR.CLEARED} />;
      return <SupplementIcon color={STATUSES_COLOR.PAID} />;
    }
    case CHARGE_TYPES.cancellation: {
      if (isAttentionReservation) return <CreditCardIcon color={COLORS.WHITE} />;
      if (reservationChargedOrRefunded) return <CreditCardIcon color={COLORS.ATTENTION} />;
      if (reservationPaymentPending) return <CreditCardIcon color={STATUSES_COLOR.CLEARED} />;
      return <CreditCardIcon color={STATUSES_COLOR.PAID} />;
    }
    default:
      return null;
  }
};

export default memo(ReservationPaymentIcon);
