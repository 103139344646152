import React from 'react';
import type { SVGProps } from 'react';
import { COLORS } from 'constants/colors';

const TableIcon = ({
  width = 16,
  height = 16,
  color = COLORS.BLACK,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 15 15"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M3.648 2.244c-.775 0-1.404.629-1.404 1.404v7.704c0 .775.629 1.404 1.404 1.404h7.704c.775 0 1.404-.629 1.404-1.404V3.648c0-.775-.629-1.404-1.404-1.404zM1.531 3.648c0-1.169.948-2.117 2.117-2.117h7.704c1.169 0 2.117.948 2.117 2.117v7.704c0 1.169-.948 2.117-2.117 2.117H3.648a2.117 2.117 0 0 1-2.117-2.117zM11.902.729c0 .172-.14.311-.312.311H3.41a.312.312 0 1 1 0-.624h8.18c.172 0 .312.14.312.313M11.902 14.272c0 .172-.14.311-.312.311H3.41a.312.312 0 1 1 0-.624h8.18c.172 0 .312.14.312.313M.728 11.902a.31.31 0 0 1-.311-.312V3.41a.312.312 0 1 1 .624 0v8.18c0 .172-.14.312-.313.312M14.271 11.902a.31.31 0 0 1-.311-.312V3.41a.312.312 0 1 1 .624 0v8.18c0 .172-.14.312-.313.312"
      clipRule="evenodd"
    />
  </svg>
);

export default TableIcon;
