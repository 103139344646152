import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'constants/colors';

interface LoyaltyIconProps {
  width?: number | string;
  height?: number | string;
  color?: string;
}

function LoyaltyIcon({ color = COLORS.BLACK, width = 16, height = 18 }: LoyaltyIconProps) {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Loyalty</title>
      <path
        d="M6.283 6.323a1.63 1.63 0 00-1.139.461 1.544 1.544 0 000 2.224l2.571 2.5a.13.13 0 00.184 0l2.567-2.506a1.546 1.546 0 000-2.224 1.64 1.64 0 00-2.277 0l-.388.375-.38-.371a1.63 1.63 0 00-1.139-.461l.001.002zm0 .261c.357 0 .7.139.955.388l.473.461a.131.131 0 00.184 0l.477-.465a1.373 1.373 0 012.204.421 1.274 1.274 0 01-.294 1.428l-2.477 2.415L5.328 8.82a1.274 1.274 0 010-1.849c.255-.25.598-.388.955-.388v.001z"
        fill={color}
        stroke={color}
        strokeWidth={0.8}
      />
      <path
        d="M13.057 1.886H4.128L5.159.855a.5.5 0 00-.7-.7l-1.89 1.882-.075.075a.088.088 0 00-.025.05c0 .025-.025.025-.025.05s0 .025-.025.05c0 .025 0 .025-.025.05a.278.278 0 000 .2c0 .025 0 .025.025.05 0 .025 0 .025.025.05 0 .025.025.025.025.05s.025.025.025.05l.075.075 1.886 1.886a.486.486 0 00.7 0 .486.486 0 000-.7L4.124 2.891h8.9a1.964 1.964 0 011.962 1.962V7.42a.503.503 0 001.006 0V4.854a2.968 2.968 0 00-2.935-2.968zM2.968 15.863h8.9l-1.031 1.031a.486.486 0 000 .7.486.486 0 00.7 0l1.886-1.886.075-.075.025-.025c0-.025.025-.025.025-.05s0-.025.025-.05c0-.025 0-.025.025-.05a.278.278 0 000-.2c0-.025 0-.025-.025-.05 0-.025 0-.025-.025-.05 0-.025-.025-.025-.025-.05a.025.025 0 00-.025-.025l-.075-.075-1.886-1.886a.5.5 0 10-.7.7l1.031 1.031h-8.9a1.964 1.964 0 01-1.962-1.962v-2.564a.503.503 0 00-1.006 0v2.591a2.968 2.968 0 002.968 2.945z"
        fill={color}
      />
    </svg>
  );
}

LoyaltyIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string,
};

export default LoyaltyIcon;
