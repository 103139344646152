import React from 'react';
import { COLORS } from 'constants/colors';

interface PersonIconProps {
  width?: number | string;
  height?: number | string;
  color?: string;
  title?: string;
}

function PersonIcon({
  width = 14,
  height = 14,
  color = COLORS.BLACK,
  title = 'Guest(s)',
}: PersonIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 20"
      fill="none"
    >
      <title>{title}</title>
      <path
        fill={color}
        d="M9.036 10.83H8.91a3.6 3.6 0 0 1-2.813-1.27c-1.532-1.8-1.288-4.856-1.254-5.198.1-2.235 1.122-3.296 1.966-3.792A4.3 4.3 0 0 1 8.953 0h.068a4.3 4.3 0 0 1 2.15.583c.828.493 1.832 1.549 1.937 3.775.033.343.27 3.406-1.255 5.198a3.62 3.62 0 0 1-2.817 1.274M8.95 1.128c-.87 0-2.905.322-3.036 3.305v.033c0 .027-.276 2.868.991 4.35a2.56 2.56 0 0 0 2.053.89h.04a2.58 2.58 0 0 0 2.058-.89c1.26-1.485.986-4.322.982-4.35a.1.1 0 0 1-.004-.034c-.124-2.816-1.956-3.279-3.036-3.304z"
      />
      <path
        fill={color}
        d="M17.305 15.925v-.116c-.029-.814-.083-2.706-1.843-3.313l-.04-.012a11.7 11.7 0 0 1-3.365-1.55.543.543 0 0 0-.762.136.555.555 0 0 0 .134.77 12.6 12.6 0 0 0 3.704 1.71c.944.339 1.05 1.36 1.078 2.293a1 1 0 0 0 0 .103q.012.636-.083 1.266a14.8 14.8 0 0 1-7.154 1.68 14.9 14.9 0 0 1-7.152-1.685 7 7 0 0 1-.084-1.266v-.103c.029-.933.135-1.954 1.083-2.294a12.7 12.7 0 0 0 3.702-1.709.557.557 0 0 0-.22-.997.55.55 0 0 0-.41.093 11.5 11.5 0 0 1-3.367 1.548l-.04.013c-1.759.61-1.812 2.503-1.837 3.315q0 .052-.005.105v.012a6.3 6.3 0 0 0 .208 1.853c.04.107.114.197.21.258a15.23 15.23 0 0 0 7.918 1.959c2.767.076 5.503-.6 7.915-1.959a.55.55 0 0 0 .21-.258c.153-.605.22-1.228.2-1.852"
      />
    </svg>
  );
}

export default PersonIcon;
