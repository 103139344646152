import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'constants/colors';

interface QueueIconProps {
  width?: number;
  color?: string;
}

function QueueIcon({ width = 23, color = COLORS.WHITE }: QueueIconProps) {
  return (
    <svg width={width} viewBox="0 0 23 23.25" fill="none">
      <g transform="translate(0.25)">
        <path
          d="M49.585,42.126V37.292a.292.292,0,0,0-.585,0v5.1l3.9,3.3a.292.292,0,1,0,.378-.446Z"
          transform="translate(-40.815 -27.738)"
          fill={color}
          stroke={color}
          strokeWidth={0.5}
        />
        <path
          d="M36.711,22.7l1.034,1.033a.292.292,0,0,0,.413-.413l-2.48-2.48a.292.292,0,0,0-.413.413L36.3,22.289l-1.034,1.033s0,.006-.006.009a8.443,8.443,0,0,0-5.491-2.278s0,0,0-.007V19.585h1.462a.292.292,0,1,0,0-.585H27.723a.292.292,0,1,0,0,.585h1.462v1.462s0,0,0,.007a8.487,8.487,0,1,0,6.483,2.688l.009-.006ZM29.477,37.415a7.892,7.892,0,1,1,7.892-7.892A7.9,7.9,0,0,1,29.477,37.415Z"
          transform="translate(-21 -15)"
          fill={color}
          stroke={color}
          strokeWidth={0.5}
        />
        <rect width="1.5" height="6" rx="0.75" transform="translate(19)" fill={color} />
        <rect
          width="1.5"
          height="6"
          rx="0.75"
          transform="translate(22.75 2.25) rotate(90)"
          fill={color}
        />
      </g>
    </svg>
  );
}

QueueIcon.propTypes = {
  width: PropTypes.number,
  color: PropTypes.string,
};

export default QueueIcon;
