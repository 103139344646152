import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  STATUSES,
  WAITLIST_STATUSES,
  QUEUE_LIST_STATUSES,
  BLOCKED_STATUS,
} from 'modules/ReservationsList/constants';
import { getReservationStatusColor } from 'services/status';
import ArrivedIcon from 'components/icons/statuses/ArrivedIcon';
import AwaitingPaymentIcon from 'components/icons/statuses/AwaitingPaymentIcon';
import BillDroppedIcon from 'components/icons/statuses/BillDroppedIcon';
import BlockedIcon from 'components/icons/statuses/BlockedIcon';
import CancelledIcon from 'components/icons/statuses/CancelledIcon';
import CancelledNotifyIcon from 'components/icons/statuses/CancelledNotifyIcon';
import ClearedIcon from 'components/icons/statuses/ClearedIcon';
import ConfirmedIcon from 'components/icons/statuses/ConfirmedIcon';
import ConfirmedSmsIcon from 'components/icons/statuses/ConfirmedSmsIcon';
import DessertIcon from 'components/icons/statuses/DessertIcon';
import ExpiredNotifyIcon from 'components/icons/statuses/ExpiredNotifyIcon';
import FailedIcon from 'components/icons/statuses/FailedIcon';
import FinishedIcon from 'components/icons/statuses/FinishedIcon';
import HeadToVenueIcon from 'components/icons/statuses/HeadToVenueIcon';
import LateIcon from 'components/icons/statuses/LateIcon';
import MessageIcon from 'components/icons/statuses/MessageIcon';
import MainIcon from 'components/icons/statuses/MainIcon';
import NoShowIcon from 'components/icons/statuses/NoShowIcon';
import NotifyIcon from 'components/icons/statuses/NotifyIcon';
import PaidIcon from 'components/icons/statuses/PaidIcon';
import PartiallySeatedIcon from 'components/icons/statuses/PartiallySeatedIcon';
import QueueIcon from 'components/icons/statuses/QueueIcon';
import RemovedQueueIcon from 'components/icons/statuses/RemovedQueueIcon';
import RemoveFromQueueIcon from 'components/icons/statuses/RemoveFromQueueIcon';
import ReservedIcon from 'components/icons/statuses/ReservedIcon';
import SeatIcon from 'components/icons/statuses/SeatIcon';
import StarterIcon from 'components/icons/statuses/StarterIcon';
import TableReadyIcon from 'components/icons/statuses/TableReadyIcon';

interface ReservationStatusIconProps {
  status?: string;
  color?: string;
  width?: number;
}

const ReservationStatusIcon = ({ status = '', color, width = 26 }: ReservationStatusIconProps) => {
  // When no color is provided, use the color that is associated with the status
  const iconColor = color || getReservationStatusColor(status);

  switch (status?.toUpperCase()) {
    case STATUSES.ARRIVED:
      return <ArrivedIcon color={iconColor} width={width} />;
    case STATUSES.AWAITING_PAYMENT:
      return <AwaitingPaymentIcon color={iconColor} width={width} />;
    case STATUSES.BILL_DROPPED:
      return <BillDroppedIcon color={iconColor} width={width} />;
    case STATUSES.CANCELLED:
    case STATUSES.REJECTED:
    case QUEUE_LIST_STATUSES.CANCELLED_QUEUE:
      return <CancelledIcon color={iconColor} width={width} />;
    case STATUSES.CLEARED:
      return <ClearedIcon color={iconColor} width={width} />;
    case STATUSES.CONFIRMED:
      return <ConfirmedIcon color={iconColor} width={width} />;
    case STATUSES.DESSERT:
      return <DessertIcon color={iconColor} width={width} />;
    case STATUSES.FINISH:
      return <FinishedIcon color={iconColor} width={width} />;
    case STATUSES.LATE:
      return <LateIcon color={iconColor} width={width} />;
    case QUEUE_LIST_STATUSES.HEAD_TO_VENUE_SMS_SENT:
      return <HeadToVenueIcon width={width} color={iconColor} />;
    case QUEUE_LIST_STATUSES.TABLE_READY_SMS_SENT:
      return <TableReadyIcon width={width} color={iconColor} />;
    case QUEUE_LIST_STATUSES.QUEUE_TURN_CONFIRMATION_SMS_SENT:
      return <ConfirmedSmsIcon color={iconColor} width={width} />;
    case STATUSES.LEFT_MESSAGE:
    case QUEUE_LIST_STATUSES.CUSTOM_TEMPLATE_SMS_SENT:
      return <MessageIcon color={iconColor} width={width} />;
    case STATUSES.MAIN:
      return <MainIcon color={iconColor} width={width} />;
    case STATUSES['NO-SHOW']:
    case QUEUE_LIST_STATUSES.REJECTED_QUEUE:
      return <NoShowIcon color={iconColor} width={width} />;
    case STATUSES.PAID:
      return <PaidIcon color={iconColor} width={width} />;
    case STATUSES.PARTIALLY_SEATED:
      return <PartiallySeatedIcon color={iconColor} width={width} />;
    case STATUSES.RESERVED:
      return <ReservedIcon color={iconColor} width={width} />;
    case STATUSES.SEATED:
      return <SeatIcon color={iconColor} width={width} />;
    case STATUSES.STARTER:
      return <StarterIcon color={iconColor} width={width} />;
    case STATUSES.FAILED:
      return <FailedIcon color={iconColor} width={width} />;
    case WAITLIST_STATUSES.NOTIFY:
      return <NotifyIcon color={iconColor} width={width} />;
    case WAITLIST_STATUSES.EXPIRED_NOTIFY:
    case STATUSES.EXPIRED:
      return <ExpiredNotifyIcon color={iconColor} width={width} />;
    case WAITLIST_STATUSES.CANCELLED_NOTIFY:
      return <CancelledNotifyIcon color={iconColor} width={width} />;
    case QUEUE_LIST_STATUSES.ACTIVE:
      return <QueueIcon color={iconColor} width={width} />;
    case QUEUE_LIST_STATUSES.REMOVED:
      return <RemovedQueueIcon color={iconColor} width={width} />;
    case QUEUE_LIST_STATUSES.SEATED:
      return <SeatIcon color={iconColor} width={width} />;
    case QUEUE_LIST_STATUSES.REMOVE_FROM_QUEUE:
      return <RemoveFromQueueIcon color={iconColor} width={width} />;
    case BLOCKED_STATUS:
      return <BlockedIcon color={iconColor} width={width} />;
    default:
      return null;
  }
};

ReservationStatusIcon.propTypes = {
  status: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.number,
};

export default memo(ReservationStatusIcon);
