import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'constants/colors';

interface MainIconProps {
  width?: number;
  color?: string;
}

function MainIcon({ width = 20, color = COLORS.WHITE }: MainIconProps) {
  return (
    <svg width={width} fill="none" viewBox="0 0 20 20">
      <path d="M10 19.5a9.5 9.5 0 100-19 9.5 9.5 0 000 19z" stroke={color} />
      <path
        d="M7.698 8.315h.85a1.388 1.388 0 011.45-1.382 1.311 1.311 0 011.4 1.221c-.004.59-.229.961-1.176 1.961l-2.48 2.612v.605h4.648v-.781H8.957v-.02l1.783-1.839c1.177-1.216 1.533-1.8 1.533-2.588a2.094 2.094 0 00-2.251-1.934 2.187 2.187 0 00-2.324 2.145z"
        fill={color}
      />
    </svg>
  );
}

MainIcon.propTypes = {
  width: PropTypes.number,
  color: PropTypes.string,
};

export default MainIcon;
