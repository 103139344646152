import React from 'react';
import moment from 'moment-timezone';
import { SERVER_DATE_TIME } from '@umai/common';
import type { Reservation } from 'modules/ReservationsList/types';
import TableIcon from 'components/icons/TableIcon';
import PersonIcon from 'components/icons/PersonIcon';
import styles from './CallPopup.module.scss';

function ReservationDetails({
  tables,
  slotStartTime,
  turnTime,
  guestCount,
}: Pick<Reservation, 'tables' | 'slotStartTime' | 'turnTime' | 'guestCount'>) {
  const reservationTables = tables.map((t) => t.name).join(',');

  return (
    <div className={styles.details}>
      <time>{`${moment(slotStartTime, SERVER_DATE_TIME).format("ddd DD MMM'YY")}, ${moment(slotStartTime, SERVER_DATE_TIME).format('LT')} - ${moment(
        slotStartTime
      )
        .add(Math.abs(turnTime), 'minutes')
        .format('LT')}`}</time>
      <span>
        <PersonIcon width={13} height={14} />
        <span>{guestCount}</span>
      </span>
      {reservationTables.length ? (
        <span>
          <TableIcon width={14} height={14} />
          <span>{reservationTables}</span>
        </span>
      ) : null}
    </div>
  );
}

export default ReservationDetails;
