/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import PopoverLib, { type PopoverProps } from 'antd/lib/popover';
import { usePopoverLibContext } from 'contexts/popover';
import { ANTD_POPOVER_BACKDROP } from 'constants/app';

interface Props extends PopoverProps {
  triggerElement: React.ReactNode;
  children: React.ReactNode;
  isBackdropEnabled?: boolean;
  mountOnContainer?: boolean;
}

// NOTE: Do Not use destroyTooltipOnHide globally for the popover.It will also destroy any modals that are mounted inside the popover.
// Example: When marking "finish" from the status selector popover, we open record spend modal, which is mounted inside status selector popover,
// hence on destroying popover, will destroy the modal too, preventing the reservation from being marked as finished.
const Popover = ({
  children,
  triggerElement,
  placement = 'bottomRight',
  trigger = 'click',
  mouseEnterDelay = 0,
  mouseLeaveDelay = 0,
  isBackdropEnabled = true,
  open = false,
  onOpenChange,
  mountOnContainer = false,
  ...restOfProps
}: Props) => {
  const backdropElement = useRef<HTMLElement>(null);
  const { popoverWrapper, popoverBackdrop } = usePopoverLibContext();

  useEffect(() => {
    // Using backdrop element condition for timeline.. as it wont work by wrapping the PopoverProvider to timeline item
    // @ts-expect-error TS(2540): Cannot assign to 'current' because it is a read-on... Remove this comment to see the full error message
    backdropElement.current = popoverBackdrop?.current
      ? popoverBackdrop.current
      : document.getElementById(ANTD_POPOVER_BACKDROP);
  }, []);

  useEffect(() => {
    const isEnabled = isBackdropEnabled && backdropElement.current && trigger !== 'hover';

    if (isEnabled) {
      if (open) backdropElement.current.style.display = 'block';
      else backdropElement.current.style.display = 'none';
    }

    return () => {
      if (isEnabled) backdropElement.current.style.display = 'none';
    };
  }, [open]);

  return (
    <PopoverLib
      open={open}
      onOpenChange={onOpenChange}
      placement={placement}
      content={children}
      trigger={trigger}
      mouseEnterDelay={mouseEnterDelay}
      mouseLeaveDelay={mouseLeaveDelay}
      autoAdjustOverflow
      {...(mountOnContainer &&
        popoverWrapper?.current && {
          getPopupContainer: () => popoverWrapper.current as HTMLElement,
        })}
      {...restOfProps}
    >
      {triggerElement}
    </PopoverLib>
  );
};

Popover.propTypes = {
  triggerElement: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  isBackdropEnabled: PropTypes.bool,
  mountOnContainer: PropTypes.bool,
};

export default Popover;
