import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'constants/colors';

interface DepositProps {
  width?: number | string;
  height?: number | string;
  color?: string;
}

function Deposit({ width = 20, height = 20, color = COLORS.WHITE }: DepositProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
    >
      <title>Deposit </title>
      <path
        fill={color}
        d="M15.14 4.25H1.86a.418.418 0 00-.314.138.475.475 0 00-.13.334v7.556a.5.5 0 00.13.334.442.442 0 00.313.138h13.282a.42.42 0 00.313-.138.478.478 0 00.13-.334V4.722a.5.5 0 00-.13-.334.44.44 0 00-.313-.138zm-2.157 7.556H4.017a2.614 2.614 0 00-.625-1.163 2.376 2.376 0 00-1.09-.667V7.024a2.376 2.376 0 001.09-.667c.3-.322.517-.723.625-1.163h8.966c.108.44.324.841.625 1.163.302.321.678.551 1.09.667v2.952a2.376 2.376 0 00-1.09.667 2.613 2.613 0 00-.625 1.163zm1.715-5.777a1.644 1.644 0 01-.782-.835h.782v.835zM3.084 5.194c-.158.37-.436.666-.782.835v-.835h.782zm-.782 5.777c.346.17.624.465.782.835h-.782v-.835zm11.614.835c.158-.37.436-.666.782-.835v.835h-.782z"
      ></path>
      <path
        fill={color}
        d="M8.5 6.611c-.35 0-.693.111-.984.319a1.868 1.868 0 00-.652.847c-.134.346-.17.725-.1 1.092.067.366.236.703.484.967.247.264.563.444.906.517.344.073.7.035 1.024-.108a1.8 1.8 0 00.794-.695 1.98 1.98 0 00.299-1.05c0-.5-.187-.98-.52-1.335A1.718 1.718 0 008.5 6.611zm0 2.834a.847.847 0 01-.492-.16.934.934 0 01-.326-.423 1.003 1.003 0 01-.05-.546.965.965 0 01.242-.484.87.87 0 01.453-.258.834.834 0 01.512.054.9.9 0 01.397.348.99.99 0 01-.11 1.192.86.86 0 01-.626.277z"
      ></path>
    </svg>
  );
}

Deposit.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string,
};

export default Deposit;
