import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'constants/colors';

interface SupplementIconProps {
  width?: number | string;
  height?: number | string;
  color?: string;
}

function SupplementIcon({ width = 12, height = 14, color = COLORS.WHITE }: SupplementIconProps) {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Supplement icon</title>
      <path
        d="M9.372.1H1.645A1.55 1.55 0 00.1 1.645v3.284h2.318v8.152l1.642-.773 1.313.792 1.294-.792 1.275.773 1.314-.792 1.661.753v-11.4A1.538 1.538 0 009.372.1zm-8.5 4.056V1.645a.773.773 0 111.545 0v2.511H.872zm9.272 7.707l-.947-.425-1.256.753-1.294-.773-1.294.792-1.253-.771-.908.425V1.645a.657.657 0 00-.019-.193v-.058c0-.039-.019-.1-.019-.135a.071.071 0 00-.019-.058c-.019-.058-.039-.1-.058-.155v-.019A1.148 1.148 0 003 .872V.853h6.374a.775.775 0 01.773.773l-.003 10.237z"
        fill={color}
        stroke={color}
        strokeWidth={0.2}
      />
      <path
        d="M8.986 2.032H4.35v.867h4.636v-.867zM8.213 5.509H4.35v.867h3.863v-.867zM7.247 3.77H4.35v.867h2.897V3.77z"
        fill={color}
      />
    </svg>
  );
}

SupplementIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string,
};

export default SupplementIcon;
