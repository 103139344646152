import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'constants/colors';

interface WhatsAppIconProps {
  width?: number;
  height?: number;
  color?: string;
}

function WhatsAppIcon({ width = 17, height = 17, color = COLORS.BLACK }: WhatsAppIconProps) {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="WhatsApp Icon"
      viewBox="0 0 17 17"
    >
      <path
        d="M7.437.041C3.873.527 1.099 3.121.42 6.601c-.338 1.746-.087 3.523.728 5.167l.294.584-.729 2.152c-.402 1.18-.721 2.155-.714 2.162.007.008 1.023-.304 2.252-.696l2.24-.715.428.203a8.324 8.324 0 005.544.563c2.84-.704 5.124-2.91 5.91-5.711.33-1.183.385-2.591.142-3.792-.558-2.754-2.523-5.044-5.185-6.034a9.877 9.877 0 00-1.555-.41C9.3-.006 7.926-.028 7.436.04zm2.03 1.31c2.673.363 4.943 2.355 5.657 4.967a6.953 6.953 0 01.04 3.462 6.896 6.896 0 01-4.5 4.826c-1.897.631-4 .413-5.686-.592a2.756 2.756 0 00-.312-.167c-.01 0-.605.185-1.32.414-.71.225-1.294.399-1.294.381 0-.014.189-.584.42-1.263l.421-1.233-.188-.283a9.04 9.04 0 01-.758-1.539C.84 7.117 2.302 3.568 5.37 2.015c1.265-.639 2.64-.86 4.097-.664z"
        fill={color}
      />
      <path
        d="M5.395 4.49c-.286.141-.681.693-.826 1.154-.03.09-.066.33-.077.53-.025.464.066.867.316 1.39.185.384.613 1.03 1.037 1.56.743.932 1.66 1.694 2.53 2.111.679.323 1.723.668 2.165.715.606.062 1.527-.341 1.85-.813.21-.305.326-1.052.185-1.183-.073-.065-1.447-.736-1.72-.841-.093-.037-.21-.055-.257-.04-.054.01-.195.156-.355.355-.391.493-.584.682-.689.682-.141 0-.845-.341-1.24-.602-.504-.334-1.153-.987-1.505-1.513-.145-.214-.26-.432-.26-.48 0-.05.094-.192.253-.377.232-.272.435-.595.435-.696 0-.022-.167-.443-.37-.936-.24-.574-.406-.93-.467-.995-.095-.094-.113-.098-.475-.098-.309 0-.403.015-.53.077z"
        fill={color}
      />
    </svg>
  );
}

WhatsAppIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default WhatsAppIcon;
