import { isMobileDevice } from 'utils/devices';
import { BLOCKED_STATUS, RESERVATION_LIST_TYPES } from '../ReservationsList/constants';

export const GROUP_TYPES = {
  room: 'room',
  table: 'table',
};

export const DEFAULT_GROUP = {
  waitlist: { id: 'waitlist', roomId: 'waitlistRoom', name: 'Waitlist' },
  noTables: { id: 'noTables', roomId: 'noTablesRoom', name: 'No tables' },
};

export const TIMELINE_LIST_TYPE = {
  yesterday: 'yesterday',
  today: 'today',
  tomorrow: 'tomorrow',
};

export const TIMELINE_FUTURE_DAYS = [TIMELINE_LIST_TYPE.today, TIMELINE_LIST_TYPE.tomorrow];

// NOTE this these height should be synced with the vars defined in timeline styles:
//  --timeline-height-room: 30px; --timeline-height-table: 57px;
export const ROOM_HEIGHT = 30;
export const MIN_ROOM_HEIGHT = 17;

export const TABLE_HEIGHT = 57;
export const MEDIUM_TABLE_HEIGHT = 38;
export const MIN_TABLE_HEIGHT = 25;

export const NEW_TIMELINE_ID = 'new-timeline-item';

export const TIMELINE_TIME_INTERVAL = 15; // mins

export const TIMELINE_ITEM_TYPES = {
  ...RESERVATION_LIST_TYPES,
  ADD_NEW_ITEM: 'ADD_NEW_ITEM',
  BLOCKED_STATUS,
  ROOM_SUMMARY: 'ROOM_SUMMARY',
  BACKGROUND: 'BACKGROUND',
};

export const DRAG_ACTIONS = {
  toReservations: 'toReservations',
  waitlistTime: 'waitlistTime',
  timeAndLinkTables: 'timeAndLinkTables',
  timeAndTable: 'timeAndTable',
  linkTables: 'linkTables',
  time: 'time',
  table: 'table',
  swapTables: 'swapTables',
  swapTablesAndTime: 'swapTablesAndTime',
  swapGuestTime: 'swapGuestTime',
};

export const SCROLL_OFFSET_X = isMobileDevice ? 120 : 180; // minutes

export const SCROLL_OFFSET_Y = isMobileDevice ? 100 : 200; // pixels

export const RESIZE_ABLE_DELTA = 40; // pixels

export const HOURS_IN_MILLISECOND = 1000 * 60 * 60;

export const ZOOM_TIME_AMOUNT = 0.5;

export const ZOOM_OUT_TABLE_AMOUNT = 0.33;

export const ZOOM_IN_TABLE_AMOUNT = 0.5;
