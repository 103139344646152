import startCase from 'lodash-es/startCase';
import { parseNewGuestQuery } from 'modules/ReservationFunnel/services';

export { replaceAll, limitString } from '@umai/common';

export const checkStringMatch = (stringsToCheck: string[] = [], stringToCompare = '') =>
  stringsToCheck?.some((str: string) => {
    str = String(str)?.toLowerCase()?.trim();
    return str.indexOf(stringToCompare.toLowerCase()) !== -1;
  });

// Get firstName and lastName from full name string
export const formatFirstAndLastName = (name = '') => {
  let lastName = '';
  let firstName = '';
  if (name?.length) {
    const names = name?.trim()?.split(' ');
    if (names?.length > 1) {
      firstName = startCase(names?.shift() || '');
      lastName = startCase(names?.join(' ') || '');
    } else {
      firstName = startCase(names?.join(' ') || '');
    }
  }

  return { firstName, lastName };
};

export const formatName = (firstName?: string, lastName?: string) =>
  (firstName && lastName ? `${firstName} ${lastName}` : firstName)?.trim();

const refactorToNormalNumber = (amount?: number | string | null) =>
  (
    parseFloat(
      String(amount).replace(/\s/g, '').replace(/\./g, '').replace(/,/g, '').replace(/^0+/, '')
    ) / 100
  ).toFixed(2);

export const formatInputAmount = (amount?: number | string | null, venueLanguage?: string) => {
  const refactoredValue = +refactorToNormalNumber(amount);
  if (!Number.isNaN(refactoredValue)) {
    return Intl.NumberFormat(venueLanguage, { minimumFractionDigits: 2 }).format(refactoredValue);
  }
  return '';
};

const revertFormattWithFormatToParts = (
  amount?: number | string | null,
  venueLanguage?: string
) => {
  let reFormattedValue = '';

  const arrayOfFormatParts = Intl.NumberFormat(venueLanguage, {
    minimumFractionDigits: 2,
  }).formatToParts(+refactorToNormalNumber(amount));

  arrayOfFormatParts.forEach(({ type, value }) => {
    if (type === 'integer') {
      reFormattedValue += value;
    } else if (type === 'fraction') {
      reFormattedValue = `${reFormattedValue}.${value}`;
    }
  });
  return reFormattedValue;
};

function revertFormattWithoutFormatToParts(
  amount?: number | string | null,
  venueLanguage?: string
) {
  const group = new Intl.NumberFormat(venueLanguage).format(1111).replace(/1/g, '');
  const decimal = new Intl.NumberFormat(venueLanguage).format(1.1).replace(/1/g, '');
  let reversedValue = String(amount)?.replace(new RegExp(`\\${group}`, 'g'), '');
  reversedValue = refactorToNormalNumber(reversedValue)?.replace(
    new RegExp(`\\${decimal}`, 'g'),
    '.'
  );
  return Number.isNaN(+reversedValue) ? 0 : reversedValue;
}

export function revertFormattedInputAmount(
  amount?: number | string | null,
  venueLanguage?: string
) {
  if (typeof Intl.NumberFormat.prototype.formatToParts === 'function') {
    return revertFormattWithFormatToParts(amount, venueLanguage);
  }
  return revertFormattWithoutFormatToParts(amount, venueLanguage);
}

// this is used to check if obj2 is a subset of obj1
export const areEqualObject = (
  obj1: Record<string, unknown> = {},
  obj2: Record<string, unknown> = {}
) => Object.entries(obj1).every(([key, val]: [string, any]) => obj2[key] === val);

export const isExactMatchingSearchText = ({
  searchText,
  callingCode,
  data,
}: {
  searchText?: string;
  callingCode: string;
  data: { name: string; phone: string; email?: string };
}) => {
  const { name, phone, email } = data;
  const {
    phone: searchPhone,
    firstName,
    lastName,
    email: searchEmail,
  } = parseNewGuestQuery(searchText, callingCode);
  const isExactEmail = email && email === searchEmail;
  const isExactPhone = phone === searchPhone;
  const isExactName = `${firstName} ${lastName}`.trim() === name?.trim();
  const isExactMatch = isExactPhone || isExactName || isExactEmail;
  const matchTypes: string[] = [];

  if (isExactName) {
    matchTypes.push('name');
  }
  if (isExactPhone) {
    matchTypes.push('phone number');
  }
  if (isExactEmail) {
    matchTypes.push('email');
  }

  let matchType = matchTypes.join(', ');
  // If more than one type is present, add "and" before the last type
  if (matchTypes.length > 1) {
    const lastType = matchTypes.pop(); // Remove the last type
    matchType = `${matchTypes.join(', ')} and ${lastType}`; // Join remaining types with ', ' and append the last type with 'and'
  }
  const title = isExactMatch ? `Matches the ${matchType} you searched for.` : '';

  return {
    isExactMatch,
    isExactName,
    isExactPhone,
    isExactEmail,
    title,
  };
};
