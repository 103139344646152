import { useContext, useEffect } from 'react';
import { useAppSelector } from 'hooks/redux';
import humps from 'humps';
import { AppContext } from 'contexts/app';
import { CHANNELS } from 'constants/socket';
import { selectIsAuthenticated } from 'modules/Auth/selectors';
import {
  selectCurrentVenueId,
  selectIsIncomingCallEnabled,
  selectIsIncomingCallSubscribed,
} from 'modules/Partner/selectors';
import useSocket from 'hooks/useSocket';
import type { IncomingCall } from '../types';
import { getIsInAppNotificationEnabled } from '../services';

const useSocketIncomingCall = () => {
  const { subscribeChannel, unsubscribeChannel } = useSocket<IncomingCall>();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const currentVenueId = useAppSelector(selectCurrentVenueId);
  const isSubscribed = useAppSelector(selectIsIncomingCallSubscribed);
  const isEnabled = useAppSelector(selectIsIncomingCallEnabled);
  const { setCallPopup } = useContext(AppContext);

  useEffect(() => {
    if (
      currentVenueId &&
      isAuthenticated &&
      isSubscribed &&
      isEnabled &&
      getIsInAppNotificationEnabled(currentVenueId)
    ) {
      subscribeChannel({
        channel: CHANNELS.incomingCallChannel,
        data: { venue_id: currentVenueId },
        onReceived: (incomingCall: IncomingCall) => {
          // checking getIsInAppNotificationEnabled here for the case when venue has not refresh the page or changed venue, after disabling in-app notification
          if (getIsInAppNotificationEnabled(currentVenueId)) {
            const data = humps.camelizeKeys<IncomingCall>(incomingCall);
            setCallPopup({ isVisible: true, data });
            // vibrate on opening call popup
            window?.navigator?.vibrate?.([100, 200, 100, 200, 100, 200]);
          }
        },
      });
    }
    return () => {
      if (currentVenueId && isAuthenticated && isSubscribed && isEnabled) {
        unsubscribeChannel();
      }
    };
  }, [currentVenueId, isAuthenticated, isSubscribed, isEnabled]);
};

export default useSocketIncomingCall;
