import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'constants/colors';

interface RemoveFromQueueIconProps {
  width?: number;
  color?: string;
}

function RemoveFromQueueIcon({ width = 20, color = COLORS.WHITE }: RemoveFromQueueIconProps) {
  return (
    <svg width={width} viewBox="0 0 20 20" fill="none">
      <rect width="20" height="19.799" transform="translate(0)" />
      <path
        d="M12.917,7.22A6.281,6.281,0,0,1,11.792,10,6.593,6.593,0,0,1,2.75,11.528,6.261,6.261,0,0,1,1.185,2.685a6.593,6.593,0,0,1,9.042-1.529.491.491,0,0,1,.122.693.51.51,0,0,1-.7.119A5.573,5.573,0,0,0,2.007,3.259a5.279,5.279,0,0,0,1.321,7.457,5.573,5.573,0,0,0,7.641-1.291,5.22,5.22,0,0,0,.949-2.342,5.283,5.283,0,0,0-.228-2.428.494.494,0,0,1,.32-.628.506.506,0,0,1,.636.314A6.26,6.26,0,0,1,12.917,7.22Z"
        transform="translate(6.912 7.316)"
        fill={color}
      />
      <rect
        width="1.295"
        height="7.556"
        rx="0.648"
        transform="translate(15.643 10.5) rotate(45)"
        fill={color}
      />
      <rect
        width="1.295"
        height="7.556"
        rx="0.648"
        transform="translate(16.559 15.843) rotate(135)"
        fill={color}
      />
      <path
        d="M5.672,14.144a.419.419,0,0,0-.452-.413.427.427,0,0,0-.4.427v3.294H3.471A1.255,1.255,0,0,1,2.2,16.212v-.455h.917a.429.429,0,0,0,.437-.386.418.418,0,0,0-.422-.441H2.2V11.621h.917a.429.429,0,0,0,.437-.386.418.418,0,0,0-.422-.441H2.2V7.485h.917A.429.429,0,0,0,3.555,7.1a.418.418,0,0,0-.422-.441H2.2V3.35h.917a.429.429,0,0,0,.437-.386.418.418,0,0,0-.422-.441H2.2V2.068A1.256,1.256,0,0,1,3.471.827H4.825V12.719a.427.427,0,0,0,.4.427.419.419,0,0,0,.451-.413V.827h8.042a1.256,1.256,0,0,1,1.27,1.241V6.616a.4.4,0,0,0,.8,0V1.654A2.108,2.108,0,0,0,13.714,0H3.471A2.093,2.093,0,0,0,1.355,2.068v.455H.438A.429.429,0,0,0,0,2.909a.418.418,0,0,0,.422.441h.931V6.658H.438A.429.429,0,0,0,0,7.044a.418.418,0,0,0,.422.441h.931v3.308H.438A.429.429,0,0,0,0,11.18a.418.418,0,0,0,.422.441h.931v3.308H.438A.429.429,0,0,0,0,15.315a.418.418,0,0,0,.422.441h.931v.455a2.093,2.093,0,0,0,2.116,2.068H7.063a.414.414,0,1,0,0-.827H5.672Z"
        transform="translate(0 0.052)"
        fill={color}
      />
    </svg>
  );
}

RemoveFromQueueIcon.propTypes = {
  width: PropTypes.number,
  color: PropTypes.string,
};

export default RemoveFromQueueIcon;
