import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useAppSelector } from 'hooks/redux';
import { getDataFromStorage, removeDataFromStorage, setDataToStorage } from '@umai/common';
import PartnerApi from '../apis';
import { selectCurrentVenueId } from 'modules/Partner/selectors';
import type { VenueId } from 'modules/Venue/types';

const STRIPE_WARNING_KEY = 'stripeWarningClosed';
const STRIPE_WARNING_EXPIRATION = 86_400_000; // 1 days in milliseconds

// Because the Stripe warning is venue-specific, we need to add the venueId to the key
// If the warning is closed for one venue, it should still be visible for another venue
function getStripeWarningKey(venueId: VenueId) {
  return `${STRIPE_WARNING_KEY}:${venueId}`;
}

export default function useWarnings() {
  const venueId = useAppSelector(selectCurrentVenueId);
  const [isStripeWarningVisible, setIsStripeWarningVisible] = useState(false);

  const {
    isLoading: isWarningsLoading,
    data: warnings,
    isError: isWarningsError,
  } = useQuery({
    queryKey: ['venue-warnings', venueId],
    queryFn: () => PartnerApi.fetchWarnings(),
    select: (data) => data.warnings,
  });

  function closeStripeWarning() {
    setDataToStorage(getStripeWarningKey(venueId), Date.now());
    setIsStripeWarningVisible(false);
  }

  useEffect(() => {
    if (Array.isArray(warnings) && !isWarningsLoading && !isWarningsError) {
      if (warnings.length < 1) {
        // The response doesn't contain any warnings, make sure to remove close information from localstorage
        removeDataFromStorage(getStripeWarningKey(venueId));
        setIsStripeWarningVisible(false);
      } else {
        const lastClosedTimestamp = getDataFromStorage(getStripeWarningKey(venueId));
        // Show the Stripe warning when the warning hasn't been closed yet OR
        // when the last warning was closed more than a day ago
        if (!lastClosedTimestamp || Date.now() - lastClosedTimestamp >= STRIPE_WARNING_EXPIRATION) {
          setIsStripeWarningVisible(true);
        } else {
          setIsStripeWarningVisible(false);
        }
      }
    }
  }, [warnings, isWarningsLoading, isWarningsError]);

  return {
    isStripeWarningVisible,
    closeStripeWarning,
    isWarningsLoading,
    isWarningsError,
    warnings,
  };
}
