import { useQuery } from '@tanstack/react-query';
import { useAppSelector } from 'hooks/redux';
import { selectCurrentVenueId } from 'modules/Partner/selectors';
import reservationApi from 'modules/ReservationsList/apis';
import { incomingCallKeys } from '../services';

export default function useFetchReservation({
  reservationId,
  enabled = true,
}: {
  reservationId?: number;
  enabled?: boolean;
}) {
  const venueId = useAppSelector(selectCurrentVenueId);

  const {
    isFetching: isFetchingReservation,
    data: reservation,
    refetch: refetchReservation,
  } = useQuery({
    queryKey: incomingCallKeys.fetchReservation({ venueId, reservationId }),
    queryFn: () => {
      if (reservationId) {
        return reservationApi.getReservation({ id: reservationId, venueId });
      }

      return null;
    },
    select: (data) => data?.reservation,
    enabled: !!venueId && !!reservationId && enabled,
  });

  return { reservation, isFetchingReservation, refetchReservation };
}
