import React from 'react';
import AccessMessage from 'components/shared/AccessMessage';
import Logo360Icon from 'components/icons/Logo360Icon';
import styles from './Subscription.module.scss';

function IncomingCallSubscription() {
  return (
    <div className={styles.unSubsIncomingCall} aria-label="Unsubscribed call view">
      <AccessMessage
        className={styles.incomingCallSubscription}
        header={<Logo360Icon />}
        title={
          <span className={styles.title}>
            UPhone ensures you don’t lose any guests when the phone isn’t answered.
          </span>
        }
        description={
          <div className={styles.description}>
            <p>
              <span>
                UPhone automatically keeps track of who is calling your business and shows you
                detailed guest history when guests call.
              </span>
              <span>
                UPhone fills out booking information when saved guests call your business, making it
                faster and allowing you to offer personal service when receiving calls.
              </span>
              <span>
                Incase you can’t reach the phone UMAI 360 will automatically send your own custom
                message to the guest, ensuring guests don’t make reservations elsewhere
              </span>
            </p>
            <img
              src="https://umai360-backgrounds.s3.ap-southeast-1.amazonaws.com/incoming-call.png"
              loading="lazy"
              alt="incoming call"
            />
          </div>
        }
        subTitle={
          <span className={styles.subTitle}>
            Speak to your UMAI account manager to hear about UPhone
          </span>
        }
        footer={null}
      />
    </div>
  );
}

export default IncomingCallSubscription;
