import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'constants/colors';

interface RemovedQueueIconProps {
  width?: number;
  color?: string;
}

function RemovedQueueIcon({ width = 20, color = COLORS.WHITE }: RemovedQueueIconProps) {
  return (
    <svg width={width} viewBox="0 0 22 20" fill="none">
      <g transform="translate(0.15 0.15)">
        <path
          d="M12.441,6.954a6.049,6.049,0,0,1-1.084,2.677A6.35,6.35,0,0,1,2.648,11.1,6.03,6.03,0,0,1,1.141,2.587,6.35,6.35,0,0,1,9.85,1.114a.473.473,0,0,1,.117.667.491.491,0,0,1-.674.115,5.368,5.368,0,0,0-7.36,1.244,5.085,5.085,0,0,0,1.273,7.182,5.368,5.368,0,0,0,7.36-1.244,5.028,5.028,0,0,0,.914-2.256,5.089,5.089,0,0,0-.219-2.339.475.475,0,0,1,.309-.6.487.487,0,0,1,.612.3A6.029,6.029,0,0,1,12.441,6.954Z"
          transform="translate(9.5 7.7)"
          fill={color}
        />
        <rect
          width="1.248"
          height="7.278"
          rx="0.624"
          transform="translate(17.861 10.767) rotate(45)"
          fill={color}
        />
        <rect
          width="1.248"
          height="7.278"
          rx="0.624"
          transform="translate(18.744 15.913) rotate(135)"
          fill={color}
        />
        <path
          d="M49.509,41.459v-4.2a.254.254,0,0,0-.509,0V41.7l1.4.873a.254.254,0,1,0,.329-.388Z"
          transform="translate(-41.88 -32.423)"
          fill={color}
          stroke={color}
          strokeWidth={0.3}
        />
        <path
          d="M7.375,16.528A7.373,7.373,0,0,1,7.121,1.786V.508H5.848a.254.254,0,0,1,0-.508H8.9a.254.254,0,0,1,0,.508H7.628V1.787A7.354,7.354,0,0,1,12.4,3.768l0,0,.9-.9-.9-.9a.254.254,0,0,1,.18-.434.252.252,0,0,1,.18.074L14.926,3.76a.254.254,0,1,1-.359.359l-.9-.9-.9.9-.005,0A7.5,7.5,0,0,1,14.038,6h-.723A7.332,7.332,0,0,0,7.375,2.289,7.332,7.332,0,0,0,.509,9.154,7.143,7.143,0,0,0,7.375,16.02c.208,0,.418-.011.625-.031V16.5C7.789,16.52,7.579,16.528,7.375,16.528Z"
          transform="translate(0 0)"
          fill={color}
          stroke={color}
          strokeWidth={0.3}
        />
      </g>
    </svg>
  );
}

RemovedQueueIcon.propTypes = {
  width: PropTypes.number,
  color: PropTypes.string,
};

export default RemovedQueueIcon;
