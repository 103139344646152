import React from 'react';
import type { SVGProps } from 'react';
import { COLORS } from 'constants/colors';

const MinimizeIcon = ({
  width = 15,
  height = 2,
  color = COLORS.WHITE,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 15 2"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <rect width={14} height={1.4} x={0.5} y={0.5} fill={color} rx={0.7} />
  </svg>
);
export default MinimizeIcon;
