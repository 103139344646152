import { useHistory, useLocation } from 'react-router-dom';
import { RoutePath, ROUTES } from 'constants/routes';
import { useAppDispatch } from 'hooks/redux';
import { changeDate } from 'modules/Footer/actions';
import { adjustDayAfterMidNight } from 'utils/date-and-time';
import {
  setReservationListLoading,
  setViewReservationId,
  updateReservationListType,
} from '../actions';
import { FINISHED_STATUSES, RESERVATION_LIST_TYPES } from '../constants';
import { ReservationListTypes } from '../types';
import { DATE_FORMAT } from '@umai/common';

type ViewReservationParams = {
  dateTime: string;
  id: number;
  status: string;
};

export const useViewReservationDetails = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();

  return ({ dateTime, id, status }: ViewReservationParams) => {
    let listType: ReservationListTypes = RESERVATION_LIST_TYPES.RESERVED;
    let pathname: RoutePath = ROUTES.RESERVATIONS;
    if (FINISHED_STATUSES.includes(status.toUpperCase())) {
      listType = RESERVATION_LIST_TYPES.FINISHED;
      pathname = ROUTES.FINISHED_RESERVATIONS;
    }

    dispatch(changeDate(adjustDayAfterMidNight({ time: dateTime, action: 'subtract' })));
    dispatch(updateReservationListType(listType));
    // Setting the loading state here, make sure the open drawer is always called after the reservations are loaded
    //  on the reservationList component.  Without this, open drawer is call before loading state is true
    //  and causes the drawer to not open.
    dispatch(setReservationListLoading());
    dispatch(setViewReservationId(id));

    const reservationDate = adjustDayAfterMidNight({ time: dateTime, action: 'subtract' });
    const params = new URLSearchParams(location.search);
    params.set('reservationId', id.toString());
    params.set('date', reservationDate.format(DATE_FORMAT));

    // redirect to finish or current list with the existing search params
    history.push({
      ...location,
      pathname,
      search: params.toString(),
      state: { isOpenDrawer: true, reservationId: id },
    });
  };
};
