import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'constants/colors';

interface FinishedIconProps {
  width?: number;
  color?: string;
}

function FinishedIcon({ width = 20, color = COLORS.WHITE }: FinishedIconProps) {
  return (
    <svg width={width} fill="none" viewBox="0 0 20 20">
      <g clipPath="url(#prefix__clip0)" fill={color}>
        <path d="M19.906 11.127a9.68 9.68 0 01-1.734 4.283 10.16 10.16 0 01-13.934 2.356A9.649 9.649 0 011.825 4.138a10.16 10.16 0 0113.934-2.356.756.756 0 01.188 1.068.785.785 0 01-1.079.184A8.59 8.59 0 003.092 5.023a8.135 8.135 0 002.037 11.491A8.59 8.59 0 0016.9 14.525a8.045 8.045 0 001.463-3.61 8.143 8.143 0 00-.351-3.742.76.76 0 01.494-.967.78.78 0 01.98.484 9.648 9.648 0 01.42 4.437z" />
        <path d="M3.558 9.786a.88.88 0 01-.141-1.253.916.916 0 011.272-.14l4.54 3.549 7.765-8.534a.917.917 0 011.492.201.881.881 0 01-.139.984l-8.338 9.161a.916.916 0 01-1.24.1L3.558 9.786z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill={color} d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

FinishedIcon.propTypes = {
  width: PropTypes.number,
  color: PropTypes.string,
};

export default FinishedIcon;
