import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'constants/colors';

interface CheckIconProps {
  width?: number;
  color?: string;
}

function CheckIcon({ width = 20, color = COLORS.WHITE }: CheckIconProps) {
  return (
    <svg width={width} fill="none" viewBox="0 0 20 20">
      <g clipPath="url(#prefix__clip0)" fill={color}>
        <path d="M9.698 4.44a.381.381 0 100 .762h6.868a.381.381 0 100-.762H9.698zm6.884 5.179H3.177a.372.372 0 00-.366.382.372.372 0 00.366.38h13.4a.373.373 0 00.366-.38.373.373 0 00-.361-.382zm0 5.18H3.177a.373.373 0 00-.366.382.372.372 0 00.366.38h13.4a.373.373 0 00.366-.38.374.374 0 00-.361-.382z" />
        <path d="M16.575 19.26H4.51a3.772 3.772 0 01-3.768-3.769V4.511A3.771 3.771 0 014.51.742h10.981a3.772 3.772 0 013.769 3.769V16.6a.37.37 0 10.739 0V4.511A4.516 4.516 0 0015.491 0H4.51A4.516 4.516 0 000 4.511v10.98A4.515 4.515 0 004.51 20h12.065a.37.37 0 000-.74z" />
        <path d="M5.041 7.422v-.4c.823-.062 1.307-.502 1.307-1.196 0-.592-.343-.946-1.058-1.137l-.249-.062V3.195a.756.756 0 01.735.694h.5a1.215 1.215 0 00-1.235-1.148v-.407h-.393v.41a1.177 1.177 0 00-1.212 1.151 1.126 1.126 0 00.978 1.093l.234.064v1.523a.81.81 0 01-.814-.71h-.5c0 .653.524 1.107 1.315 1.166v.393l.392-.002zm.8-1.557c0 .428-.29.691-.8.718V5.132c.553.117.8.348.8.733zm-1.9-2a.7.7 0 01.707-.67v1.348c-.462-.097-.709-.328-.709-.678h.002z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill={color} d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

CheckIcon.propTypes = {
  width: PropTypes.number,
  color: PropTypes.string,
};

export default CheckIcon;
