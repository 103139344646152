import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'constants/colors';

interface ArrivedIconProps {
  width?: number;
  color?: string;
}

function ArrivedIcon({ width = 20, color = COLORS.WHITE }: ArrivedIconProps) {
  return (
    <svg width={width} fill="none" viewBox="0 0 20 20">
      <path
        d="M10.064 9.497A2.586 2.586 0 017.455 6.94a2.586 2.586 0 012.609-2.556 2.585 2.585 0 012.607 2.556 2.586 2.586 0 01-2.607 2.557zm0-4.384A1.847 1.847 0 008.201 6.94a1.847 1.847 0 001.863 1.826 1.847 1.847 0 001.863-1.826 1.847 1.847 0 00-1.863-1.827z"
        fill={color}
      />
      <path
        d="M9.998 20l-5.725-8.1a7.852 7.852 0 01.671-9.845 7.25 7.25 0 0110.107 0 7.859 7.859 0 01.662 9.855L9.999 20h-.001zm0-19.268a6.434 6.434 0 00-4.526 1.836 7.1 7.1 0 00-.595 8.9l5.122 7.248 5.11-7.237a7.107 7.107 0 00-.585-8.912A6.432 6.432 0 009.998.732z"
        fill={color}
      />
    </svg>
  );
}

ArrivedIcon.propTypes = {
  width: PropTypes.number,
  color: PropTypes.string,
};

export default ArrivedIcon;
