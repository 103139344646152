import React from 'react';
import classNames from 'classnames';
import { HELP_CHAT_INTERCOM_ID } from '@umai/common';
import { openUmaiLink } from 'utils/url-helpers';
import './AccessMessage.style.scss';

interface Props {
  title: React.ReactNode;
  description: React.ReactNode;
  subTitle: React.ReactNode;
  header: React.ReactNode;
  footer?: React.ReactNode;
  className?: string;
}

const AccessMessage = ({ title, description, subTitle, header, footer, className }: Props) => {
  const renderFooter = () => {
    if (typeof footer !== 'undefined') {
      return footer;
    }

    return (
      <button
        type="button"
        className="go-to-umai-button"
        onClick={() => {
          openUmaiLink();
        }}
      >
        Go to UMAI.io
      </button>
    );
  };

  return (
    <section className={classNames('access-message', className)}>
      <h2 className="header">{header}</h2>
      <h3 className="title">{title}</h3>
      <p className="description">{description}</p>
      <h4 className="sub-title" id={HELP_CHAT_INTERCOM_ID}>
        {subTitle}
      </h4>
      <footer className="footer">{renderFooter()}</footer>
    </section>
  );
};

export default AccessMessage;
