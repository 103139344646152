import React from 'react';
import type { SVGProps } from 'react';
import { COLORS } from 'constants/colors';

const Logo360Icon = ({
  width = 219,
  height = 50,
  color = COLORS.GREEN_PRIMARY,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 219 50"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <path
      fill={color}
      d="M166.021 19.744h2.651c3.017 0 4.892 1.538 4.892 4.029 0 2.211-2.006 3.793-4.804 3.793-2.842 0-4.644-1.494-4.878-3.662h-2.534c.19 3.501 3.017 5.948 7.441 5.948 4.146 0 7.456-2.535 7.456-6.167 0-2.974-2.051-4.952-5.244-5.142v-.059c2.637-.468 4.336-2.373 4.336-5.024 0-3.179-2.857-5.45-6.636-5.45-3.97 0-6.636 2.3-6.929 5.89h2.535c.234-2.213 1.845-3.604 4.277-3.604 2.681 0 4.102 1.42 4.102 3.516 0 2.138-1.7 3.706-4.131 3.706h-2.534zm21.093 10.122c4.336 0 7.486-3.047 7.486-7.221 0-4.014-2.989-6.988-7.017-6.988-2.622 0-4.951 1.363-5.903 3.428h-.044c.088-5.625 2.094-8.76 5.625-8.76 2.094 0 3.794 1.23 4.365 3.15h2.681c-.674-3.326-3.428-5.479-7.017-5.479-5.171 0-8.203 4.175-8.203 11.294 0 2.285.337 4.19 1.04 5.816 1.304 3.046 3.823 4.76 6.987 4.76m-.029-2.343c-2.71 0-4.819-2.154-4.819-4.864 0-2.695 2.065-4.687 4.863-4.687s4.79 1.992 4.79 4.76c0 2.666-2.139 4.79-4.834 4.79m17.739 2.329c4.922 0 7.764-4.263 7.764-10.943 0-6.636-2.857-10.898-7.764-10.898s-7.793 4.292-7.793 10.913c0 6.68 2.857 10.928 7.793 10.928m0-2.3c-3.31 0-5.141-3.37-5.141-8.643 0-5.156 1.875-8.584 5.141-8.584s5.113 3.399 5.113 8.599c0 5.273-1.817 8.628-5.113 8.628"
    />
    <path
      stroke={color}
      strokeWidth={1.2}
      d="M215.499 10.869c1.602 0 2.9-1.294 2.9-2.891a2.895 2.895 0 0 0-2.9-2.892 2.896 2.896 0 0 0-2.901 2.892 2.896 2.896 0 0 0 2.901 2.891Z"
    />
    <path
      fill={color}
      d="M8.21 27.583V4.871h1.956v22.592c0 10.613 5.753 16.206 14.499 16.206 8.688 0 14.327-5.365 14.327-16.103V4.854h1.955v22.54c0 11.927-6.615 18.024-16.34 18.024-9.664.024-16.396-6.025-16.396-17.835M51.125 4.87h1.842l17.031 24.307L86.961 4.869h1.842v39.944h-1.955V8.236l-16.86 23.967h-.116L53.013 8.236v36.577h-1.888zM145.834 4.87h1.956v39.943h-1.956zM118.038 4.583h-1.842l-19.1 40.23h2.014l5.522-11.63 12.43-26.304 11.632 24.536.862 1.768 5.465 11.64h2.13z"
    />
    <path fill={color} d="M123.607 31.405h-13.74v1.766h13.74z" />
  </svg>
);
export default Logo360Icon;
