import React, { type SVGProps } from 'react';
import { COLORS } from 'constants/colors';

function ArrowBack({
  width = 24,
  height = 16,
  color = COLORS.BLACK,
  className,
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.501.24a.818.818 0 011.395.568.8.8 0 01-.236.568L2.786 7.19H22.18a.812.812 0 01.823.8.822.822 0 01-.823.814H2.786l5.877 5.8a.818.818 0 11-1.162 1.154L.236 8.568a.8.8 0 010-1.135L7.501.24z"
        fill={color}
      />
    </svg>
  );
}

export default ArrowBack;
