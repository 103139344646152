import React from 'react';
import { useAppSelector } from 'hooks/redux';
import PropTypes from 'prop-types';
import { selectIsLoyaltySubscribed } from 'modules/Partner/selectors';
import { COLORS } from 'constants/colors';
import LoyaltyIcon from 'components/icons/LoyaltyIcon';

interface LoyaltyStatusIconProps {
  hasLoyalty?: boolean;
  isWhite?: boolean;
}

const LoyaltyStatusIcon = ({ hasLoyalty = false, isWhite = false }: LoyaltyStatusIconProps) => {
  const isLoyaltyProgramEnabled = useAppSelector(selectIsLoyaltySubscribed);
  if (hasLoyalty && isLoyaltyProgramEnabled) {
    if (isWhite) return <LoyaltyIcon color={COLORS.WHITE} />;
    return <LoyaltyIcon />;
  }
  return null;
};

LoyaltyStatusIcon.propTypes = {
  hasLoyalty: PropTypes.bool,
  isWhite: PropTypes.bool,
};

export default LoyaltyStatusIcon;
