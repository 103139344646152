import { useQuery } from '@tanstack/react-query';
import { useAppSelector } from 'hooks/redux';
import { selectCurrentVenueId } from 'modules/Partner/selectors';
import guestApi from 'modules/Guest/apis';
import { incomingCallKeys } from '../services';

export default function useFetchGuest({
  guestId,
  enabled = true,
}: {
  guestId?: number;
  enabled?: boolean;
}) {
  const venueId = useAppSelector(selectCurrentVenueId);

  const {
    isFetching: isFetchingGuest,
    data: guest,
    refetch: refetchGuest,
  } = useQuery({
    queryKey: incomingCallKeys.fetchGuest({ venueId, guestId }),
    queryFn: () => (guestId ? guestApi.fetchMasterGuest(guestId, venueId) : null),
    select: (data) => data?.masterGuest,
    enabled: !!venueId && !!guestId && enabled,
  });

  return { guest, isFetchingGuest, refetchGuest };
}
