import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'constants/colors';

interface AwaitingPaymentIconProps {
  width?: number;
  color?: string;
}

function AwaitingPaymentIcon({ width = 20, color = COLORS.WHITE }: AwaitingPaymentIconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height="20" viewBox="0 0 22.15 20">
      <g transform="translate(0.15)" fill={color}>
        <rect width="20" height="20" transform="translate(2)" fill="none" />
        <path
          d="M12.441,7.115A6.252,6.252,0,1,1,9.85,1.139a.49.49,0,0,1,.117.683.484.484,0,0,1-.674.117,5.277,5.277,0,1,0,1.273,7.349A5.211,5.211,0,0,0,11.48,6.98a5.321,5.321,0,0,0-.219-2.393.486.486,0,1,1,.921-.309A6.3,6.3,0,0,1,12.441,7.115Z"
          transform="translate(9.5 7.5)"
          fill={color}
        />
        <rect
          width="1.248"
          height="1.25"
          rx="0.624"
          transform="translate(12.85 13.1)"
          fill={color}
        />
        <rect
          width="1.248"
          height="1.25"
          rx="0.624"
          transform="translate(15.115 13.1)"
          fill={color}
        />
        <rect
          width="1.248"
          height="1.25"
          rx="0.624"
          transform="translate(17.38 13.1)"
          fill={color}
        />
        <path
          d="M7.578,14.891H2.416A2.366,2.366,0,0,1,0,12.588V6.195a2.366,2.366,0,0,1,2.417-2.3H12.707a2.423,2.423,0,0,1,2.314,1.64c-.251.022-.508.056-.763.1a1.559,1.559,0,0,0-.378-.558,1.717,1.717,0,0,0-1.174-.464H2.416a1.716,1.716,0,0,0-1.176.463,1.551,1.551,0,0,0-.486,1.12v.054H12.311A8.325,8.325,0,0,0,9.442,8.432H.758v4.156a1.553,1.553,0,0,0,.484,1.118,1.7,1.7,0,0,0,1.174.464H7.51c.012.237.034.479.068.72Z"
          transform="translate(0)"
          fill={color}
          stroke={color}
          strokeWidth="0.3"
        />
      </g>
    </svg>
  );
}

AwaitingPaymentIcon.propTypes = {
  width: PropTypes.number,
  color: PropTypes.string,
};

export default AwaitingPaymentIcon;
