import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'constants/colors';

interface BlockedIconProps {
  width?: number;
  color?: string;
}

function BlockedIcon({ width = 19, color = COLORS.WHITE }: BlockedIconProps) {
  return (
    <svg width={width} height={width + 1} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.98 12.272c.207.056.33.268.267.472a9.167 9.167 0 11-.075-5.715.375.375 0 01-.253.479.401.401 0 01-.49-.259 8.393 8.393 0 10.07 5.295.401.401 0 01.482-.272z"
        fill={color}
      />
      <rect x={4.917} y={9.542} width={9.167} height={0.917} rx={0.458} fill={color} />
    </svg>
  );
}

BlockedIcon.propTypes = {
  width: PropTypes.number,
  color: PropTypes.string,
};

export default BlockedIcon;
