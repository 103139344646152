import React from 'react';
import { OpenNewWindow } from '@umai/common';
import { COLORS } from 'constants/colors';
import { isPhoneNumberValid } from 'utils/phone-number';
import WhatsAppIcon from 'components/icons/WhatsappIcon';
import styles from './WhatsAppButton.module.scss';

type Props = {
  phone: string;
  size?: number;
};

export default function WhatsAppButton({ phone, size = 19 }: Props) {
  if (isPhoneNumberValid(phone)) {
    return (
      <button
        type="button"
        onClick={() => {
          const whatsAppWindow = new OpenNewWindow(`https://wa.me/${phone}`, 'WhatsApp') as any;
          whatsAppWindow();
        }}
        className={styles.whatsAppButton}
        title="Click to initiate chat with guest using WhatsApp"
        aria-label="WhatsApp chat link"
      >
        <WhatsAppIcon width={size} height={size} color={COLORS.GREEN_PRIMARY} />
      </button>
    );
  }

  return null;
}
