import type { AppDispatch } from 'redux-store';
import debounce from 'lodash-es/debounce';
import guestsCrmApis from './apis';
import { getErrorMessage } from 'utils/errors';
import { showErrorMessage } from 'utils/alerts';
import * as ActionTypes from './action-types';

const setMasterGuestsLoading = ({ isLoading = false }) => ({
  type: ActionTypes.SET_MASTER_GUESTS_LOADING,
  payload: isLoading,
});

export const setLoadingMoreGuests = () => ({
  type: ActionTypes.SET_LOADING_MORE_GUESTS,
  payload: { isLoadingMore: true },
});

const searchMasterGuestsListDebounce = debounce(async (params, dispatch, { signal }) => {
  try {
    const response = await guestsCrmApis.fetchGuestsCrm(params, { signal });
    dispatch({
      type: ActionTypes.SEARCH_MASTER_GUESTS,
      payload: {
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        masterGuests: response.masterGuests || [],
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        totalMasterGuestsCount: response.totalGuestCount,
      },
    });
  } catch (error) {
    if (guestsCrmApis.isCancel(error)) return;
    showErrorMessage(getErrorMessage(error));
  } finally {
    setMasterGuestsLoading({ isLoading: false });
  }
}, 700);

const fetchMoreMasterGuestListDebounce = debounce(async (params, dispatch) => {
  try {
    // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
    const response = await guestsCrmApis.fetchGuestsCrm(params);
    dispatch({
      type: ActionTypes.FETCH_MORE_MASTER_GUESTS,
      payload: {
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        masterGuests: response.masterGuests || [],
      },
    });
  } catch (error) {
    showErrorMessage(getErrorMessage(error));
  } finally {
    setMasterGuestsLoading({ isLoading: false });
  }
}, 500);

export const searchMasterGuests = (params: any) => (dispatch: AppDispatch) => {
  const controller = new AbortController();
  searchMasterGuestsListDebounce(params, dispatch, controller);
  return controller;
};

export const fetchMoreGuests = (params: any) => (dispatch: AppDispatch) => {
  fetchMoreMasterGuestListDebounce(params, dispatch);
};

export const createMasterGuest = (data: any) => async (dispatch: AppDispatch) => {
  try {
    // @ts-expect-error TS(2339): Property 'masterGuest' does not exist on type 'unk... Remove this comment to see the full error message
    const { masterGuest } = await guestsCrmApis.createGuest(data);
    dispatch({
      type: ActionTypes.CREATE_NEW_MASTER_GUEST,
      payload: masterGuest.id,
    });
  } catch (error) {
    showErrorMessage(getErrorMessage(error));
  }
};

export const setMasterGuestId = (id: any) => ({
  type: ActionTypes.SET_MASTER_GUEST_ID,
  payload: id,
});

export const updateMasterGuestsSearchText =
  (searchText: string) => async (dispatch: AppDispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_MASTER_GUESTS_SEARCH_TEXT,
      payload: { searchText },
    });
  };

export const resetSelectedGuestId = () => ({
  type: ActionTypes.RESET_SELECTED_GUEST_ID,
});

export const updateGuestCrm = (guest: any) => ({
  type: ActionTypes.UPDATE_GUEST_CRM,
  payload: guest,
});
