export const NOTIFICATIONS_QUERY_KEY = 'notifications';
export const NOTIFICATIONS_UNREAD_QUERY_KEY = 'unread-notifications-count';
export const NOTIFICATIONS_SETTINGS_QUERY_KEY = 'notification-settings';

export const NOTIFICATION_DATE_FORMAT = 'DD MMMM YYYY';

export const PER_PAGE = 20;

// `key` is the key in the notification settings object we receive from the API
// `enabled` is the key in the venue object that determines if the setting should be shown
export const NOTIFICATION_SETTINGS = [
  {
    groupTitle: 'Reservation push notifications',
    settings: [
      {
        label: 'New reservations',
        key: 'isNewReservationEnabled',
        enabled: 'isGuestcenterEnabled',
      },
      {
        label: 'Edit reservations',
        key: 'isEditReservationEnabled',
        enabled: 'isGuestcenterEnabled',
      },
      {
        label: 'Late reservations',
        key: 'isLateReservationEnabled',
        enabled: 'isGuestcenterEnabled',
      },
      {
        label: 'Cancelled reservations',
        key: 'isCanceledReservationEnabled',
        enabled: 'isGuestcenterEnabled',
      },
      {
        label: 'Failed reservations',
        key: 'isFailedReservationEnabled',
        enabled: 'isGuestcenterEnabled',
      },
    ],
  },
  {
    groupTitle: 'Waitlist push notifications',
    settings: [
      {
        label: 'New waitlist',
        key: 'isNewNotifylistEnabled',
        enabled: 'isGuestcenterEnabled',
      },
    ],
  },
  {
    groupTitle: 'Queue push notifications',
    settings: [
      {
        label: 'Queue confirmed',
        key: 'isQueueConfirmedEnabled',
        enabled: 'isOfflineWaitlistEnabled',
      },
      {
        label: 'Queue rejected',
        key: 'isQueueRejectedEnabled',
        enabled: 'isOfflineWaitlistEnabled',
      },
    ],
  },
  {
    groupTitle: 'Orders push notifications',
    settings: [
      {
        label: 'Delivery orders',
        key: 'isNewDeliveryOrderEnabled',
        enabled: 'isDeliveryProgramEnabled',
      },
      {
        label: 'Pickup orders',
        key: 'isNewTakeawayOrderEnabled',
        enabled: 'isTakeawayProgramEnabled',
      },
    ],
  },
  {
    groupTitle: 'Gift card push notifications',
    settings: [
      {
        label: 'New gift card purchase',
        key: 'isNewGiftCardSoldEnabled',
        enabled: 'isGiftCardProgramEnabled',
      },
      {
        label: 'Failed payment gift card',
        key: 'isNewGiftCardPaymentFailedEnabled',
        enabled: 'isGiftCardProgramEnabled',
      },
    ],
  },
];
