import React, { useContext } from 'react';
import classnames from 'classnames';
import { useViewPortContext } from '@umai/common';
import { AppContext } from 'contexts/app';
import { isTodayWithinSixToSix, formatServerDate } from 'utils/date-and-time';
import { getStatusName } from 'modules/IncomingCall/services';
import type { IncomingCall } from 'modules/IncomingCall/types';
import IncomingCallIcon from 'components/icons/IncomingCallIcon';
import MinimizeIcon from 'components/icons/MinimizeIcon';
import CloseIcon from 'components/icons/CloseIcon';
import styles from './CallPopup.module.scss';

type Props = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  slotStartTime?: string;
  callDateMillis: IncomingCall['callDateMillis'];
  callCurrentStatus: IncomingCall['callCurrentStatus'];
};

function Header({ setOpen, slotStartTime, callDateMillis, callCurrentStatus }: Props) {
  const { setCallPopup } = useContext(AppContext);
  const { mobile } = useViewPortContext();
  const isReservationToday = slotStartTime && isTodayWithinSixToSix(slotStartTime);
  const isMissed = callCurrentStatus === 'ABANDON';

  const handleMinimize = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setCallPopup({ isVisible: false, data: null });
  };

  return (
    <header className={classnames(styles.header, { [styles.isMissed]: isMissed })}>
      <div className={styles.title} aria-label="call header actions">
        <IncomingCallIcon width={18.33} height={18.33} />
        <h4 className={styles.text}>
          {getStatusName(callCurrentStatus)} call
          {callDateMillis ? (
            <span className={styles.time}>{formatServerDate(callDateMillis, 'LT')}</span>
          ) : null}
        </h4>
        {isReservationToday ? (
          <h6 className={classnames(styles.today, { [styles.isMissed]: isMissed })}>
            {mobile ? 'Rsv Today' : 'Reservation today'}
          </h6>
        ) : null}
      </div>
      <div className={styles.actions} aria-label="call header actions">
        <button
          type="button"
          className={styles.action}
          onClick={handleMinimize}
          aria-label="Minimize incoming call"
        >
          <MinimizeIcon />
        </button>
        <button
          type="button"
          className={styles.action}
          onClick={handleClose}
          aria-label="Close incoming call"
        >
          <CloseIcon width={14} height={15} />
        </button>
      </div>
    </header>
  );
}

export default Header;
