import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'constants/colors';

interface PaidIconProps {
  width?: number;
  color?: string;
}

function PaidIcon({ width = 20, color = COLORS.WHITE }: PaidIconProps) {
  return (
    <svg width={width} fill="none" viewBox="0 0 20 20">
      <path
        d="M17.534 17.724a1.353 1.353 0 01-1.355 1.352H7.618v-3.59a.46.46 0 00-.421-.465.452.452 0 00-.481.45v3.605H5.279a1.353 1.353 0 01-1.352-1.352v-.5h.972a.46.46 0 00.465-.421.451.451 0 00-.45-.481h-.991v-3.601h.976a.461.461 0 00.465-.421.451.451 0 00-.45-.481h-.991V8.214h.976a.461.461 0 00.465-.421.45.45 0 00-.45-.481h-.991V3.707h.976a.461.461 0 00.465-.421.45.45 0 00-.45-.481h-.991v-.5A1.352 1.352 0 015.279.956h1.438v12.96a.46.46 0 00.421.465.452.452 0 00.481-.45V.956h8.56a1.352 1.352 0 011.352 1.352v.788a.428.428 0 10.856 0v-.791A2.235 2.235 0 0016.272.056H5.279A2.254 2.254 0 003.021 2.31v.5h-.976a.461.461 0 00-.466.416.45.45 0 00.45.481h.992v3.605h-.976a.461.461 0 00-.466.421.45.45 0 00.45.481h.992v3.606h-.976a.461.461 0 00-.466.42.451.451 0 00.45.481h.992v3.605h-.976a.461.461 0 00-.466.421.451.451 0 00.45.481h.992v.5a2.254 2.254 0 002.258 2.249h10.99a2.235 2.235 0 002.118-2.247v-.551a.428.428 0 10-.856 0l.003.545z"
        fill={color}
      />
      <path
        d="M15.361 15.85v-.894c1.859-.152 2.952-1.145 2.952-2.714 0-1.337-.775-2.138-2.39-2.569l-.562-.139V6.296a1.708 1.708 0 011.661 1.569h1.125a2.745 2.745 0 00-2.786-2.595v-.92h-.888v.931c-1.688.166-2.741 1.152-2.741 2.6a2.545 2.545 0 002.211 2.467l.53.146v3.442a1.831 1.831 0 01-1.841-1.608H11.5c.007 1.476 1.185 2.5 2.973 2.635v.887h.888zm1.806-3.516c0 .967-.655 1.562-1.807 1.622v-3.284c1.251.265 1.807.788 1.807 1.662zm-4.3-4.522a1.57 1.57 0 011.6-1.516v3.045c-1.04-.218-1.597-.741-1.597-1.529h-.003z"
        fill={color}
      />
    </svg>
  );
}

PaidIcon.propTypes = {
  width: PropTypes.number,
  color: PropTypes.string,
};

export default PaidIcon;
