import React, { useContext } from 'react';
import classnames from 'classnames';
import { AppContext } from 'contexts/app';
import { COLORS } from 'constants/colors';
import { useIncomingCallActions } from 'modules/IncomingCall/hooks/useIncomingCallActions';
import type { IncomingCall } from 'modules/IncomingCall/types';
import ReservedIcon from 'components/icons/statuses/ReservedIcon';
import AddReservationIcon from 'components/icons/AddReservationIcon';
import PersonIcon from 'components/icons/PersonIcon';
import EditIcon from 'components/icons/EditIcon';
import CreateGuestIcon from 'components/icons/CreateGuestIcon';
import styles from './CallPopup.module.scss';

type Props = {
  data: IncomingCall;
};

function Footer({ data }: Props) {
  const { setCallPopup } = useContext(AppContext);
  const {
    isFetchingGuest,
    isFetchingReservation,
    handleViewGuest,
    handleCreateGuest,
    handleViewReservation,
    handleAddReservation,
    handleEditReservation,
  } = useIncomingCallActions({
    phone: data.callPhoneNumber,
    guestId: data.guest?.id,
    reservationId: data.upcomingReservation?.id,
    slotStartTime: data.upcomingReservation?.slotStartTime,
    reservationStatus: data.upcomingReservation?.status,
    firstName: data.upcomingReservation?.firstName,
    lastName: data.upcomingReservation?.lastName,
    callback: () => {
      setCallPopup({ isVisible: false, data: null });
    },
  });

  return (
    <footer className={styles.footer}>
      <button
        type="button"
        className={classnames(styles.action, { [styles.active]: !data.upcomingReservation })}
        onClick={handleAddReservation}
        aria-label="Add reservation"
        disabled={isFetchingGuest}
      >
        <AddReservationIcon color={!data.upcomingReservation ? COLORS.WHITE : COLORS.BLACK} />
        <span className={styles.label}>
          {isFetchingGuest ? 'Loading guest...' : 'Add reservation'}
        </span>
      </button>
      {data.upcomingReservation && (
        <button
          type="button"
          className={classnames(styles.action, styles.active)}
          onClick={handleEditReservation}
          aria-label="Edit reservation"
          disabled={isFetchingReservation}
        >
          <EditIcon
            width={20}
            height={20}
            color={isFetchingReservation ? COLORS.BLACK : COLORS.WHITE}
          />
          <span className={styles.label}>
            {isFetchingReservation ? 'Loading data...' : 'Edit reservation'}
          </span>
        </button>
      )}
      {data.upcomingReservation && (
        <button
          type="button"
          className={styles.action}
          onClick={handleViewReservation}
          aria-label="View reservation"
        >
          <ReservedIcon width={16} color={COLORS.BLACK} />
          <span className={styles.label}>View reservation</span>
        </button>
      )}
      {!data.upcomingReservation && !data.guest && (
        <button
          type="button"
          className={styles.action}
          onClick={handleCreateGuest}
          aria-label="Create guest"
        >
          <CreateGuestIcon />
          <span className={styles.label}>Create guest</span>
        </button>
      )}
      {!data.upcomingReservation && data.guest && (
        <button
          type="button"
          className={styles.action}
          onClick={handleViewGuest}
          aria-label="View guest"
        >
          <PersonIcon width={16.67} height={20} title="View Guest" />
          <span className={styles.label}>View guest</span>
        </button>
      )}
    </footer>
  );
}

export default Footer;
