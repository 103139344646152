import { useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { DATE_FORMAT, isMobileDevice, showErrorMessage } from '@umai/common';
import { type Routes, ROUTES } from 'constants/routes';
import { getCurrentDateAndTime } from 'utils/date-and-time';
import { useViewReservationDetails } from 'modules/ReservationsList/hooks/useViewReservationDetails';
import { selectVenueCommunicationEdit } from 'modules/Partner/selectors';
import {
  editReservation,
  resetReservationFunnel,
  setIsReservationWithGuest,
} from 'modules/ReservationFunnel/actions';
import { RESERVATION_FUNNEL_STEPS } from 'modules/ReservationFunnel/constants';
import useFetchReservation from 'modules/IncomingCall/hooks/useFetchReservation';
import useFetchGuest from './useFetchGuest';

type IncomingCallAction = {
  phone: string;
  guestId: number | undefined;
  reservationId: number | undefined;
  slotStartTime: string | undefined;
  reservationStatus: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  from?: Routes;
  callback?: () => void;
};

export function useIncomingCallActions({ callback, ...data }: IncomingCallAction) {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const editCommunicationSettings = useAppSelector(selectVenueCommunicationEdit);
  const viewReservationDetails = useViewReservationDetails();
  const { isFetchingGuest, refetchGuest } = useFetchGuest({
    guestId: data.guestId,
    enabled: false,
  });
  const { isFetchingReservation, refetchReservation } = useFetchReservation({
    reservationId: data.reservationId,
    enabled: false,
  });

  const handleViewGuest = () => {
    if (data.guestId) {
      // Reset the reservation funnel, if opened  IncomingCall popup opened on it. to avoid state conflicts when reopening funnel
      if (location.pathname === ROUTES.RESERVATION_FUNNEL) {
        dispatch(resetReservationFunnel());
      }

      if (callback) {
        callback();
      }

      history.push(
        { pathname: '/guests', search: `?guestId=${data.guestId}` },
        data.from ? { from: data.from } : undefined
      );
    }
  };

  const handleCreateGuest = () => {
    // Reset the reservation funnel, if IncomingCall popup opened on it, to avoid state conflicts when creating new guest and reopening funnel
    if (location.pathname === ROUTES.RESERVATION_FUNNEL) {
      dispatch(resetReservationFunnel());
    }

    if (callback) {
      callback();
    }

    history.push(
      { pathname: '/guests', search: `?add-guest=${data.phone}` },
      data.from ? { from: data.from } : undefined
    );
  };

  const handleViewReservation = () => {
    if (data.reservationId && data.slotStartTime && data.reservationStatus) {
      // Reset the reservation funnel, if IncomingCall popup opened on it. to avoid state conflicts when reopening funnel
      if (location.pathname === ROUTES.RESERVATION_FUNNEL) {
        dispatch(resetReservationFunnel());
      }

      viewReservationDetails({
        dateTime: data.slotStartTime,
        id: data.reservationId,
        status: data.reservationStatus,
      });

      if (callback) {
        callback();
      }
    }
  };

  const handleAddReservation = async () => {
    const { data: primaryGuest } = await refetchGuest();
    dispatch(resetReservationFunnel());
    // NOTE: Using editReservation with isEdit false here because,
    //       it provides a complete setup for opening funnel with the required data to pre populate like pax, guest, fill input in guest step etc...
    //       Would be nice in future we separate out this function for adding a reservation.
    dispatch(
      editReservation({
        reservation: {
          walkin: false,
          ...(data.guestId && primaryGuest && { primaryGuest }),
          ...(!data.guestId && {
            phone: data.phone,
            firstName: data.firstName,
            lastName: data.lastName,
          }),
          guestCount: isMobileDevice ? null : 2,
        },
        funnelDate: getCurrentDateAndTime().format(DATE_FORMAT),
        step: RESERVATION_FUNNEL_STEPS.FIRST_STEP,
        isTimeEdit: false,
        isEdit: false,
        isCustomTime: false,
        hasDateUpdated: true,
      })
    );

    if (data.guestId) {
      dispatch(setIsReservationWithGuest());
    }

    if (callback) {
      callback();
    }

    history.push(ROUTES.RESERVATION_FUNNEL, data.from ? { from: data.from } : undefined);
  };

  const handleEditReservation = async () => {
    try {
      const { data: reservation } = await refetchReservation({ throwOnError: true });
      if (reservation) {
        dispatch(resetReservationFunnel());
        dispatch(
          editReservation({
            reservation,
            step: RESERVATION_FUNNEL_STEPS.SELECT_TABLES,
            isTimeEdit: false,
            communicationSettings: editCommunicationSettings,
          })
        );

        if (callback) {
          callback();
        }

        history.push(ROUTES.RESERVATION_FUNNEL, data.from ? { from: data.from } : undefined);
      }
    } catch (error) {
      showErrorMessage('Un expected error fetching the upcoming reservation');
    }
  };

  return {
    isFetchingGuest,
    isFetchingReservation,
    handleViewGuest,
    handleCreateGuest,
    handleViewReservation,
    handleAddReservation,
    handleEditReservation,
  };
}
