import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'constants/colors';

interface LateIconProps {
  width?: number;
  color?: string;
}

function LateIcon({ width = 20, color = COLORS.WHITE }: LateIconProps) {
  return (
    <svg width={width} fill="none" viewBox="0 0 20 20">
      <g clipPath="url(#prefix__clip0)">
        <path
          d="M10.15 20.15a10 10 0 1110-10 10.01 10.01 0 01-10 10v0zm0-19.332a9.332 9.332 0 109.332 9.332A9.343 9.343 0 0010.15.818v0z"
          fill={color}
          stroke={color}
          strokeWidth={0.3}
        />
        <path
          d="M10.15 3.538a.326.326 0 00-.327.327v6.285H4.925a.327.327 0 100 .654h5.225a.328.328 0 00.327-.327V3.865a.325.325 0 00-.327-.327"
          fill={color}
        />
        <path
          d="M10.15 3.538a.326.326 0 00-.327.327v6.285H4.925a.327.327 0 100 .654h5.225a.328.328 0 00.327-.327V3.865a.325.325 0 00-.327-.327"
          stroke={color}
          strokeWidth={0.2}
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill={color} d="M0 0h20.3v20.3H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

LateIcon.propTypes = {
  width: PropTypes.number,
  color: PropTypes.string,
};

export default LateIcon;
