import React, { lazy, Suspense } from 'react';
import { useAppSelector } from 'hooks/redux';
import {
  selectIsIncomingCallEnabled,
  selectIsIncomingCallSubscribed,
} from 'modules/Partner/selectors';
import IncomingCallSubscription from './components/Subscription';
import Loader from './components/Loader';

const IncomingCall = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "umai-incoming-call" */ './IncomingCall')
);

function IncomingCallModule() {
  const isSubscribed = useAppSelector(selectIsIncomingCallSubscribed);
  const isEnabled = useAppSelector(selectIsIncomingCallEnabled);

  return isSubscribed && isEnabled ? (
    <Suspense fallback={<Loader />}>
      <IncomingCall />
    </Suspense>
  ) : (
    <IncomingCallSubscription />
  );
}

export default IncomingCallModule;
