import type { AppDispatch, AppGetState } from 'redux-store';
import moment from 'moment-timezone';
// need to import moment with data just here in order load the timezones
import 'moment-timezone/data/packed/latest.json';
import { normalize, schema } from 'normalizr';
import { batch } from 'react-redux';
import * as ActionTypes from './action-types';
import { DEFAULT_CURRENCY, DEFAULT_LANGUAGE, HELP_CHAT_INTERCOM_ID } from 'constants/app';
import { LOCALE_DATE_FORMAT, TIME_FORMAT_24 } from 'constants/time-and-date';
import partnerApis from './apis';
import venueApis from 'modules/Venue/apis/venue';
import {
  savePartnerSession,
  getPartnerSession,
  isProductionEnv,
  type PartnerSession,
} from 'utils/session';
import { getErrorMessage } from 'utils/errors';
import { showErrorMessage } from 'utils/alerts';
import { setIntercom } from 'utils/intercom';
import { updateBeamer } from 'utils/beamer';
import {
  getCountryCurrencySymbolByISOCode,
  getCountryISOCodeFromVenue,
  getVenueCountryCallingCode,
  getFirstBrowserLanguage,
  setMomentLocale,
  getVenueCurrencyAndCountryCode,
} from 'utils/locale';
import { setVenuesColor } from 'modules/Venue/services';
import { getFirebaseToken } from 'services/firebase-messaging';
import { setProductSubscription } from 'services/subscription';
import socketMessage from 'services/socket-message';
import { changeDate } from 'modules/Footer/actions';
import { getInitialDate } from 'modules/Footer/services';
import { setupIncomingCallInAppNotification } from 'modules/IncomingCall/services';
import type { Venue, VenueId } from 'modules/Venue/types';

const venuesSchema = new schema.Entity('venues');

const normalizeVenues = (
  venuesData: Venue[]
): { venues: { [key: VenueId]: Venue }; venueIds: VenueId[] } => {
  const {
    entities: { venues = {} },
    result: venueIds = [],
  } = normalize(venuesData, [venuesSchema]);

  return { venues, venueIds };
};

const setVenueCurrency = (venue: Venue) => (dispatch: AppDispatch) => {
  try {
    const { country } = venue || {};
    dispatch({
      type: ActionTypes.SET_VENUE_INFO,
      payload: {
        currency: `${
          getCountryCurrencySymbolByISOCode(country?.countryIsoCode) || DEFAULT_CURRENCY
        } `,
        currencyFormatter: getVenueCurrencyAndCountryCode(country),
        countryIsoCode: getCountryISOCodeFromVenue(country),
        callingCode: getVenueCountryCallingCode(country),
      },
    });
  } catch (error) {
    showErrorMessage(getErrorMessage(error));
  }
};

const setVenueSubscription = (venue: any) => (dispatch: AppDispatch) => {
  try {
    dispatch({ type: ActionTypes.SET_VENUE_SUBSCRIPTION, payload: setProductSubscription(venue) });
  } catch (error) {
    showErrorMessage(getErrorMessage(error));
  }
};

const setVenueTimeZoneAndLocale =
  (venue: Venue) => (dispatch: AppDispatch, getState: AppGetState) => {
    const { defaultLanguage, timeZone = '', timeFormat = '' } = venue || {};
    moment.tz.setDefault(timeZone);
    setMomentLocale(defaultLanguage?.language || getFirstBrowserLanguage() || DEFAULT_LANGUAGE);
    moment.updateLocale('en', timeFormat === TIME_FORMAT_24 ? LOCALE_DATE_FORMAT.EN_24 : null);
    // After fetching of venue data and changing the timezone in moment-timezone of the venue,
    // we need fire changeDate again to initial date, so moment-timezone reflects to the footerDate of venue
    dispatch(changeDate(moment.utc(getInitialDate(getState().footer.date), false).local(false)));
  };

const setCurrentVenueLibs = (venue: Venue, currentVenueId: VenueId) => {
  try {
    const { email } = getPartnerSession();
    getFirebaseToken();
    socketMessage({ venue, venueId: currentVenueId });
    setIntercom({ tag: 'shutdown' });
    setIntercom({
      tag: 'boot',
      options: {
        hide_default_launcher: true,
        name: venue?.name?.replace(/\s+/g, '-') || 'Unnamed-venue',
        email,
        user_id: isProductionEnv()
          ? currentVenueId
          : `${currentVenueId}-${process.env.REACT_APP_ENVIRONMENT || 'DEV'}`,
        custom_launcher_selector: `#${HELP_CHAT_INTERCOM_ID}`,
        // @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
        last_request_at: parseInt(new Date().getTime() / 1000, 10),
        // Workaround for solving intercom connection and latency issues for Vietnamese partners
        // https://forum.intercom.com/s/question/0D52G00004jhZo7SAE/why-am-i-having-issues-with-the-intercom-messenger-in-vietnam
        api_base: `https://${process.env.REACT_APP_INTERCOM_APP_ID}.intercom-messenger.com`,
      },
    });

    updateBeamer({
      // ---------------Visitor Information---------------
      venue_email: venue.partner.email,
      venue_id: currentVenueId,
      venue_name: venue.name,
      /**
       * Filter are added to send messages from beamer to a particular segment
       *
       * Currently we have segments for:
       *  1. venue ID
       *  2. country
       *  3. freemium
       *  4. premium
       *  4. loyalty
       *
       * can add more based on request...
       */
      filter: `${currentVenueId};${venue.country.name};${
        venue.isFreemium ? 'freemium' : 'premium'
      };${venue.isLoyaltyProgramEnabled ? 'loyalty' : ''};`,
    });
  } catch (error) {
    showErrorMessage(getErrorMessage(error));
  }
};

const setCurrentVenueData = (venue: Venue) => (dispatch: AppDispatch) => {
  try {
    batch(() => {
      // set current venue in partner reducer
      dispatch({ type: ActionTypes.SET_CURRENT_VENUE, payload: venue });
      dispatch(setVenueSubscription(venue));
      dispatch(setVenueCurrency(venue));
    });
  } catch (error) {
    showErrorMessage(getErrorMessage(error));
  }
};

const setCurrentVenue = (venue: Venue, currentVenueId: VenueId) => (dispatch: AppDispatch) => {
  try {
    if (venue) {
      batch(() => {
        dispatch(setCurrentVenueData(venue));
        dispatch(setVenueTimeZoneAndLocale(venue));
      });
      setCurrentVenueLibs(venue, currentVenueId);
      setupIncomingCallInAppNotification(currentVenueId);
    }
  } catch (error) {
    showErrorMessage(getErrorMessage(error));
  }
};

export const setVisiblePaymentWarning = (isVisible: boolean) => ({
  type: ActionTypes.SET_VISIBLE_PAYMENT_WARNING,
  payload: isVisible,
});

export const getPaymentProfiles = () => async (dispatch: AppDispatch) => {
  try {
    // @ts-expect-error TS(2339): Property 'billingEntities' does not exist on type ... Remove this comment to see the full error message
    const { billingEntities = [] } = await partnerApis.fetchPaymentProfiles();
    const payload = {};

    billingEntities.forEach((profile: any) => {
      const { venueIds, warnings = [], ...restOfProfile } = profile;
      venueIds.forEach((venueId: VenueId) => {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        payload[venueId] = {
          ...restOfProfile,
          // sorting to move the smallest warning text on the top ( asc order ), so that button and svg are not overlapping on desktop mode
          warnings: warnings.sort((a: any, b: any) => a.length - b.length),
          isVisible: true,
        };
      });
    });

    dispatch({
      type: ActionTypes.SET_PAYMENT_PROFILE,
      payload,
    });
  } catch (error) {
    showErrorMessage(getErrorMessage(error));
  }
};

export const getPartnerVenues = () => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: ActionTypes.SET_VENUE_LOADING, payload: true });
    const { venues: venuesData } = await partnerApis.fetchPartnerVenues();
    const { venues, venueIds } = normalizeVenues(setVenuesColor(venuesData));
    // Use current venue id already saved in cookies if the user refreshes the page
    let { currentVenueId }: Partial<PartnerSession> = getPartnerSession();

    if (!currentVenueId && venueIds.length > 0) {
      // Use first venue in the venue list if currentVenueId is not saved in the cookies in the case of new login
      const [venueId] = venueIds;

      currentVenueId = venueId;

      savePartnerSession({
        currentVenueId,
        currentVenueName: venues[currentVenueId].name,
      });
    }

    batch(() => {
      if (currentVenueId) {
        dispatch({ type: ActionTypes.GET_PARTNER_VENUES, payload: { venues, venueIds } });
        dispatch(setCurrentVenue(venues[currentVenueId], currentVenueId));
        dispatch(getPaymentProfiles());
      }
    });
  } catch (error) {
    showErrorMessage(getErrorMessage(error));
  } finally {
    dispatch({ type: ActionTypes.SET_VENUE_LOADING, payload: false });
  }
};

export const changeVenue =
  (currentVenueId: VenueId) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const {
        partner: { venues },
      } = getState();
      savePartnerSession({ currentVenueId, currentVenueName: venues[currentVenueId].name });
      dispatch(setCurrentVenue(venues[currentVenueId], currentVenueId));
    } catch (error) {
      showErrorMessage(getErrorMessage(error));
    }
  };

export const getVenueDetails =
  (venueId: VenueId) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const {
        partner: { venues },
      } = getState();
      // @ts-expect-error TS(2339): Property 'venue' does not exist on type 'unknown'.
      const { venue = {} } = await venueApis.getVenue(venueId);
      const currentVenue = { ...venues[venueId], ...venue };

      batch(() => {
        dispatch(setCurrentVenueData(currentVenue));
        dispatch({
          type: ActionTypes.SET_PARTNER_VENUE,
          payload: { ...venues, [venueId]: currentVenue },
        });
      });
    } catch (err) {
      showErrorMessage(getErrorMessage(err));
    }
  };

export const getVenueLoyaltyTiers = (venueId: any) => async (dispatch: AppDispatch) => {
  try {
    // @ts-expect-error TS(2339): Property 'loyaltyTiers' does not exist on type 'un... Remove this comment to see the full error message
    const { loyaltyTiers } = await venueApis.getVenueLoyaltyTiers(venueId);
    dispatch({
      type: ActionTypes.GET_VENUE_LOYALTY_TIERS,
      payload: loyaltyTiers,
    });
  } catch (error) {
    showErrorMessage(getErrorMessage(error));
  }
};
