import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'constants/colors';

interface ClearedIconProps {
  width?: number;
  color?: string;
}

function ClearedIcon({ width = 20, color = COLORS.WHITE }: ClearedIconProps) {
  return (
    <svg width={width} fill="none" viewBox="0 0 20 23">
      <g clipPath="url(#prefix__clip0)" fill={color}>
        <path d="M12.458 22.144H6.712a2.51 2.51 0 01-2.514-2.5v-3.93h-.359v-.357a1.793 1.793 0 011.8-1.786h2.869V1.428a1.437 1.437 0 012.873 0v12.144h2.873a1.793 1.793 0 011.8 1.786v.357h-.359v3.929a1.793 1.793 0 001.8 1.786v.714h-3.241a3.916 3.916 0 01-1.8-.422v.422h.004zm-5.028-6.43v3.929a1.793 1.793 0 001.8 1.786h2.203a3.668 3.668 0 01-.411-1.787h.717a1.568 1.568 0 00.738 1.258 3.084 3.084 0 001.777.524h1.473a2.474 2.474 0 01-.755-1.784v-3.926h-1.078v1.429h-.718v-1.429H7.43zm-2.514 0v3.929a1.793 1.793 0 001.8 1.786h.756a2.48 2.48 0 01-.756-1.786v-3.929h-1.8zm.723-1.428a1.08 1.08 0 00-1.02.714h10.649a1.077 1.077 0 00-1.014-.714H5.639zM9.226 12.5v1.072h1.436V12.5H9.226zM9.944.714a.717.717 0 00-.719.714v10.357h1.437V1.428a.717.717 0 00-.718-.714z" />
        <path d="M13.212 18.551h.718v-.718h-.718v.718zm4.992 3.592h1.8v-.718h-1.8v.718zm-15.69-3.569a1.077 1.077 0 11.002-2.154 1.077 1.077 0 01-.002 2.154zm0-1.437a.359.359 0 100 .717.359.359 0 000-.717zm-1.078-3.915a1.437 1.437 0 110-2.874 1.437 1.437 0 010 2.874zm0-2.155a.718.718 0 10.002 1.436.718.718 0 00-.002-1.436z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill={color} d="M0 0h20v22.144H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

ClearedIcon.propTypes = {
  width: PropTypes.number,
  color: PropTypes.string,
};

export default ClearedIcon;
