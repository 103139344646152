import * as ActionTypes from 'modules/QueueList/action-types';
import {
  queuelistSlice,
  getQueueItemAndListKey,
  removeQueueItemFromList,
} from 'modules/QueueList/slice';
import { ACTIVE_QUEUE_KEY, REMOVED_QUEUE_KEY } from 'modules/QueueList/constants';
import { normalize, schema } from 'normalizr';

const queueSchema = new schema.Entity('queue');

const normalizeQueues = (queues: any) => {
  const normalized = normalize(queues, [queueSchema]);
  return normalized.entities;
};

const INITIAL_STATE = {
  [ACTIVE_QUEUE_KEY]: {},
  [REMOVED_QUEUE_KEY]: {},
  isQueueLoading: false,
  selectedQueueId: null,
  searchText: '',
  errorMessage: null,
  activities: [],
  activitiesLoading: false,
  cacheKey: null,
};

// eslint-disable-next-line default-param-last
const queueReducer = (state = INITIAL_STATE, { type, payload }: any) => {
  switch (type) {
    case ActionTypes.GET_QUEUE_ITEMS: {
      const { queueResponse, cacheKey } = payload;
      return {
        ...state,
        [ACTIVE_QUEUE_KEY]: normalizeQueues(queueResponse.active || []),
        [REMOVED_QUEUE_KEY]: normalizeQueues(queueResponse.removed || []),
        cacheKey,
      };
    }
    case ActionTypes.RESET_QUEUE_CACHE:
      return {
        ...state,
        cacheKey: null,
      };
    case ActionTypes.SET_QUEUE_LIST_LOADING:
      return {
        ...state,
        ...payload,
      };
    case ActionTypes.VIEW_QUEUE_RESERVATION:
      return {
        ...state,
        selectedQueueId: payload.queueId,
      };
    case ActionTypes.UPDATE_QUEUE_ITEM: {
      const { updatedQueueItem, footerDate } = payload;
      let { queueItem: currentQueueItem } = getQueueItemAndListKey({
        queueId: updatedQueueItem.id,
        queueLists: {
          [ACTIVE_QUEUE_KEY]: state[ACTIVE_QUEUE_KEY],
          [REMOVED_QUEUE_KEY]: state[REMOVED_QUEUE_KEY],
        },
      });
      if (!currentQueueItem) return state;
      currentQueueItem = { ...currentQueueItem, ...updatedQueueItem };

      return {
        ...state,
        ...(queuelistSlice({
          queueId: currentQueueItem.id,
          currentQueueItem,
          footerDate,
          queueLists: {
            [ACTIVE_QUEUE_KEY]: state[ACTIVE_QUEUE_KEY],
            [REMOVED_QUEUE_KEY]: state[REMOVED_QUEUE_KEY],
          },
        }) || {}),
      };
    }
    case ActionTypes.UPDATE_QUEUE_FROM_SOCKET: {
      const { queueId, currentQueueItem, footerDate } = payload;

      return {
        ...state,
        ...(queuelistSlice({
          queueId,
          currentQueueItem,
          footerDate,
          queueLists: {
            [ACTIVE_QUEUE_KEY]: state[ACTIVE_QUEUE_KEY],
            [REMOVED_QUEUE_KEY]: state[REMOVED_QUEUE_KEY],
          },
        }) || {}),
      };
    }
    case ActionTypes.REMOVE_FROM_QUEUE: {
      const { queueId } = payload;
      // Remove any queue that is converted to reservation from the reducer
      // Only active queueitem can be converted to reservation
      return {
        ...state,
        ...removeQueueItemFromList({
          queueId,
          listKey: ACTIVE_QUEUE_KEY,
          queueLists: {
            [ACTIVE_QUEUE_KEY]: state[ACTIVE_QUEUE_KEY],
            [REMOVED_QUEUE_KEY]: state[REMOVED_QUEUE_KEY],
          },
        }),
      };
    }
    case ActionTypes.SET_QUEUE_ERROR:
      return {
        ...state,
        errorMessage: payload.error,
      };
    case ActionTypes.UPDATE_QUEUE_SEARCH_TEXT:
      return {
        ...state,
        searchText: payload.queueSearchText,
      };
    case ActionTypes.GET_QUEUE_ACTIVITIES: {
      return { ...state, activities: payload };
    }
    case ActionTypes.SET_QUEUE_ACTIVITIES_LOADING: {
      return { ...state, activitiesLoading: payload };
    }

    default:
      return { ...state };
  }
};

export default queueReducer;
