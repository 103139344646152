import { API_ENDPOINT_PARTNER_V2 } from 'constants/app';
import HttpRequest from 'utils/http-request';
import type {
  NotificationSettingsKey,
  NotificationSettingsResponse,
  NotificationsResponse,
  NotificationType,
} from './types';

class NotificationCenterAPI extends HttpRequest {
  constructor() {
    super(`${API_ENDPOINT_PARTNER_V2}/notification_center`);
  }

  fetchNotifications({
    page,
    filters,
    perPage,
  }: {
    page?: number;
    filters: NotificationType[];
    perPage?: number;
  }) {
    return this.fetch<NotificationsResponse>('', {
      perPage,
      page,
      filters,
    });
  }

  markAllNotificationsAsRead() {
    return this.create(`/mark_as_read`);
  }

  markNotificationAsRead(notificationId: number) {
    return this.create(`/mark_as_read?notification_ids=${notificationId}`);
  }

  markNotificationAsUnread(notificationId: number) {
    return this.create(`/mark_as_unread?notification_ids=${notificationId}`);
  }

  clearAllNotifications() {
    return this.create(`/clear_notifications`);
  }

  clearNotification(notificationId: number) {
    return this.create(`/clear_notifications?notification_ids=${notificationId}`);
  }

  fetchSettings(token: string) {
    return this.fetch<NotificationSettingsResponse>(`/settings`, {
      token,
    });
  }

  updateSettings(token: string, updated: Record<NotificationSettingsKey, boolean>) {
    return this.update(
      `/update_settings`,
      {
        notificationSetting: updated,
      },
      { params: { token } }
    );
  }
}

export default new NotificationCenterAPI();
