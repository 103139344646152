/* eslint-disable camelcase, no-console */
import { useEffect } from 'react';
import { useAppDispatch } from 'hooks/redux';
import { updateReservationFromSocket } from 'modules/ReservationsList/actions';
import { MESSAGE_EVENTS } from 'constants/socket';
import reservationApis from 'modules/ReservationsList/apis';

const useSocketReservationList = ({ venueId, footerDate }: any) => {
  const dispatch = useAppDispatch();

  const reservationListUpdate = async ({ detail = {} }) => {
    try {
      // @ts-expect-error TS(2339): Property 'params' does not exist on type '{}'.
      if (detail?.params) {
        // @ts-expect-error TS(2339): Property 'params' does not exist on type '{}'.
        const { params } = detail;
        console.debug('RESERVATION LIST: socket update: ', params, params?.date);
        if (params?.reservation_id) {
          let reservation = params?.reservation;
          // for a custom event (customSocketEventForReservation) we are passing reservation in the params
          // to avoid api hit on reservationApis.getReservation
          if (!reservation?.id) {
            const response = await reservationApis.getReservation({
              id: params.reservation_id,
              venueId,
            });
            reservation = response.reservation;
          }
          dispatch(
            updateReservationFromSocket({
              footerDate,
              reservationId: +params.reservation_id,
              currentStatus: params.reservation_status,
              currentReservation: reservation,
            })
          );
        }
      }
    } catch (error) {
      console.error('Error in update reservation list from socket', error);
    }
  };

  useEffect(() => {
    if (venueId) {
      // @ts-expect-error TS(2769): No overload matches this call.
      document.addEventListener(MESSAGE_EVENTS.reservation, reservationListUpdate, false);
    }
    return () => {
      // @ts-expect-error TS(2769): No overload matches this call.
      document.removeEventListener(MESSAGE_EVENTS.reservation, reservationListUpdate, false);
    };
  }, [venueId, footerDate]);
};

export default useSocketReservationList;
