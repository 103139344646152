import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'constants/colors';

interface HeadToVenueIconProps {
  width?: number;
  color?: string;
}

function HeadToVenueIcon({ width = 30, color = COLORS.WHITE }: HeadToVenueIconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height="30" viewBox="0 0 30 30">
      <g id="Head_to_venue_icon" data-name="Head to venue" transform="translate(-0.9 -0.9)">
        <path
          id="Path_958"
          data-name="Path 958"
          d="M30.292,10.807a.7.7,0,0,0-.708.693v8.217a3.192,3.192,0,0,1-3.234,3.132H16.038a.692.692,0,0,0-.486.195L9.455,28.7V23.536a.695.695,0,0,0-.708-.687H5.65a3.192,3.192,0,0,1-3.234-3.138V5.514A3.192,3.192,0,0,1,5.65,2.374h14.7A.687.687,0,1,0,20.35,1H5.65A4.587,4.587,0,0,0,1,5.514V19.705a4.582,4.582,0,0,0,4.65,4.514H8.032v6.1a.691.691,0,0,0,.429.632A.8.8,0,0,0,8.74,31a.734.734,0,0,0,.493-.194l7.089-6.59H26.35A4.582,4.582,0,0,0,31,19.7V11.5a.7.7,0,0,0-.708-.687"
          fill={color}
        />
        <path
          id="Path_959"
          data-name="Path 959"
          d="M30.292,10.807a.7.7,0,0,0-.708.693v8.217a3.192,3.192,0,0,1-3.234,3.132H16.038a.692.692,0,0,0-.486.195L9.455,28.7V23.536a.695.695,0,0,0-.708-.687H5.65a3.192,3.192,0,0,1-3.234-3.138V5.514A3.192,3.192,0,0,1,5.65,2.374h14.7A.687.687,0,1,0,20.35,1H5.65A4.587,4.587,0,0,0,1,5.514V19.705a4.582,4.582,0,0,0,4.65,4.514H8.032v6.1a.691.691,0,0,0,.429.632A.8.8,0,0,0,8.74,31a.734.734,0,0,0,.493-.194l7.089-6.59H26.35A4.582,4.582,0,0,0,31,19.7V11.5a.7.7,0,0,0-.708-.687"
          fill="none"
          stroke={color}
          strokeWidth="0.2"
        />
        <path
          id="Path_960"
          data-name="Path 960"
          d="M24.85,8.2A1.35,1.35,0,1,0,23.5,6.85,1.35,1.35,0,0,0,24.85,8.2Z"
          transform="translate(0.5)"
          fill={color}
        />
        <path
          id="Path_961"
          data-name="Path 961"
          d="M29.35,8.2A1.35,1.35,0,1,0,28,6.85,1.35,1.35,0,0,0,29.35,8.2Z"
          transform="translate(0.3)"
          fill={color}
        />
        <path
          id="Path_962"
          data-name="Path 962"
          d="M15.488,7.412a1.729,1.729,0,1,1,.664-.122A1.729,1.729,0,0,1,15.488,7.412Zm0-2.653a.962.962,0,1,0,.683.272.962.962,0,0,0-.683-.272Z"
          fill={color}
        />
        <path
          id="Path_963"
          data-name="Path 963"
          d="M17.344,21.25a1.077,1.077,0,0,1-1.008-.686l-1.379-3.691-1.379,3.692a1.074,1.074,0,0,1-1.007.685,1.124,1.124,0,0,1-.366-.062,1.054,1.054,0,0,1-.6-.539,1.008,1.008,0,0,1-.037-.8l1.787-4.78V13.454a1.052,1.052,0,0,1-.273-.185L11.907,12.13a1.017,1.017,0,0,1-.074-1.389l1.959-2.184.006.005a1.758,1.758,0,0,1,2.527-.088h.01a.027.027,0,0,1,.012.016l1.424,1.392h2.2a1.041,1.041,0,1,1,0,2.081H17.3a1.017,1.017,0,0,1-.553-.225L16.5,11.58,16.053,13.7l2.3,6.154a1.009,1.009,0,0,1-.037.8,1.057,1.057,0,0,1-.61.54,1.12,1.12,0,0,1-.361.061Zm-1.661-6.318-.31.832,1.7,4.542a.293.293,0,0,0,.275.187.28.28,0,0,0,.1-.017.288.288,0,0,0,.167-.151.277.277,0,0,0,.008-.215ZM15.1,8.739a.962.962,0,0,0-.974.947V15.2l-.392,1.046-1.443,3.864a.277.277,0,0,0,.008.215.288.288,0,0,0,.167.15.278.278,0,0,0,.1.017.293.293,0,0,0,.275-.187l2.339-6.254.61-2.918-.618-.395A.371.371,0,0,1,15,10.436a.385.385,0,0,1,.389-.379.4.4,0,0,1,.215.071L17.3,11.2h2.678a.285.285,0,1,0,0-.569H17.432L15.8,9.029a.98.98,0,0,0-.7-.29ZM13.352,10.2l-.915,1.017a.279.279,0,0,0,.021.379l.888.863Z"
          fill={color}
        />
        <path
          id="Path_964"
          data-name="Path 964"
          d="M24.85,8.2A1.35,1.35,0,1,0,23.5,6.85,1.35,1.35,0,0,0,24.85,8.2Z"
          transform="translate(-3.8)"
          fill={color}
        />
      </g>
    </svg>
  );
}

HeadToVenueIcon.propTypes = {
  width: PropTypes.number,
  color: PropTypes.string,
};

export default HeadToVenueIcon;
