import { useEffect } from 'react';

// This hook listens for messages from the service worker and navigates to the path specified in the message
// Used for push notifications
const useServiceWorkerNavigation = () => {
  useEffect(() => {
    navigator.serviceWorker?.addEventListener('message', (event) => {
      const { postMessageType, path } = event.data || {};
      if (postMessageType === 'navigate') {
        window.location.href = path;
      }
    });
  }, []);
};

export default useServiceWorkerNavigation;
