import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'constants/colors';

interface TableReadyIconProps {
  width?: number;
  color?: string;
}

function TableReadyIcon({ width = 30, color = COLORS.WHITE }: TableReadyIconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height="30" viewBox="0 0 30 30">
      <g id="Table_ready_icon" data-name="Table ready" transform="translate(-0.9 -0.9)">
        <path
          id="Path_949"
          data-name="Path 949"
          d="M30.292,10.807a.7.7,0,0,0-.708.693v8.217a3.192,3.192,0,0,1-3.234,3.132H16.038a.692.692,0,0,0-.486.195L9.455,28.7V23.536a.695.695,0,0,0-.708-.687H5.65a3.192,3.192,0,0,1-3.234-3.138V5.514A3.192,3.192,0,0,1,5.65,2.374h14.7A.687.687,0,1,0,20.35,1H5.65A4.587,4.587,0,0,0,1,5.514V19.705a4.582,4.582,0,0,0,4.65,4.514H8.032v6.1a.691.691,0,0,0,.429.632A.8.8,0,0,0,8.74,31a.734.734,0,0,0,.493-.194l7.089-6.59H26.35A4.582,4.582,0,0,0,31,19.7V11.5a.7.7,0,0,0-.708-.687"
          fill={color}
        />
        <path
          id="Path_950"
          data-name="Path 950"
          d="M30.292,10.807a.7.7,0,0,0-.708.693v8.217a3.192,3.192,0,0,1-3.234,3.132H16.038a.692.692,0,0,0-.486.195L9.455,28.7V23.536a.695.695,0,0,0-.708-.687H5.65a3.192,3.192,0,0,1-3.234-3.138V5.514A3.192,3.192,0,0,1,5.65,2.374h14.7A.687.687,0,1,0,20.35,1H5.65A4.587,4.587,0,0,0,1,5.514V19.705a4.582,4.582,0,0,0,4.65,4.514H8.032v6.1a.691.691,0,0,0,.429.632A.8.8,0,0,0,8.74,31a.734.734,0,0,0,.493-.194l7.089-6.59H26.35A4.582,4.582,0,0,0,31,19.7V11.5a.7.7,0,0,0-.708-.687"
          fill="none"
          stroke={color}
          strokeWidth="0.2"
        />
        <path
          id="Path_951"
          data-name="Path 951"
          d="M24.85,8.2A1.35,1.35,0,1,0,23.5,6.85,1.35,1.35,0,0,0,24.85,8.2Z"
          fill={color}
        />
        <path
          id="Path_952"
          data-name="Path 952"
          d="M29.35,8.2A1.35,1.35,0,1,0,28,6.85,1.35,1.35,0,0,0,29.35,8.2Z"
          fill={color}
        />
        <path
          id="Path_953"
          data-name="Path 953"
          d="M9.946,6.336A1.66,1.66,0,0,0,8.286,8V17.1a1.66,1.66,0,0,0,1.66,1.66h9.108a1.66,1.66,0,0,0,1.66-1.66V8a1.66,1.66,0,0,0-1.66-1.66ZM7.443,8a2.5,2.5,0,0,1,2.5-2.5h9.108a2.5,2.5,0,0,1,2.5,2.5V17.1a2.5,2.5,0,0,1-2.5,2.5H9.946a2.5,2.5,0,0,1-2.5-2.5Z"
          fill={color}
        />
        <path
          id="Path_954"
          data-name="Path 954"
          d="M19.7,4.544a.369.369,0,0,1-.369.369H9.664A.369.369,0,0,1,9.3,4.544h0a.369.369,0,0,1,.369-.369h9.672a.369.369,0,0,1,.369.369Z"
          fill={color}
        />
        <path
          id="Path_955"
          data-name="Path 955"
          d="M19.7,20.556a.369.369,0,0,1-.369.369H9.664a.369.369,0,0,1-.369-.369h0a.369.369,0,0,1,.369-.369h9.672a.369.369,0,0,1,.369.369Z"
          fill={color}
        />
        <path
          id="Path_956"
          data-name="Path 956"
          d="M6.494,17.754a.369.369,0,0,1-.369-.369V7.714a.369.369,0,0,1,.369-.369h0a.369.369,0,0,1,.369.369v9.672a.369.369,0,0,1-.369.369Z"
          fill={color}
        />
        <path
          id="Path_957"
          data-name="Path 957"
          d="M22.506,17.754a.369.369,0,0,1-.369-.369V7.714a.369.369,0,0,1,.369-.369h0a.369.369,0,0,1,.369.369v9.672a.369.369,0,0,1-.369.369Z"
          fill={color}
        />
      </g>
    </svg>
  );
}

TableReadyIcon.propTypes = {
  width: PropTypes.number,
  color: PropTypes.string,
};

export default TableReadyIcon;
