import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getErrorMessage, showErrorMessage } from '@umai/common';
import notificationCenterAPI from 'modules/NotificationCenter/api';
import { NOTIFICATIONS_SETTINGS_QUERY_KEY } from '../constants';
import { NotificationSettingsKey, NotificationSettingsResponse } from '../types';

export function useFetchNotificationSettings(token?: string) {
  const { data, isError, isLoading } = useQuery({
    queryKey: [NOTIFICATIONS_SETTINGS_QUERY_KEY],
    queryFn: () => notificationCenterAPI.fetchSettings(token || ''),
    select: ({ notificationSettings }) => notificationSettings,
    // Token is required, no use fetching without it
    enabled: Boolean(token),
  });

  return {
    notificationSettings: data,
    isError,
    isLoading,
  };
}

export function useUpdateNotificationSettings(token?: string) {
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: (updated: Record<NotificationSettingsKey, boolean>) =>
      notificationCenterAPI.updateSettings(token || '', updated),
    onMutate: async (updated: Record<NotificationSettingsKey, boolean>) => {
      // Cancel any outgoing refetches to prevent overwriting the optimistic update
      await queryClient.cancelQueries({ queryKey: [NOTIFICATIONS_SETTINGS_QUERY_KEY] });

      // Snapshot the previous data
      const previousData = queryClient.getQueryData<NotificationSettingsResponse>([
        NOTIFICATIONS_SETTINGS_QUERY_KEY,
      ]);

      // Optimistically update the settings in cache with new values
      if (previousData) {
        queryClient.setQueryData([NOTIFICATIONS_SETTINGS_QUERY_KEY], {
          ...previousData,
          notificationSettings: {
            ...previousData.notificationSettings,
            ...updated,
          },
        });
      }

      // Make snapshot available to onError
      return { previousData };
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [NOTIFICATIONS_SETTINGS_QUERY_KEY] });
    },
    onError: (error, _, context) => {
      showErrorMessage(getErrorMessage(error));
      // Rollback to the snapshot of previous data if the mutation failed
      if (context?.previousData) {
        queryClient.setQueryData([NOTIFICATIONS_SETTINGS_QUERY_KEY], context.previousData);
      }
    },
  });

  return mutate;
}
