import React, { memo } from 'react';
import { useAppSelector } from 'hooks/redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import kebabCase from 'lodash-es/kebabCase';
import { getLocalizationText } from 'services/language';
import { selectCurrentVenueLanguage } from 'modules/Partner/selectors';
import { COLORS } from 'constants/colors';
import FeedbackIcon from 'components/icons/FeedbackIcon';
import { GUEST_FIRST_VISIT } from 'modules/Guest/constants';
import './Tags.styles.scss';

interface TagsProps {
  tags?: {
    id?: string | number;
    name?: string;
    count?: number;
  }[];
  limit?: number;
  hasDots?: boolean;
  className?: string;
  wrapperId?: string;
}

const Tags = ({ tags = [], limit, hasDots = true, className = '', wrapperId = '' }: TagsProps) => {
  const defaultLanguage = useAppSelector(selectCurrentVenueLanguage);
  const tagList = tags || []; // check for null values
  const visibleTags = limit ? tagList.slice(0, limit) : tagList;

  const renderTagBullet = ({ key, tagName, isAutoTag, isVipTag }: any) => {
    const isFirstVisitAutoTag = isAutoTag && tagName === GUEST_FIRST_VISIT;
    return (
      <span
        key={key}
        title={tagName}
        className={classnames('tag-bullet', {
          'auto-tag-first-visit': isFirstVisitAutoTag,
          'auto-tags': isAutoTag && !isFirstVisitAutoTag,
          'vip-tag': isVipTag,
        })}
      >
        {isVipTag && (
          <FeedbackIcon
            width="20"
            height="16"
            fill={COLORS.GOLDEN_YELLOW}
            border={COLORS.GOLDEN_YELLOW}
            title={tagName}
          />
        )}
        {tagName}
      </span>
    );
  };

  const renderHasDots = () => {
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    if (hasDots && tagList.length > limit) {
      const tagNamesAfterLimit = tagList
        .slice(limit, tagList.length)
        .map((t) => t.name)
        .join(', ');

      return (
        <span className="tag-bullet last-tag" key="last-tag" title={tagNamesAfterLimit}>
          &hellip;
        </span>
      );
    }

    return null;
  };

  return (
    <div className={classnames('tags-wrapper', className)} id={wrapperId} aria-label="tag list">
      <div>
        {/* @ts-expect-error TS(2339): Property 'localizationName' does not exist on type... Remove this comment to see the full error message */}
        {visibleTags.map(({ id = '', name, localizationName, count, isAutoTag }, index) => {
          const tagName = getLocalizationText(localizationName, name, defaultLanguage);
          const kebabName = kebabCase(name);
          const key = `${index}-${kebabName}-${id}`;
          const isVipTag = name === 'VIP';
          return count ? (
            <span key={key} className="tag-bullet-count" aria-label={`${count}x ${tagName}`}>
              <span className="count">{count}x</span>
              {renderTagBullet({ key, tagName, isAutoTag, isVipTag })}
            </span>
          ) : (
            renderTagBullet({ key, tagName, isAutoTag, isVipTag })
          );
        })}
        {renderHasDots()}
      </div>
    </div>
  );
};

Tags.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      count: PropTypes.number,
    })
  ),
  limit: PropTypes.number,
  hasDots: PropTypes.bool,
  className: PropTypes.string,
  wrapperId: PropTypes.string,
};

export default memo(Tags);
