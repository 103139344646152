import React, { lazy } from 'react';
import Layout from 'layouts/Layout';
import IncomingCallModule from 'modules/IncomingCall';

const TabletFooter = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "umai-tablet-footer" */
      /* webpackFetchPriority: "high" */
      'modules/Footer/components/TabletFooter'
    )
);

const MobileFooter = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "umai-mobile-footer" */
      /* webpackFetchPriority: "high" */
      'modules/Footer/components/MobileFooter'
    )
);

function IncomingCall() {
  return (
    <Layout
      header={null}
      footer={<TabletFooter showGuestCount={false} />}
      mobileFooter={<MobileFooter hideMobileFilters />}
      className="incoming-call"
    >
      <IncomingCallModule />
    </Layout>
  );
}

export default IncomingCall;
