import React from 'react';
import type { SVGProps } from 'react';
import { COLORS } from 'constants/colors';

const IncomingCallIcon = ({
  width = 20,
  height = 20,
  color = COLORS.WHITE,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 20 20"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <path
      fill={color}
      d="M18.582 17.616c.329-.329.493-.658.082-.988-3.867-4.361-2.221-4.773-5.35-2.133h-.082a.93.93 0 0 1-1.067 0L5.498 7.828a.99.99 0 0 1-.082-1.152C6.814 5.03 7.473 4.7 6.897 4.208L2.865 1.08a3.45 3.45 0 0 0-1.807 3.117c.247 2.88 3.21 6.09 6.09 8.97 3.123 3.127 7.896 8.064 11.433 4.448M10.924 5.102a.413.413 0 0 1 0-.822A4.776 4.776 0 0 1 15.7 9.053a.413.413 0 0 1-.823 0 3.95 3.95 0 0 0-3.95-3.95m-.823-2.386a.413.413 0 0 1 0-.823 7.99 7.99 0 0 1 7.982 7.982.414.414 0 0 1-.822 0 7.164 7.164 0 0 0-7.155-7.158zm9.876 14.483c-.165 1.316-2.305 2.88-4.115 2.8-3.21 0-6.583-3.127-9.546-6.007C2.861 10.453-2.735 4.776 1.544.658A1.72 1.72 0 0 1 4.012.412c5.101 4.114 5.184 3.456 2.386 6.83l6.337 6.253c3.373-2.8 2.716-2.634 6.83 2.387a1.6 1.6 0 0 1 .417 1.318z"
    />
  </svg>
);

export default IncomingCallIcon;
