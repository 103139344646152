import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'constants/colors';

interface MessageIconProps {
  width?: number;
  color?: string;
}

function MessageIcon({ width = 20, color = COLORS.WHITE }: MessageIconProps) {
  return (
    <svg width={width} fill="none" viewBox="0 0 20 20">
      <g clipPath="url(#prefix__clip0)" fill={color}>
        <path d="M19.528 6.538a.465.465 0 00-.472.462v5.478a2.128 2.128 0 01-2.156 2.088h-6.875a.461.461 0 00-.324.13l-4.064 3.772v-3.444a.463.463 0 00-.472-.458H3.1a2.128 2.128 0 01-2.156-2.092V3.009A2.128 2.128 0 013.1.916h9.8a.463.463 0 00.472-.458A.463.463 0 0012.9 0H3.1A3.058 3.058 0 000 3.009v9.461a3.055 3.055 0 003.1 3.009h1.588v4.064A.46.46 0 005.16 20a.49.49 0 00.329-.129l4.726-4.393H16.9a3.055 3.055 0 003.1-3.009V7a.463.463 0 00-.472-.458" />
        <path d="M13.095 3.44a.83.83 0 01-.842.817.83.83 0 01-.843-.817.83.83 0 01.842-.818.83.83 0 01.842.818h.001zm3.453 0a.83.83 0 01-.842.817.83.83 0 01-.843-.817.83.83 0 01.842-.818.83.83 0 01.842.818h.001zm3.452 0a.83.83 0 01-.842.817.83.83 0 01-.843-.817.83.83 0 01.842-.818.83.83 0 01.842.818" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill={color} d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

MessageIcon.propTypes = {
  width: PropTypes.number,
  color: PropTypes.string,
};

export default MessageIcon;
