/* eslint-disable no-console */
const firebaseMessagingSw = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register(`${process.env.PUBLIC_URL}/firebase-messaging-sw.js`)
      .then((registration) => {
        console.debug('Registration successful, scope is:', registration.scope);
      })
      .catch((err) => {
        console.debug('Service worker registration failed, error:', err);
      });
  }
};

export default firebaseMessagingSw;
