import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import humps from 'humps';
import { ROUTES } from 'constants/routes';
import { CHANNELS } from 'constants/socket';
import { updateCurrentUser } from 'modules/Auth/actions';
import { selectCurrentUserId, selectIsAuthenticated } from 'modules/Auth/selectors';
import { selectCurrentVenueId } from 'modules/Partner/selectors';
import type { UserSocketPayload } from 'modules/Auth/types';
import useSocket from 'hooks/useSocket';

const useSocketUser = () => {
  const dispatch = useAppDispatch();
  const { subscribeChannel, unsubscribeChannel } = useSocket<{ params: string }>();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const currentUserId = useAppSelector(selectCurrentUserId);
  const currentVenueId = useAppSelector(selectCurrentVenueId);

  useEffect(() => {
    if (currentUserId && currentVenueId && isAuthenticated) {
      subscribeChannel({
        channel: CHANNELS.userChannel,
        data: { user_id: currentUserId },
        onReceived: (data: { params: string }) => {
          try {
            const user = humps.camelizeKeys<UserSocketPayload>(JSON.parse(data.params));

            // We check if the accessibleVenues have been updated, and if the current user's access to the current venue has been revoked,
            //  then we log out the current user.
            if (
              Array.isArray(user.accessibleVenues) &&
              !user.accessibleVenues.includes(+currentVenueId)
            ) {
              window.location.pathname = ROUTES.LOGOUT;
            }
            dispatch(updateCurrentUser(user));
          } catch (error) {
            console.error('Error parsing JSON:', error);
          }
        },
      });
    }
    return () => {
      if (currentUserId && currentVenueId && isAuthenticated) {
        unsubscribeChannel();
      }
    };
  }, [currentUserId, currentVenueId, isAuthenticated]);
};

export default useSocketUser;
