import React, { SVGProps } from 'react';
import { COLORS } from 'constants/colors';

function InfoIcon({
  height = 14,
  width = 14,
  color = COLORS.SECONDARY,
  className,
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 14 14"
      aria-label="info icon"
    >
      <g fill="none" stroke={color} strokeWidth="1">
        <circle cx="7" cy="7" r="7" stroke="none" />
        <circle cx="7" cy="7" r="6.5" fill="none" />
      </g>
      <path
        d="M-.727,0H.732V-6.211H-.727ZM0-7.025a.8.8,0,0,0,.814-.791A.8.8,0,0,0,0-8.613a.8.8,0,0,0-.809.8A.8.8,0,0,0,0-7.025Z"
        transform="translate(7 11)"
        fill={color}
      />
    </svg>
  );
}

export default InfoIcon;
