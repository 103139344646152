import { COLORS } from 'constants/colors';
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  height?: number;
  width?: number;
}

function CopySuccessIcon({
  height = 16,
  width = 16,
  className,
  color = COLORS.GREEN_PRIMARY,
  ...props
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 16 16"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <path
        d="M13.78 4.22a.75.75 0 010 1.06l-7.25 7.25a.75.75 0 01-1.06 0L2.22 9.28a.751.751 0 01.018-1.042.751.751 0 011.042-.018L6 10.94l6.72-6.72a.75.75 0 011.06 0z"
        fill={color}
      />
    </svg>
  );
}

export default CopySuccessIcon;
