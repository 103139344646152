import ActionCable, { Cable, type Channel } from 'actioncable';

export class SocketConnection {
  cable: Cable;

  constructor(socketHostUrl = '') {
    this.cable = ActionCable.createConsumer(socketHostUrl);
  }

  subscribe = <T = unknown>(
    { onReceivedData }: { onReceivedData: (obj: T) => void },
    channelData: { channel: string; data: any }
  ) =>
    this.cable.subscriptions.create(channelData, {
      // eslint-disable-next-line
      connected: () => console.debug(`socket connection connected`),
      // eslint-disable-next-line
      disconnected: () => console.debug(`socket connection disconnected`),
      received: onReceivedData,
      // eslint-disable-next-line
      rejected: () => console.debug(`socket connection rejected`),
    });

  unsubscribe = (subscription: Channel | null) => subscription?.unsubscribe();

  disconnect = () => this.cable.disconnect();
}
