import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'constants/colors';

interface FeedbackIconProps {
  width?: number | string;
  height?: number | string;
  fill?: string;
  border?: string;
  className?: string;
  title?: string;
}

function FeedbackIcon({
  width = 30,
  height = 30,
  fill = COLORS.TRANSPARENT,
  border = COLORS.WHITE,
  className,
  title = 'Feedback',
}: FeedbackIconProps) {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <title>{title}</title>
      <path
        d="M12 3.3l2.939 5.696 6.572.913-4.756 4.434 1.123 6.26L12 17.647l-5.878 2.956 1.123-6.26-4.756-4.434 6.572-.913L12 3.3z"
        fill={fill}
      />
      <path
        d="M6.2 21.558a.559.559 0 01-.546-.65L6.7 14.683l-4.53-4.396a.55.55 0 01.308-.943l6.238-.931 2.77-5.672a.552.552 0 01.993 0l2.807 5.655 6.239.884a.555.555 0 01.3.95l-4.5 4.42 1.08 6.192a.555.555 0 01-.791.62l-5.582-2.924-5.574 2.956a.522.522 0 01-.258.066zm5.787-17.32L9.585 9.16a.55.55 0 01-.416.304l-5.416.806 3.924 3.817a.552.552 0 01.159.489l-.906 5.408 4.832-2.566a.59.59 0 01.264-.064c.087 0 .173.023.25.064l4.851 2.537-.94-5.4a.549.549 0 01.156-.493l3.911-3.837-5.415-.773a.568.568 0 01-.417-.305l-2.435-4.91z"
        fill={border}
      />
    </svg>
  );
}

FeedbackIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fill: PropTypes.string,
  border: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
};

export default FeedbackIcon;
